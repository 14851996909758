import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";

import OrderDetailsServiceProvider from "./Screens/OrderDetailsServiceProvider/OrderDetailsServiceProvider";
import ModelForPrintingOrderDetails from "./Screens/Orders/ModelForPrintingOrderDetails";
import ServiceProviderForm from "./Screens/Login/ServiceProvider/ServiceProviderForm";
import CrownAndBridgeOrderDetails from "./Screens/Orders/CrownAndBridgeOrderDetails";
import OrderDetailsDentist from "./Screens/OrderDetailsDentist/OrderDetailsDentist";
import OrthodonticOrderDetails from "./Screens/Orders/OrthodonticOrderDetails";
import SmileDesignOrderDetails from "./Screens/Orders/SmileDesignOrderDetails";
import OrderDetailsAdmin from "./Screens/OrderDetailsAdmin/OrderDetailsAdmin";
import RemovablesOrderDetails from "./Screens/Orders/RemovablesOrderDetails";
import SurgicalOrderDetails from "./Screens/Orders/SurgicalOrderDetails";
import ServiceProvider from "./Screens/User Management/ServiceProvider";
import ImplantOrderDetails from "./Screens/Orders/ImplantOrderDetails";
import PaymentHistory from "./Screens/PaymentHistory/PaymentHistory";
import OrderDisputes from "./Screens/OrderDisputes.js/OrderDisputes";
import DashboardDentist from "./Screens/Dashboard/DashboardDentist";
import DentistForm from "./Screens/Login/Dentist/DentistForm";
import ChangePassword from "./Screens/Profile/ChangePassword";
import DashboardSP from "./Screens/Dashboard/DashboardSP";
import Dentist from "./Screens/User Management/Dentist";
import Categories from "./Screens/Services/Categories";
import AddNewOrder from "./Screens/Orders/AddNewOrder";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Questions from "./Screens/Services/Questions";
import Software from "./Screens/Services/Software";
import Services from "./Screens/Services/Services";
import Profile from "./Screens/Profile/Profile";
import Login from "./Screens/Login/Login";
import Orders from "./Screens/Orders/Orders";

import { ServicesServiceProvider } from "./Screens/ServicesServiceProvider/ServicesServiceProvider";
import { useGetProfileMutation, useReadPersonalDetailsMutation } from "./services/user";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfileDetails } from "./reducers/PersonalDetailsReducer";
import Reports from "./Screens/Reports/Reports";

function App() {
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  let userRole = localStorage.getItem("crowdHireUserRole");
  let userCrowdHireId = localStorage.getItem("userCrowdHireId");
  let useractive = localStorage.getItem("useractive");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();

  const [getProfile] = useGetProfileMutation();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();

  const [emailFromUserProfile, setEmailFromUserProfile] = useState("");
  const [profileId, setProfileId] = useState("");
  const [userInfo, setUserInfo] = useState([
    {
      active: Number(useractive),
      crowdHireId: userCrowdHireId,
    },
  ]);

  console.log("isAuthenticated", isAuthenticated);
  const [isRoleLoading, setIsRoleLoading] = useState(false);

  const redirectIfRolePresent = (uRole, userCrowdHireId) => {
    setIsRoleLoading(false);
    if (location.pathname !== "/" && location.pathname !== "/login") {
      return;
    }
    switch (uRole) {
      case null:
        navigate("/");
        break;
      // super admin
      case "89uij7":
        navigate("/dashboard");
        break;
      // service provider
      case "34fs3":
        if (userCrowdHireId === "undefined" || undefined) {
          navigate("/serviceProviderForm");
        } else {
          navigate("/dashboard");
        }
        break;
      // dentist
      case "676dza2":
        if (userCrowdHireId === "undefined" || undefined) {
          navigate("/dentistForm");
        } else {
          navigate("/dashboard");
        }
        break;
      default:
        navigate("/");
        break;
    }
  };

  const clearCookies = () => {
    const allCookies = document.cookie.split(";");
    for (var i = 0; i < allCookies.length; i++)
      document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
  };

  const getUserProfileDetails = async () => {
    if (!userProfileDetails?._id) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await readPersonalDetails({ accessToken }).unwrap((res) => res);
      dispatch(setUserProfileDetails(response?.data?.[0]));
    }
  };

  useEffect(() => {
    const checkingRole = async () => {
      if (isAuthenticated) {
        setIsRoleLoading(true);
        if (userRole && userCrowdHireId) {
          getUserProfileDetails();
          redirectIfRolePresent(userRole, userCrowdHireId);
          return;
        }
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
          },
        });
        const response = await getProfile(accessToken).unwrap();
        console.log("response", response);
        if (response?.role) {
          localStorage.setItem("crowdHireUserRole", response?.role);
          localStorage.setItem("userCrowdHireId", response?.chDetails?.[0]?.crowdHireId);
          localStorage.setItem("useractive", response?.chDetails?.[0]?.active);
          setEmailFromUserProfile(response["email"]);
          const userRoleFromResponse = response["role"];
          redirectIfRolePresent(userRoleFromResponse, response?.chDetails?.[0]?.crowdHireId);
          setUserInfo(response?.chDetails);
        } else if (response?.type === "2") {
          localStorage.clear();
          sessionStorage.clear();
          clearCookies();
          logout({ returnTo: window.location.origin });
        } else {
          const userRoleFromResponse = response["role"];
          redirectIfRolePresent(userRoleFromResponse, response?.chDetails?.[0]?.crowdHireId);
        }
      } else {
        // redirectIfRolePresent();
      }
    };
    checkingRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <div className="App font-roboto bg-black">
        <Routes>
          <Route exact path="/" element={isRoleLoading ? <SplashScreen /> : <Login />} />
          <Route
            exact
            path="/dashboard"
            element={
              userRole === "89uij7" ? (
                <Dashboard userRoles={userRole} userInfo={userInfo} />
              ) : userRole === "34fs3" ? (
                <DashboardSP userRoles={userRole} userInfo={userInfo} setUserInfo={setUserInfo} />
              ) : (
                <DashboardDentist
                  userRoles={userRole}
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                />
              )
            }
          />
          <Route
            exact
            path="/profileinfo"
            element={
              <Profile
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
                userRole={userRole}
                userInfo={userInfo}
              />
            }
          />
          <Route
            exact
            path="/changepassword"
            element={
              <ChangePassword
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/servicecategories"
            element={
              <Services
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/softwarecategories"
            element={
              <Software
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/questions"
            element={
              <Questions
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/servicesSp"
            element={
              <ServicesServiceProvider
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/providerlevels"
            element={
              <Categories
                emailFromUserProfile={emailFromUserProfile}
                profileId={profileId}
                setProfileId={setProfileId}
                userRole={userRole}
                userInfo={userInfo}
              />
            }
          />
          <Route
            exact
            path="/serviceProviderForm"
            element={
              <ServiceProviderForm
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                setProfileId={setProfileId}
              />
            }
          />

          <Route
            exact
            path="/dentistForm"
            element={
              <DentistForm
                userRole={userRole}
                userInfo={userInfo}
                emailFromUserProfile={emailFromUserProfile}
                setProfileId={setProfileId}
              />
            }
          />
          <Route
            exact
            path="/serviceproviders"
            element={<ServiceProvider userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/dentist"
            element={<Dentist userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orders"
            element={<Orders userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/reports"
            element={<Reports userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/paymentHistory"
            element={<PaymentHistory userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDisputes"
            element={<OrderDisputes userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/addNewOrder"
            element={<AddNewOrder userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orthodonticsorder"
            element={<OrthodonticOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/crownandbridgeorder"
            element={<CrownAndBridgeOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/smileorder"
            element={<SmileDesignOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/modelorder"
            element={<ModelForPrintingOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDetailsServiceProvider"
            element={<OrderDetailsServiceProvider userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/surgicalorder"
            element={<SurgicalOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/implantorder"
            element={<ImplantOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/removablesorder"
            element={<RemovablesOrderDetails userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDetailsAdmin"
            element={<OrderDetailsAdmin userRole={userRole} userInfo={userInfo} />}
          />
          <Route
            exact
            path="/orderDetailsDentist"
            element={<OrderDetailsDentist userRole={userRole} userInfo={userInfo} />}
          />
        </Routes>
        <ToastContainer className={"!w-[48vw]"} />
      </div>
    </>
  );
}

const SplashScreen = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <h1 className="text-4xl font-bold text-white">Loading the App...</h1>
    </div>
  );
};

export default App;
