import { forwardRef, useEffect, useState } from "react";
import CrownOrderSummary from "./CrownOrderSummary";
import ImplantOrderSummary from "./ImplantOrderSummary";
import ModelsForPrintingOrderSummary from "./ModelsForPrintingOrderSummary";
import OrthodonticOrderSummary from "./OrthodonticOrderSummary";
import RemovablesOrderSummary from "./RemovablesOrderSummary";
import SmileOrderSummary from "./SmileOrderSummary";
import SurgicalGuidesOrderSummary from "./SurgicalGuidesOrderSummary";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";
import { SERVICE_TEETH_MAPPING_REVERSE } from "../../constants/orderForms";
import { useAuth0 } from "@auth0/auth0-react";
import { TbListDetails } from "react-icons/tb";
import { isImageFormatDisplayable } from "../../Utils/utility";
import imagePlaceholderIcon from "../../Assets/images/imagePlaceholderIcon.png";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import ReactModal from "react-modal";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const OrderSummary = forwardRef(
  ({ data, transformDetails = true, isOrderPlaced = false, showTopTiles = true }, ref) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const [getServicesCategories] = useGetServicesCategoriesMutation();

    const [selectedServiceCategory, setSelectedServiceCategory] = useState({});
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [orderData, setOrderData] = useState({});

    console.log("orderData777", orderData);

    const openImageModal = (image) => {
      setSelectedImage(image);
      setIsImageModalOpen(true);
    };

    console.log("selectedServiceCategory", selectedServiceCategory);

    const closeImageModal = () => {
      setIsImageModalOpen(false);
    };

    const getServicesCategoriesData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const resp = await getServicesCategories(accessToken).unwrap();
      console.log("test data?.categoryObjId", data?.categoryObjId?.[0]);
      console.log("test resp", resp?.data);
      if (resp?.data && data?.categoryObjId?.[0]) {
        resp?.data?.forEach((d) => {
          if (d?._id === data?.categoryObjId?.[0]) {
            setSelectedServiceCategory(d);
          }
        });
      }
    };

    const transformData = (data) => {
      if (transformDetails) {
        setOrderData({
          expectedDelivery: data?.expectedDelivery || "",
          categoryObjId: data?.categoryObjId?.[0],
          isPriority: data?.isPriority !== undefined ? data?.isPriority : null,
          patientDetails: {
            patientInitials: data?.patientDetails?.patientInitials || "",
            detailsAnonymized: data?.patientDetails?.detailsAnonymized || false,
            dob: data?.patientDetails?.dob || "",
            gender: data?.patientDetails?.gender || "",
            address: data?.patientDetails?.address || "",
            country: data?.patientDetails?.country || "",
            pinCode: data?.patientDetails?.pinCode || "",
            location: {
              lat: data?.patientDetails?.latitude ? parseInt(data?.patientDetails?.latitude) : "",
              lng: data?.patientDetails?.longitude ? parseInt(data?.patientDetails?.longitude) : "",
            },
          },
          caseDetails: {
            numberSystem: data?.numberSystem || "",
            hasMissingTeeth: data?.hasMissingTeeth !== undefined ? data?.hasMissingTeeth : null,
            missingTeethSelectedTeeth: (data?.missingTeeth || [])?.reduce((acc, item) => {
              if (item?.expiryDate === null) {
                acc.push(item?.teethno);
              }
              return acc;
            }, []),
            ...((data?.categoryObjId?.[0] === "65bcebd95a40eb218674ef5e" && {
              anyTeethMobile: data?.anyTeethMobile !== undefined ? data?.anyTeethMobile : null,
              anyTeethMobileSelectedTeeth: (data?.anyTeethMobileDet || [])?.reduce((acc, item) => {
                if (item?.expiryDate === null) {
                  acc.push(item?.teethno);
                }
                return acc;
              }, []),
              teethToExtract: data?.teethToExtract !== undefined ? data?.teethToExtract : null,
              teethToExtractSelectedTeeth: (data?.teethToExtractDet || [])?.reduce((acc, item) => {
                if (item?.expiryDate === null) {
                  acc.push(item?.teethno);
                }
                return acc;
              }, []),
            }) ||
              {}),
            services: (data?.services || []).reduce((acc, item) => {
              const serviceTooths = item?.serviceTooth?.reduce((prevAcc, toothItem) => {
                prevAcc[SERVICE_TEETH_MAPPING_REVERSE[toothItem.type]] = [
                  ...(prevAcc?.[SERVICE_TEETH_MAPPING_REVERSE[toothItem.type]] || []),
                  toothItem.teethno,
                ];
                return prevAcc;
              }, {});
              const serviceOptions = item?.serviceOptions?.reduce((prevAcc, optionItem) => {
                prevAcc[optionItem.type] = [
                  ...(prevAcc?.[optionItem?.type] || []),
                  optionItem?.optionstring,
                ];
                return prevAcc;
              }, {});
              acc.push({ ...item, ...serviceTooths, ...serviceOptions });
              return acc;
            }, []),
          },
          uploadFiles: {
            photographs: (data?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "photograhpsAndFaceScans") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            radiographs: (data?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "radiographs") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            intraOralScans: (data?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "internalScan") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            rawDicomData: (data?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "rawDicom") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
            additionalFiles: (data?.assets || [])?.reduce((acc, item) => {
              if (item?.fileType === "additionalFiles") {
                acc[item?.fileFor] = {
                  fileType: item?.fileType || "",
                  s3Url: item?.s3Url || "",
                  _id: item?._id || "",
                };
              }
              return acc;
            }, {}),
          },
          disclaimers: {
            isTncChecked: data?.isTncChecked || false,
            tncDocument: data?.tncDocument || null,
          },
        });
      } else {
        setOrderData(data);
      }
    };

    useEffect(() => {}, []);

    useEffect(() => {
      if (data?._id) {
        getServicesCategoriesData();
        transformData(data);
      }
    }, [data]);

    return (
      <div className="ORDER-DETAIL-COMPONENT flex-1 flex flex-col h-full bg-secondary rounded-b-xl px-4 w-full">
        {showTopTiles && (
          <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md">
            <div className="flex items-center gap-4">
              <div className="bg-[#8ED259] h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
              <p className=" text-white">Service Details</p>
            </div>
            <div className="md:w-[200px] h-[20px] ">
              <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
            </div>
            <div className="flex items-center gap-4">
              <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                <AiOutlineCheck className=" " />
              </div>
              <p className="text-white">Order Details</p>
            </div>
            <div className="md:w-[200px] h-[20px] ">
              <FaAngleRight className="mx-auto text-[#8ED259] text-xl md:rotate-0 " />
            </div>
            <div className="flex items-center gap-4">
              {isOrderPlaced ? (
                <div className="bg-[#8ED259] h-8 w-8 rounded-full border text-white font-medium flex items-center justify-center">
                  <AiOutlineCheck className=" " />
                </div>
              ) : (
                <div className="bg-orange-500 h-8 w-8 rounded-full border text-[#fff] font-medium flex items-center justify-center">
                  3
                </div>
              )}

              <p className="text-white font-semibold">Confirmation</p>
            </div>
          </div>
        )}
        {isOrderPlaced ? (
          <div className="flex-1 flex flex-col justify-between h-full">
            <div className="h-full w-full flex-1 flex flex-col justify-center items-center gap-2">
              <img src={orderPlacedIcon} alt="" className="w-[12%]" />
              <p className="text-2xl text-white font-semibold">Order Placed Successfully</p>
              <p className="text-[17px] text-white">
                Your order is being reviewed and your quote will be available within 24 hours
              </p>
            </div>
            <div className="mb-4">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div ref={ref} className="w-full">
            <div className="bg-backgroundV2 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md border-b-2 border-primary">
              <div className="flex items-center gap-4">
                <div className="bg-primary h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
                  <TbListDetails className=" " />
                </div>
                <p className=" text-white font-medium">Patient Details</p>
              </div>
            </div>
            <div className="w-full bg-secondary p-3">
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4">
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient Initials</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.patientInitials || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Patient details have been anonymized</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.detailsAnonymized ? "Yes" : "No"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Date of Birth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.dob || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Gender</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.gender || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Shipping Address</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.address || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Country</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.country || "NA"}
                  </p>
                </div>
                <div className="TILE E md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Postal code (Zip code/Pin code)</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.patientDetails?.pinCode || "NA"}
                  </p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">The patient has any missing teeth</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.caseDetails?.hasMissingTeeth || "NA"}
                  </p>
                </div>
                <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Missing Teeths</p>
                  <p className="text-[#555] font-medium mt-2">
                    {orderData?.caseDetails?.missingTeethSelectedTeeth?.join(", ") || "NA"}
                  </p>
                </div>

                {data?.categoryObjId?.[0] === "65bcebd95a40eb218674ef5e" && (
                  <>
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Do you intend to extract any teeth?</p>
                      <p className="text-[#555] font-medium mt-2">
                        {orderData?.caseDetails?.teethToExtract || "NA"}
                      </p>
                    </div>
                    <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Teeth that you intend to extract</p>
                      <p className="text-[#555] font-medium mt-2">
                        {orderData?.caseDetails?.teethToExtractSelectedTeeth?.join(", ") || "NA"}
                      </p>
                    </div>
                    <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Any of the remaining teeth mobile?</p>
                      <p className="text-[#555] font-medium mt-2">
                        {orderData?.caseDetails?.anyTeethMobile || "NA"}
                      </p>
                    </div>
                    <div className="TILE md:col-span-2 pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                      <p className="text-[15px] text-white">Remaining mobile teeth</p>
                      <p className="text-[#555] font-medium mt-2">
                        {orderData?.caseDetails?.anyTeethMobileSelectedTeeth?.join(", ") || "NA"}
                      </p>
                    </div>
                  </>
                )}

                {orderData?.caseDetails?.services?.length > 0 && (
                  <div className="bg-backgroundV2 col-span-3 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md border-b-2 border-primary">
                    <div className="flex items-center gap-4">
                      <div className="bg-primary h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
                        <TbListDetails className=" " />
                      </div>
                      <p className=" text-white font-medium">Services Selected</p>
                    </div>
                  </div>
                )}
              </div>

              {data?.categoryObjId?.[0] === "65baa5ed9061ae0a955d3076" && (
                <CrownOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              {data?.categoryObjId?.[0] === "65baa5ff9061ae0a955d3077" && (
                <ImplantOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              {data?.categoryObjId?.[0] === "65bce8ec5a40eb218674ef5b" && (
                <OrthodonticOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              {data?.categoryObjId?.[0] === "65bd043aa03607278060f6ba" && (
                <RemovablesOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              {data?.categoryObjId?.[0] === "65bceaad5a40eb218674ef5c" && (
                <SmileOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              {data?.categoryObjId?.[0] === "65bceb6a5a40eb218674ef5d" && (
                <ModelsForPrintingOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              {data?.categoryObjId?.[0] === "65bcebd95a40eb218674ef5e" && (
                <SurgicalGuidesOrderSummary
                  orderData={orderData}
                  selectedServiceCategory={selectedServiceCategory}
                  openImageModal={openImageModal}
                  isImageModalOpen={isImageModalOpen}
                  setIsImageModalOpen={setIsImageModalOpen}
                  selectedImage={selectedImage}
                  closeImageModal={closeImageModal}
                />
              )}
              <div className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 mt-8">
                <div className="bg-backgroundV2 col-span-3 flex flex-row flex-wrap items-center gap-4 md:justify-around justify-between py-4 md:px-0 px-4 mb-4 rounded-md border-b-2 border-primary">
                  <div className="flex items-center gap-4">
                    <div className="bg-primary h-8 w-8 rounded-full text-white font-medium flex items-center justify-center">
                      <TbListDetails className=" " />
                    </div>
                    <p className=" text-white font-medium">Uploads</p>
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Photographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orderData?.uploadFiles?.photographs || {})?.map(function (
                      key,
                      index
                    ) {
                      if (orderData?.uploadFiles?.photographs[key]?.s3Url) {
                        return (
                          <div
                            key={"photographItem" + key}
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(orderData?.uploadFiles?.photographs[key]?.s3Url)
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="w-7 aspect-square object-cover"
                              src={
                                isImageFormatDisplayable(
                                  orderData?.uploadFiles?.photographs[key]?.s3Url
                                )
                                  ? orderData?.uploadFiles?.photographs[key]?.s3Url
                                  : imagePlaceholderIcon
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    })}
                    {(Object.keys(orderData?.uploadFiles?.photographs || {})?.length === 0 ||
                      Object.values(orderData?.uploadFiles?.photographs || {})?.every(
                        (d) => !d?.s3Url
                      )) &&
                      "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Radiographs</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orderData?.uploadFiles?.radiographs || {})?.map(function (
                      key,
                      index
                    ) {
                      if (orderData?.uploadFiles?.radiographs[key]?.s3Url) {
                        return (
                          <div
                            key={"radiographItem" + key}
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(orderData?.uploadFiles?.radiographs[key]?.s3Url)
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="w-7 aspect-square object-cover"
                              src={
                                isImageFormatDisplayable(
                                  orderData?.uploadFiles?.radiographs[key]?.s3Url
                                )
                                  ? orderData?.uploadFiles?.radiographs[key]?.s3Url
                                  : imagePlaceholderIcon
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    })}
                    {(Object.keys(orderData?.uploadFiles?.radiographs || {})?.length === 0 ||
                      Object.values(orderData?.uploadFiles?.radiographs || {})?.every(
                        (d) => !d?.s3Url
                      )) &&
                      "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Intra Oral Scans</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orderData?.uploadFiles?.intraOralScans || {})?.map(function (
                      key,
                      index
                    ) {
                      if (orderData?.uploadFiles?.intraOralScans[key]?.s3Url) {
                        return (
                          <div
                            key={"intraOralScansItem" + key}
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(orderData?.uploadFiles?.intraOralScans[key]?.s3Url)
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="w-7 aspect-square object-cover"
                              src={
                                isImageFormatDisplayable(
                                  orderData?.uploadFiles?.intraOralScans[key]?.s3Url
                                )
                                  ? orderData?.uploadFiles?.intraOralScans[key]?.s3Url
                                  : imagePlaceholderIcon
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    })}
                    {(Object.keys(orderData?.uploadFiles?.intraOralScans || {})?.length === 0 ||
                      Object.values(orderData?.uploadFiles?.intraOralScans || {})?.every(
                        (d) => !d?.s3Url
                      )) &&
                      "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Intraoral scans sent through:</p>
                  <p className="text-[#555] font-medium mt-2">{orderData?.scanMedium || "NA"}</p>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Raw DICOM Data</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orderData?.uploadFiles?.rawDicomData || {})?.map((key, index) => {
                      if (orderData?.uploadFiles?.rawDicomData[key]?.s3Url) {
                        return (
                          <div
                            key={"rawDicomDataItem" + key}
                            className="cursor-pointer"
                            onClick={() =>
                              openImageModal(orderData?.uploadFiles?.rawDicomData[key]?.s3Url)
                            }
                          >
                            <img
                              key={index} // Don't forget to provide a unique key for each element in the array
                              className="w-7 aspect-square object-cover"
                              src={
                                isImageFormatDisplayable(
                                  orderData?.uploadFiles?.rawDicomData[key]?.s3Url
                                )
                                  ? orderData?.uploadFiles?.rawDicomData[key]?.s3Url
                                  : imagePlaceholderIcon
                              }
                              alt=""
                            />
                          </div>
                        );
                      }
                      return null; // Return null if there's no image URL for the key
                    })}
                    {(Object.keys(orderData?.uploadFiles?.rawDicomData || {})?.length === 0 ||
                      Object.values(orderData?.uploadFiles?.rawDicomData || {})?.every(
                        (d) => !d?.s3Url
                      )) &&
                      "NA"}
                  </div>
                </div>
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Additional Files</p>
                  <div className="text-[#555] font-medium mt-2 flex flex-row gap-2">
                    {Object.keys(orderData?.uploadFiles?.additionalFiles || {})?.map(
                      (key, index) => {
                        if (orderData?.uploadFiles?.additionalFiles?.[key]?.s3Url) {
                          return (
                            <div
                              key={"rawDicomDataItem" + key}
                              className="cursor-pointer"
                              onClick={() =>
                                openImageModal(
                                  orderData?.uploadFiles?.additionalFiles?.[key]?.s3Url
                                )
                              }
                            >
                              <img
                                key={index} // Don't forget to provide a unique key for each element in the array
                                className="w-7 aspect-square object-cover"
                                src={
                                  isImageFormatDisplayable(
                                    orderData?.uploadFiles?.additionalFiles[key]?.s3Url
                                  )
                                    ? orderData?.uploadFiles?.additionalFiles[key]?.s3Url
                                    : imagePlaceholderIcon
                                }
                                alt=""
                              />
                            </div>
                          );
                        }
                        return null; // Return null if there's no image URL for the key
                      }
                    )}
                    {(Object.keys(orderData?.uploadFiles?.additionalFiles || {})?.length === 0 ||
                      Object.values(orderData?.uploadFiles?.additionalFiles || {})?.every(
                        (d) => !d?.s3Url
                      )) &&
                      "NA"}
                  </div>
                </div>
                {isImageModalOpen && (
                  <div className="image-modal blur-md">
                    <ReactModal
                      isOpen={isImageModalOpen}
                      onRequestClose={() => setIsImageModalOpen(false)}
                      contentLabel="Custom Modal"
                      className="image-modal !bg-black/80"
                      // style={customStyles}
                      ariaHideApp={false}
                    >
                      <div
                        className="MODAL-BODY flex flex-col h-full"
                        onClick={() => closeImageModal()}
                      >
                        <button className="w-full text-right">
                          <AiOutlineClose className="ml-auto text-white" />
                        </button>
                        <img
                          className={`${
                            isImageFormatDisplayable(selectedImage) ? "h-[90%]" : "h-[85%]"
                          } w-full object-contain`}
                          src={
                            isImageFormatDisplayable(selectedImage)
                              ? selectedImage
                              : imagePlaceholderIcon
                          }
                          onClick={(e) => e.stopPropagation()}
                          alt=""
                        />
                        {!isImageFormatDisplayable(selectedImage) && (
                          <p className="mt-3 font-medium text-white text-lg text-center">
                            File Preview not available
                          </p>
                        )}
                      </div>
                    </ReactModal>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default OrderSummary;
