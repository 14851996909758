import React, { useState } from "react";

export const Checkbox = ({ label, value, onChange, checked, icon, iconClass = "" }) => {
  const handleCheckboxChange = () => {
    const newCheckedValue = !checked;
    if (onChange) {
      onChange(value, newCheckedValue);
    }
  };

  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        className="form-checkbox"
        checked={checked ?? false}
        onChange={handleCheckboxChange}
      />
      <span className={`${icon ? "text-xs" : ""}`}>
        {icon && (
          <img
            src={icon}
            className={`w-[50%] aspect-[3/2] object-contain grayscale-[100%] opacity-90 ${iconClass}`}
            alt={label}
          />
        )}
        {label}
      </span>
    </label>
  );
};
