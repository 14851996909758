import React, { useState, useEffect } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import Navbar, { MiddleNavLayout } from "../../Components/Navbar-Comps/Navbar";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import { useSearchParams } from "react-router-dom";
import {
  useOrderStatsListMutation,
  useRefundListMutation,
  usePaymentStatsListMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import DateFilter from "../../Components/InputField-Comps/DateFilter";
import { endOfMonth, startOfMonth } from "date-fns";
import DropdownField from "../../Components/InputField-Comps/DropdownField";
import * as XLSX from "xlsx";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import { CiFilter } from "react-icons/ci";

function Reports({ userRole, userInfo }) {
  return (
    <div className="flex">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Reports"
      />
      <ReportsTab userRole={userRole} userInfo={userInfo} />
    </div>
  );
}

export default Reports;

export const ReportsTab = ({ userRole, userInfo }) => {
  const headersOrder = [
    { headerId: "orderNumber", headerName: "Order Id", headerSort: true },
    {
      headerId: "isPriority",
      headerName: "Priority Order",
      headerSort: false,
    },
    {
      headerId: "createdDate",
      headerName: "Order Date",
      headerSort: false,
    },
    {
      headerId: "status",
      headerName: "Order Status",
      headerSort: false,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
    {
      headerId: "discard",
      headerName: "Discard",
      headerSort: false,
    },
  ];

  const headersTransaction = [
    {
      headerId: "orderNumber",
      headerName: "Order Id",
      headerSort: true,
    },
    {
      headerId: "amount",
      headerName: "Amount",
      headerSort: true,
    },
    {
      headerId: "paymentDate",
      headerName: "Payment Date",
      headerSort: true,
    },
    {
      headerId: "status",
      headerName: "Status",
      headerSort: true,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
  ];

  const { user, isAuthenticated } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedButton, setSelectedButton] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [orderStatsList] = useOrderStatsListMutation();
  const [paymentStatsList] = usePaymentStatsListMutation();
  const [refundList] = useRefundListMutation();
  const [ordersData, setOrdersData] = useState([]);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const [activeTab, setActiveTab] = useState(searchParams.get("orderType") ?? "Orders");
  const [middleNavActive, setMiddleNavActive] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: "selection",
  });
  const [orderStatus, setOrderStatus] = useState(searchParams.get("orderStatus") ?? "");
  const [spStatus, setSpStatus] = useState(searchParams.get("spStatus") ?? "");
  const [status, setStatus] = useState(searchParams.get("status") ?? "");
  const [transactionData, setTransactionData] = useState([]);

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  const closeMiddleNav = () => {
    setMiddleNavActive((prev) => !prev);
  };

  const readOrderStatsList = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const currentStatus = searchParams.get("orderStatus");
    const spstatus = searchParams.get("spstatus");
    const resp = await orderStatsList({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: currentStatus === "" ? undefined : currentStatus,
        spstatus: spstatus === "" ? undefined : spstatus,
      },
    }).unwrap();
    setOrdersData(resp?.data);
  };

  const readPaymentStatsList = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const status = searchParams.get("status");
    const resp = await paymentStatsList({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        status: status === "" ? undefined : status,
      },
    }).unwrap();

    if (resp) {
      const filterData = resp?.data?.filter((d) => d?.payments);
      setTransactionData(
        filterData?.map((d) => ({
          ...d?.payments,
          ...d,
        }))
      );
    }
  };

  const readRefundList = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const status = searchParams.get("status");
    const resp = await refundList({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        status: status === "" ? undefined : status,
      },
    }).unwrap();
    if (resp) {
      const filterData = resp?.data?.filter((d) => d?.payments);
      setTransactionData(
        filterData?.map((d) => ({
          ...d?.payments,
          ...d,
        }))
      );
    }
  };

  const readByType = () => {
    if (activeTab === "Orders") {
      readOrderStatsList();
    } else if (activeTab === "Payments") {
      readPaymentStatsList();
    } else if (activeTab === "Refunds") {
      readRefundList();
    }
  };

  useEffect(() => {
    readByType();
  }, [activeTab]);

  const middleTabTitleArray =
    userRole === "89uij7" ? ["Orders", "Payments", "Refunds"] : ["Orders"];

  return (
    <>
      <>
        <MiddleNavLayout
          middleNavActive={middleNavActive}
          activeTab={activeTab}
          middleTabTitleArray={middleTabTitleArray}
          handleTabSuper={setActiveTab}
          closeMiddleNav={closeMiddleNav}
          middleNavHeading="Orders"
        />

        <div className="MAIN-DASH bg-background p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3 flex items-center text-textP">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {userProfileDetails?.firstName ?? user?.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {userRole !== "89uij7" ? (
                userInfo?.length > 0 ? (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    {userInfo[0]?.active === 1 ? (
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                      </>
                    ) : (
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={
                          "Your registration is under review and you will be notified once admin approves your registration."
                        }
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                    )}
                  </button>
                ) : (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    Account Not Active
                    <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
                  </button>
                )
              ) : (
                <></>
              )}
              <Notification />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {activeTab === "Orders" ? (
                <>
                  <div className="flex flex-col">
                    <p className="text-xs text-white">Order Status</p>
                    <DropdownField
                      labelText="Order Status"
                      placeholder="Select Order Status"
                      placeholderValue={null}
                      name="orderStatus"
                      labelClass={"text-[16px] leding-4"}
                      handleInputChange={(e) => {
                        setOrderStatus(e.target.value);
                        const newParams = new URLSearchParams(searchParams);
                        newParams.set("orderStatus", e.target.value);
                        setSearchParams(newParams);
                      }}
                      labelColor={"#777"}
                      value={orderStatus}
                      options={
                        userRole === "676dza2"
                          ? [
                              {
                                label: "Order Saved",
                                value: "1",
                              },
                              {
                                label: "Service provider assigned",
                                value: "2",
                              },
                              {
                                label: "Accepted by dentist",
                                value: "5",
                              },
                              {
                                label: "Order delivered by service provider",
                                value: "7",
                              },
                              {
                                label: "delivery accepted by dentist",
                                value: "8",
                              },
                            ]
                          : userRole === "34fs3"
                          ? [
                              {
                                label: "Service provider assigned",
                                value: "2",
                              },
                              {
                                label: "Accepted by dentist",
                                value: "5",
                              },
                              {
                                label: "delivery accepted by dentist",
                                value: "8",
                              },
                              {
                                label: "delivery declined by dentist",
                                value: "9",
                              },
                            ]
                          : [
                              {
                                label: "All Order",
                                value: "",
                              },
                              {
                                label: "Order Saved",
                                value: "1",
                              },
                              {
                                label: "Service provider assigned",
                                value: "2",
                              },
                              {
                                label: "Rejected by service provider",
                                value: "3",
                              },
                              {
                                label: "Accepted by service provide",
                                value: "4",
                              },
                              {
                                label: "Accepted by dentist",
                                value: "5",
                              },
                              {
                                label: "Order rejected by dentist",
                                value: "6",
                              },
                              {
                                label: "Order delivered by service provider",
                                value: "7",
                              },
                              {
                                label: "delivery accepted by dentist",
                                value: "8",
                              },
                              {
                                label: "delivery declined by dentist",
                                value: "9",
                              },
                              {
                                label: "Dispute resolved by admin",
                                value: "10",
                              },
                              {
                                label: "Refund requested",
                                value: "11",
                              },
                              {
                                label: "Refund completed",
                                value: "12",
                              },
                            ]
                      }
                      showLabelText={false}
                      isFieldRequired={false}
                    />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-xs text-white">Supplier Status</p>
                    <DropdownField
                      labelText="Supplier Status"
                      placeholder="Select Supplier Status"
                      placeholderValue={null}
                      name="spstatus"
                      labelClass={"text-[16px] leding-4"}
                      handleInputChange={(e) => {
                        setSpStatus(e.target.value);
                        const newParams = new URLSearchParams(searchParams);
                        newParams.set("spstatus", e.target.value);
                        setSearchParams(newParams);
                      }}
                      labelColor={"#777"}
                      value={spStatus}
                      options={[
                        {
                          label: "All",
                          value: "",
                        },
                        {
                          label: "Accepted",
                          value: "1",
                        },
                        {
                          label: "Rejected",
                          value: "0",
                        },
                      ]}
                      showLabelText={false}
                      isFieldRequired={false}
                    />
                  </div>
                </>
              ) : (
                <div className="flex flex-col">
                  <p className="text-xs text-white">Status</p>
                  <DropdownField
                    labelText="Status"
                    placeholder="Select Status"
                    placeholderValue={null}
                    name="status"
                    labelClass={"text-[16px] leding-4"}
                    handleInputChange={(e) => {
                      setStatus(e.target.value);
                      const newParams = new URLSearchParams(searchParams);
                      newParams.set("status", e.target.value);
                      setSearchParams(newParams);
                    }}
                    labelColor={"#777"}
                    value={status}
                    options={[
                      {
                        label: "All",
                        value: "",
                      },
                      {
                        label: "Succeeded",
                        value: "succeeded",
                      },
                    ]}
                    showLabelText={false}
                    isFieldRequired={false}
                  />
                </div>
              )}
              <DateFilter range={dateRange} setRange={setDateRange} />
            </div>
            <button
              className="bg-primary text-white flex items-center gap-1 rounded-lg text-sm px-5 py-2 font-medium"
              onClick={readByType}
            >
              <CiFilter />
              Apply Filters
            </button>
          </div>
          <div className="SEARCH-BAR flex justify-between items-center mb-5 gap-12">
            <p className="font-medium text-[34px] leading-7 mr-8 ml-2 text-textP">Reports</p>
            <button
              className="bg-primary text-white flex items-center gap-1 rounded-lg text-sm px-4 py-2 font-medium"
              onClick={() => downloadExcel(activeTab === "Orders" ? ordersData : transactionData)}
            >
              <img src={exitIcon} alt="" />
              Export
            </button>
          </div>
          <div className="bg-secondary p-4 rounded-md md:h-[80vh] h-[70vh]">
            <DynamicTable
              setSelectedButton={setSelectedButton}
              headers={activeTab === "Orders" ? headersOrder : headersTransaction}
              data={activeTab === "Orders" ? ordersData : transactionData}
              includeImgVideoButton={true}
              reportsTable={true}
              orderTable={activeTab === "Orders"}
              paymentTable={activeTab !== "Orders"}
              userRole={userRole}
              onClickDeleteRow={() => {}}
            />
          </div>
        </div>
      </>
    </>
  );
};
