import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { TeethV2 } from "../Teeth-Comps/TeethV2";
import { Checkbox } from "../InputField-Comps/CheckboxField";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import RangeInputField from "../InputField-Comps/RangeInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";

import {
  useDeleteOrderMissingTeethMutation,
  useDeleteOrderServiceDetailsMutation,
  useDeleteOrderServiceMultiFieldMutation,
  useDeleteOrderServiceTeethMutation,
  useSaveOrderMissingTeethMutation,
  useSaveOrderServiceDetailsMutation,
  useSaveOrderServiceMultiFieldMutation,
  useSaveOrderServiceTeethMutation,
} from "../../services/orders";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { setDentalImplantOrderDetails } from "../../reducers/orderFormReducer";

import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import classes from "../Teeth-Comps/Teeth.module.scss";
import {
  IMPLANT_RESTORATION_DESIGN,
  INTERPROXIMAL_CONTACTS,
  OCCLUSAL_CLEARANCE_ACTION,
  OCCLUSAL_CONTACTS,
  OPTIONS_VALUE,
  ORDER_STAGE,
  PONTIC_DESIGN,
  SERVICE_TEETH_MAPPING,
  TYPE_OF_CROWN,
} from "../../constants/orderForms";

import interproximalContactsBroad from "../../Assets/images/interproximalContactsBroad.jpg";
import interproximalContactsNormal from "../../Assets/images/interproximalContactsNormal.jpg";
import interproximalContactsPoint from "../../Assets/images/interproximalContactsPoint.jpg";
import occlusalContactsPositive from "../../Assets/images/occlusalContactsPositive.jpg";
import occlusalContactsFoilRelief1 from "../../Assets/images/occlusalContactsFoilRelief1.jpg";
import occlusalContactsFoilRelief2 from "../../Assets/images/occlusalContactsFoilRelief2.jpg";
import occlusalContactsFoilRelief3 from "../../Assets/images/occlusalContactsFoilRelief3.jpg";
import ponticDesignSanitary from "../../Assets/images/ponticDesignSanitary.png";
import ponticDesignSaddle from "../../Assets/images/ponticDesignSaddle.png";
import ponticDesignConical from "../../Assets/images/ponticDesignConical.png";
import ponticDesignModified from "../../Assets/images/ponticDesignModified.png";
import ponticDesignOvate from "../../Assets/images/ponticDesignOvate.png";

export const ImplantCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  categoryObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { dentalImplantOrderDetails } = useSelector((state) => state.orderFormDetails);

  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();
  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [deleteOrderServiceDetails] = useDeleteOrderServiceDetailsMutation();
  const [saveOrderServiceTeeth] = useSaveOrderServiceTeethMutation();
  const [deleteOrderServiceTeeth] = useDeleteOrderServiceTeethMutation();
  const [saveOrderServiceMultiField] = useSaveOrderServiceMultiFieldMutation();
  const [deleteOrderServiceMultiField] = useDeleteOrderServiceMultiFieldMutation();

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.hasMissingTeeth === null ? "" : orderCaseData?.hasMissingTeeth ? "Yes" : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeethSelectedTeeth || []
  );
  const [restorativeTypeSelected, setRestorativeTypeSelected] = useState(
    orderCaseData?.services?.reduce((prev, curr) => {
      return { ...prev, [curr?.serviceObjId]: curr };
    }, {}) ?? {}
  );
  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [notation, setNotation] = useState(orderCaseData?.numberSystem || "FDI");

  const handleFieldChange = (value, name, isMulti = false, checked = false) => {
    setRestorativeTypeSelected((prev) => {
      let actualValue = value;

      if (isMulti) {
        if (checked) {
          actualValue = [...(prev?.[selectedService?.serviceObjId]?.[name] ?? []), value];
        } else {
          actualValue = (prev?.[selectedService?.serviceObjId]?.[name] ?? [])?.filter(
            (item) => item !== value
          );
        }
      }

      return {
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          [name]: actualValue,
        },
      };
    });
  };

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    if (restorativeTypeSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeSelected({
        ...restorativeTypeSelected,
        [selectedService?.serviceObjId]: updateTeethMapping(
          restorativeTypeSelected[selectedService?.serviceObjId],
          event.target.value
        ),
      });
    }
  };

  console.log("restorativeTypeSelected", restorativeTypeSelected);
  console.log("selectedService", selectedService);
  console.log("selectedServiceCategory", selectedServiceCategory);
  console.log("preferredSoftwares", preferredSoftwares);

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "CAD",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDetails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  const deleteService = async (service) => {
    setRestorativeTypeSelected((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      delete copy[service?.serviceObjId];
      return copy;
    });

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    deleteOrderServiceDetails({
      accessToken,
      _id: orderByData?._id,
      serviceObjId: service?.serviceObjId,
    });
  };

  const prepareServicesElements = useMemo(() => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeSelected.hasOwnProperty(eachService?.serviceObjId) &&
              Object.keys(restorativeTypeSelected[eachService?.serviceObjId] ?? {})?.length > 0
            }
            handleSelectedIcon={() => deleteService(eachService)}
            id={categoryObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  }, [
    categoryObjId,
    restorativeTypeSelected,
    selectedService?.serviceObjId,
    selectedServiceCategory?.catservices,
  ]);

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...(restorativeTypeSelected[d]?.implantSites ?? []));
      });
    return restrictedTeethData;
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDetails();
  }, []);

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
    if (event.target.value === "No") {
      setMissingTeethSelectedTeeth([]);
    }
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "restorationDesign",
      "interproximalContacts",
      "processType",
      "occlusalContacts",
      "processTypePreferredRestorativeSpace",
      "printableModelRequired",
      "printableModelincludeDies",
      "occlusalContacts",
      "ponticDesign",
      "cementGap",
      "needDesignPreview",
      "designFileNeeded",
      "preferredSoftware",
      "constructionFileNeeded",
      "restorationLacksOC",
      "additionalInstructions",
    ];

    return requiredFields.includes(fieldName);
  };

  const submitCaseDetailsForm = async () => {
    submitOrderData({
      numberSystem: notation,
      hasMissingTeeth: hasMissingTeeth,
      missingTeethSelectedTeeth: missingTeethSelectedTeeth,
      services: Object.keys(restorativeTypeSelected)?.map((key) => ({
        ...restorativeTypeSelected[key],
        serviceObjId: key,
      })),
    });
    setActiveTab(ORDER_STAGE.UPLOAD_FILES);
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleServiceTeethSave = async (value, teethKey) => {
    console.log("value", value);
    const actualTeethMapping = structuredClone(
      restorativeTypeSelected[selectedService?.serviceObjId]?.[teethKey] ?? []
    );
    console.log("actualTeethMapping", actualTeethMapping);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    const serviceDetails = dentalImplantOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: dentalImplantOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
      dispatch(
        setDentalImplantOrderDetails({
          ...dentalImplantOrderDetails,
          caseDetails: {
            ...(dentalImplantOrderDetails?.caseDetails ?? {}),
            services: [
              ...(dentalImplantOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [teethKey]: value },
            ],
          },
        })
      );
    }

    if (value?.length > actualTeethMapping?.length) {
      const teethNo = findExtraElement(actualTeethMapping, value);
      saveOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    } else if (value?.length < actualTeethMapping?.length) {
      const teethNo = findExtraElement(value, actualTeethMapping);
      deleteOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    }
  };

  const handleServiceMultiFieldSave = async (value, fieldKey, checked) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const serviceDetails = dentalImplantOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      dispatch(
        setDentalImplantOrderDetails({
          ...dentalImplantOrderDetails,
          caseDetails: {
            ...(dentalImplantOrderDetails?.caseDetails ?? {}),
            services: [
              ...(dentalImplantOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [fieldKey]: value },
            ],
          },
        })
      );
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: dentalImplantOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
    }

    if (checked) {
      saveOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    } else {
      deleteOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    }
  };

  const getTeethSelectionSummary = useCallback(
    (key) => {
      const selectedTeethSummary = [];
      selectedServiceCategory?.catservices?.forEach((service) => {
        if (restorativeTypeSelected?.[service?.serviceObjId]?.[key]) {
          selectedTeethSummary.push(service);
        }
      });

      return (
        <ul>
          {selectedTeethSummary?.map((item) => (
            <li key={item?.serviceObjId} className="mb-2">
              <p className="text-xs">{item?.serviceName}</p>
              <p className="font-bold text-sm">
                {(restorativeTypeSelected?.[item?.serviceObjId]?.[key] ?? [])?.join(", ")}
              </p>
            </li>
          ))}
        </ul>
      );
    },
    [restorativeTypeSelected, selectedServiceCategory?.catservices]
  );

  return (
    <div>
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth
            {isFieldRequired("hasMissingTeeth") && <span className="text-red-500 ml-1">*</span>}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          <div className="mt-4">
            {hasMissingTeeth === "Yes" && (
              <>
                <p className="text-white text-[16px] mb-2">Please select missing teeth</p>
                <TeethV2
                  key={`missingTeethSelectedTeeth${notation}`}
                  allTeethData={[]}
                  selectedTeeth={missingTeethSelectedTeeth}
                  setSelectedTeeth={handleMissingTeethSelection}
                  allowBulkTeethSelection={true}
                  defaultNotation={notation}
                  notationVisible={false}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">
            Implant Restoration Service Type
          </p>
          <p className="text-[16px] text-[#777] mb-8">
            Please click an implant restoration service type below and choose the implant sites that
            apply for that service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple implant restoration service types can be selected and multiple implant sites
              can be selected on each service type's charting system.
            </span>{" "}
            If you prefer not to use our charting system, you can also provide written instructions
            in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id && Object.keys(restorativeTypeSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {restorativeTypeSelected && (
              <div className="flex gap-4">
                <div>
                  <p className="text-white text-[16px] mb-2">
                    Please select all implant sites as applicable
                  </p>
                  <TeethV2
                    key={
                      selectedService?.serviceObjId +
                      restorativeTypeSelected[selectedService?.serviceObjId]?.length +
                      notation
                    }
                    allTeethData={[]}
                    disabledTeeths={getRestrictedTeeth(selectedService?.serviceObjId)}
                    disabledTeethMessage={
                      "This tooth is already selected in other restorative type"
                    }
                    selectedTeeth={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.implantSites || []
                    }
                    setSelectedTeeth={(value) => {
                      let result = value(
                        restorativeTypeSelected[selectedService?.serviceObjId]?.implantSites || []
                      );
                      handleFieldChange(result, "implantSites");
                      handleServiceTeethSave(result, "implantSites");
                    }}
                    defaultNotation={notation}
                    notationVisible={false}
                    missingTeeth={missingTeethSelectedTeeth}
                    missingTeethSelectable={true}
                  />
                </div>
                <div className="flex-1 text-white max-h-full overflow-y-auto">
                  <p className="font-bold underline">Summary</p>
                  {getTeethSelectionSummary("implantSites")}
                </div>
              </div>
            )}
            <div className="py-4 flex gap-4">
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
              ].includes(selectedService?.serviceName) && (
                <div className="w-full flex flex-col">
                  <p className="font-medium text-[17px] text-white mb-4">
                    {[
                      "Implant bridge (screw retained)",
                      "Implant bridge (cemented)",
                      "Implant bridge with gingiva (screw retained)",
                    ].includes(selectedService?.serviceName)
                      ? "Type of Bridge Retainer and Pontic"
                      : "Type of Crown"}{" "}
                    <small>(Optional)</small>
                  </p>
                  {selectedService?.serviceName ===
                    "Single or multiple implant crowns (cemented)" && (
                    <p className="text-[16px] text-[#777] mb-4">
                      All our cementable abutment restorations are designed as tissue level
                      abutments on a Ti base with a separate Ti base, abutment and restoration
                      depending on your selection below
                    </p>
                  )}
                  {selectedService?.serviceName === "Implant bridge (cemented)" && (
                    <p className="text-[16px] text-[#777] mb-4">
                      All our cementable abutment restorations are designed as tissue level
                      abutments on Ti bases with separate Ti bases, abutments and the restoration
                      depending on your selection below
                    </p>
                  )}
                  <div className="grid md:grid-flow-row grid-cols-1 grid-rows-1 md:gap-4 gap-4 text-white text-[16px] mx-3">
                    <Checkbox
                      label={TYPE_OF_CROWN.FULL_COUNTOUR_DESIGN}
                      value={TYPE_OF_CROWN.FULL_COUNTOUR_DESIGN}
                      checked={(
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.typeOfCrown ?? []
                      )?.includes(TYPE_OF_CROWN.FULL_COUNTOUR_DESIGN)}
                      onChange={(value, checked) => {
                        handleFieldChange(value, "typeOfCrown", true, checked);
                        handleServiceMultiFieldSave(value, "typeOfCrown", checked);
                      }}
                    />
                    <Checkbox
                      label={TYPE_OF_CROWN.CUT_BACK_DESIGN}
                      value={TYPE_OF_CROWN.CUT_BACK_DESIGN}
                      checked={(
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.typeOfCrown ?? []
                      )?.includes(TYPE_OF_CROWN.CUT_BACK_DESIGN)}
                      onChange={(value, checked) => {
                        handleFieldChange(value, "typeOfCrown", true, checked);
                        handleServiceMultiFieldSave(value, "typeOfCrown", checked);
                      }}
                    />
                    <Checkbox
                      label={TYPE_OF_CROWN.COPING_DESIGN_ONLY}
                      value={TYPE_OF_CROWN.COPING_DESIGN_ONLY}
                      checked={(
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.typeOfCrown ?? []
                      )?.includes(TYPE_OF_CROWN.COPING_DESIGN_ONLY)}
                      onChange={(value, checked) => {
                        handleFieldChange(value, "typeOfCrown", true, checked);
                        handleServiceMultiFieldSave(value, "typeOfCrown", checked);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            {[
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
            ].includes(selectedService?.serviceName) && (
              <p className="text-[#777] mb-5 text-[16px]">
                (Indicate surfaces for cutback, if any, in your notes below)
              </p>
            )}

            {[
              "Single or multiple implant crowns (screw retained)",
              "Implant bridge (screw retained)",
              "Implant bridge with gingiva (screw retained)",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex flex-col mb-8">
                <p className="mb-3">
                  {[
                    "Implant bridge (screw retained)",
                    "Implant bridge with gingiva (screw retained)",
                  ].includes(selectedService?.serviceName)
                    ? "Would you like a one piece restoration design (Ti bases and restoration) or a split restoration design (Ti bases and tissue level abutments and restoration)?"
                    : "Would you like a single restoration design (Ti base and restoration) or a split restoration design (Ti base and tissue level abutment and restoration)?"}
                  {isFieldRequired("restorationDesign") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="flex">
                  {[
                    "Implant bridge (screw retained)",
                    "Implant bridge with gingiva (screw retained)",
                  ].includes(selectedService?.serviceName) ? (
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={IMPLANT_RESTORATION_DESIGN.ONE_PIECE_RESTORATION}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.restorationDesign === IMPLANT_RESTORATION_DESIGN.ONE_PIECE_RESTORATION
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "restorationDesign")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">
                        {IMPLANT_RESTORATION_DESIGN.ONE_PIECE_RESTORATION}
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value={IMPLANT_RESTORATION_DESIGN.SINGLE_RESTORATION}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.restorationDesign === IMPLANT_RESTORATION_DESIGN.SINGLE_RESTORATION
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "restorationDesign")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">
                        {IMPLANT_RESTORATION_DESIGN.SINGLE_RESTORATION}
                      </p>
                    </div>
                  )}
                  <div className="flex items-center">
                    <CircularCheckbox
                      value={IMPLANT_RESTORATION_DESIGN.SPLIT_RESTORATION}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.restorationDesign === IMPLANT_RESTORATION_DESIGN.SPLIT_RESTORATION
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "restorationDesign")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">
                      {IMPLANT_RESTORATION_DESIGN.SPLIT_RESTORATION}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {[
              "Custom abutments",
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <>
                <div className="w-1/2 mb-8">
                  <RangeInputField
                    labelColor={"#fff"}
                    labelText={"Cement Gap (µ microns)"}
                    subtitle="If you are unsure what cement gap to request, please leave slider at 0µ. A default setting will then apply."
                    errorMessage={"Please enter a valid cement gap value"}
                    placeholder={""}
                    htmlFor={"cementGap"}
                    name={"cementGap"}
                    value={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.cementGap ?? "0"
                    }
                    handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                    validationFunctionName={validateNumber}
                    isSubmitted={isFieldRequired}
                    isFieldRequired={isFieldRequired("serviceSpecilization")}
                    unit="µ"
                    min={0}
                    max={200}
                  />
                </div>
              </>
            )}
            <div className="flex flex-col">
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "All on X hybrid with prep style posts for crowns",
              ].includes(selectedService?.serviceName) && (
                <>
                  <p className="font-medium text-[17px] text-white mb-2">
                    Interproximal Contacts
                    {isFieldRequired("interproximalContacts") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div className="flex-1 mt-4 mb-4 grid grid-cols-5 md:gap-12 gap-4 mx-3">
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={INTERPROXIMAL_CONTACTS.BROAD}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.interproximalContacts === INTERPROXIMAL_CONTACTS.BROAD
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "interproximalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={interproximalContactsBroad}
                        alt="interproximalContactsBroad"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={INTERPROXIMAL_CONTACTS.NORMAL}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.interproximalContacts === INTERPROXIMAL_CONTACTS.NORMAL
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "interproximalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={interproximalContactsNormal}
                        alt="interproximalContactsBroad"
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={INTERPROXIMAL_CONTACTS.POINT}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.interproximalContacts === INTERPROXIMAL_CONTACTS.POINT
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "interproximalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={interproximalContactsPoint}
                        alt="interproximalContactsBroad"
                      />
                    </div>
                  </div>
                </>
              )}
              {[
                "Single or multiple implant crowns (screw retained)",
                "Single or multiple implant crowns (cemented)",
                "Implant bridge (screw retained)",
                "Implant bridge (cemented)",
                "Implant bridge with gingiva (screw retained)",
                "Implant bar substructure for over denture",
                "All on X Hybrid (monolithic zirconia)",
                "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                "All on X hybrid with prep style posts for crowns",
                "All on X custom framework",
              ].includes(selectedService?.serviceName) && (
                <>
                  <p className="font-medium text-[17px] text-white mb-1 mt-3">
                    Occlusal Contacts
                    {isFieldRequired("occlusalContacts") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div className="flex-1 mt-4 mb-4 grid grid-cols-5 md:gap-12 gap-4 mx-3">
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={OCCLUSAL_CONTACTS.NONE}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.occlusalContacts === OCCLUSAL_CONTACTS.NONE
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "occlusalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsFoilRelief1}
                        alt={OCCLUSAL_CONTACTS.NONE}
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={OCCLUSAL_CONTACTS.LIGHT}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.occlusalContacts === OCCLUSAL_CONTACTS.LIGHT
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "occlusalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsFoilRelief2}
                        alt={OCCLUSAL_CONTACTS.LIGHT}
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={OCCLUSAL_CONTACTS.MEDIUM}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.occlusalContacts === OCCLUSAL_CONTACTS.MEDIUM
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "occlusalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsFoilRelief3}
                        alt={OCCLUSAL_CONTACTS.MEDIUM}
                      />
                    </div>
                    <div className="flex flex-col items-center">
                      <CircularCheckbox
                        value={OCCLUSAL_CONTACTS.HEAVY}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.occlusalContacts === OCCLUSAL_CONTACTS.HEAVY
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "occlusalContacts")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <img
                        className="mt-1 rounded-md overflow-hidden"
                        src={occlusalContactsPositive}
                        alt={OCCLUSAL_CONTACTS.HEAVY}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {[
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "Implant bar substructure for over denture",
              "All on X Hybrid (monolithic zirconia)",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-[17px] text-white mb-1 mt-5">
                  {[
                    "Implant bar substructure for over denture",
                    "All on X Hybrid (monolithic zirconia)",
                    "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                    "All on X hybrid with prep style posts for crowns",
                    "All on X custom framework",
                  ].includes(selectedService?.serviceName)
                    ? "Tissue Interface Design"
                    : "Pontic Design"}{" "}
                  <small>(Optional)</small>
                </p>
                <div className="flex-1 mt-4 mb-8 grid grid-cols-5 md:gap-12 gap-4 mx-3">
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value={PONTIC_DESIGN.SANITARY}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.ponticDesign ===
                        PONTIC_DESIGN.SANITARY
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "ponticDesign")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignSanitary}
                      alt="interproximalContactsBroad"
                    />
                    {/* <p className="text-[16px] text-white">Sanitary/Hygenic</p> */}
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value={PONTIC_DESIGN.SADDLE_RIDGE_LAP}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.ponticDesign ===
                        PONTIC_DESIGN.SADDLE_RIDGE_LAP
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "ponticDesign")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignSaddle}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value={PONTIC_DESIGN.CONICAL}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.ponticDesign ===
                        PONTIC_DESIGN.CONICAL
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "ponticDesign")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignConical}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value={PONTIC_DESIGN.MODIFIED_RIDGE_LAP}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.ponticDesign ===
                        PONTIC_DESIGN.MODIFIED_RIDGE_LAP
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "ponticDesign")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignModified}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                  <div className="flex flex-col items-center">
                    <CircularCheckbox
                      value={PONTIC_DESIGN.OVATE}
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.ponticDesign ===
                        PONTIC_DESIGN.OVATE
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "ponticDesign")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <img
                      className="mt-1 rounded-md overflow-hidden"
                      src={ponticDesignOvate}
                      alt="interproximalContactsBroad"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="text-white flex mb-8">
              ● Do you need to see a design preview?
              {isFieldRequired("needDesignPreview") && <span className="text-red-500 ml-1">*</span>}
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.needDesignPreview ===
                    "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "needDesignPreview")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.needDesignPreview ===
                    "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "needDesignPreview")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            {[
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "Implant bar substructure for over denture",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <div className="mb-4">
                <div className="mb-4">
                  <p className="text-[17px] text-white mb-3">
                    ●{" "}
                    {[
                      "Implant bar substructure for over denture",
                      "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                      "All on X custom framework",
                    ].includes(selectedService?.serviceName)
                      ? "The substructure is always designed to be milled in our designs. With reference to the superstructure, will you be"
                      : selectedService?.serviceName ===
                        "All on X hybrid with prep style posts for crowns"
                      ? "The substructure is always designed to be milled in our designs. With reference to the crowns, will you be"
                      : "Will you be"}
                    {isFieldRequired("processType") && <span className="text-red-500 ml-1">*</span>}
                  </p>
                  <div className="flex gap-y-4 gap-x-8 text-white">
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value={OPTIONS_VALUE.MILLING}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.processType ===
                          OPTIONS_VALUE.MILLING
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "processType")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{OPTIONS_VALUE.MILLING}</p>
                    </div>
                    <span>or</span>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={OPTIONS_VALUE.PRINTING}
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.processType ===
                          OPTIONS_VALUE.PRINTING
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "processType")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{OPTIONS_VALUE.PRINTING}</p>
                    </div>
                  </div>
                </div>
                {restorativeTypeSelected?.[selectedService?.serviceObjId]?.processType ===
                  OPTIONS_VALUE.PRINTING && (
                  <div className="w-1/2 mb-8">
                    <RangeInputField
                      labelColor={"#777"}
                      labelText={
                        "If you are printing what restorative space do you prefer? (µ microns)"
                      }
                      subtitle={
                        "If you are unsure what restorative space to request, please leave slider at 0µ. A default setting will then apply."
                      }
                      errorMessage={"Please enter a valid value"}
                      placeholder={""}
                      htmlFor={"processTypePreferredRestorativeSpace"}
                      name={"processTypePreferredRestorativeSpace"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.processTypePreferredRestorativeSpace ?? "0"
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateNumber}
                      isSubmitted={isFieldRequired}
                      isFieldRequired={isFieldRequired("processTypePreferredRestorativeSpace")}
                      unit="µ"
                      min={0}
                      max={200}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="text-white flex mb-2">
              ● Design file to be provided?
              {isFieldRequired("designFileNeeded") && <span className="text-red-500 ml-1">*</span>}
              <div className="flex items-center mx-3">
                <CircularCheckbox
                  value="Yes"
                  checked={
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.designFileNeeded ===
                    "Yes"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "designFileNeeded")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">Yes</p>
              </div>
              <div className="flex items-center">
                <CircularCheckbox
                  value="No"
                  checked={
                    restorativeTypeSelected?.[selectedService?.serviceObjId]?.designFileNeeded ===
                    "No"
                  }
                  onChange={(e) => handleFieldChange(e.target.value, "designFileNeeded")}
                  borderColor={"#FF8503"}
                  backgroundColor={"#FF8503"}
                />
                <p className="text-white text-[16px]">No</p>
              </div>
            </div>
            <p className="text-[#777] text-[16px] mb-8">
              This is a file in the original design software's format and you will need the
              appropriate design software to open it. Please do not request it unless you have the
              software and know how to manipulate the file.
            </p>
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p>
                  ● Preferred design software?
                  {isFieldRequired("preferredSoftware") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="grid grid-cols-5 gap-y-3 mt-1">
                  {preferredSoftwares
                    ?.filter(
                      (d) =>
                        !["MeditCAD", "Dentbird", "Clinux", "Hypsocad"].includes(d?.softwareName)
                    )
                    ?.map((item) => {
                      return (
                        <div className="flex items-center mx-3" key={item?.softwareObjId}>
                          <CircularCheckbox
                            value={item?.softwareName}
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.preferredSoftware === item?.softwareName
                            }
                            onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">{item?.softwareName}</p>
                        </div>
                      );
                    })}
                  <div className="flex items-center mx-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.preferredSoftware === "No Preferance"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            {[
              "Custom abutments",
              "Single or multiple implant crowns (screw retained)",
              "Single or multiple implant crowns (cemented)",
              "Implant bridge (screw retained)",
              "Implant bridge (cemented)",
              "Implant bridge with gingiva (screw retained)",
              "Implant bar substructure for over denture",
              "All on X Hybrid (monolithic zirconia)",
              "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
              "All on X hybrid with prep style posts for crowns",
              "All on X custom framework",
            ].includes(selectedService?.serviceName) && (
              <div className="text-white flex flex-col mb-8">
                <p className="mb-3">
                  ●{" "}
                  {[
                    "Implant bar substructure for over denture",
                    "All on X Hybrid (monolithic zirconia)",
                    "All on X hybrid with metal substructure bar and Zirconia /PMMA outer shell",
                    "All on X hybrid with prep style posts for crowns",
                    "All on X custom framework",
                  ].includes(selectedService?.serviceName)
                    ? "Restorations, superstructures and substructures are normally provided as .STLs, Please advise if a construction.info file is desired."
                    : "Restorations are normally provided as .STLs, Please advise if a construction.info file is desired."}
                  {isFieldRequired("constructionFileNeeded") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="flex">
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.constructionFileNeeded === "Yes"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "constructionFileNeeded")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.constructionFileNeeded === "No"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "constructionFileNeeded")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-6">
              <div className="text-white flex flex-col mb-4">
                <p className="mb-3">
                  ● Is a printable model desired?{" "}
                  <span className="text-[16px]">(additional cost)</span>
                  {isFieldRequired("printableModelRequired") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="flex">
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.printableModelRequired === "Yes"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "printableModelRequired")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.printableModelRequired === "No"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "printableModelRequired")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
              {restorativeTypeSelected?.[selectedService?.serviceObjId]?.printableModelRequired ===
                "Yes" && (
                <div className="text-white flex mb-4 mx-3">
                  Should the crown and bridge model include dies?{" "}
                  <span className="text-[16px]">(additional cost)</span>
                  {isFieldRequired("printableModelincludeDies") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.printableModelincludeDies === "Yes"
                      }
                      onChange={(e) =>
                        handleFieldChange(e.target.value, "printableModelincludeDies")
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.printableModelincludeDies === "No"
                      }
                      onChange={(e) =>
                        handleFieldChange(e.target.value, "printableModelincludeDies")
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              )}
            </div>
            <div className="text-white flex flex-col mb-8">
              <p className="mb-3">
                ● If a certain restoration lacks occlusal clearance? Please advise your preferred
                course of action:
                {isFieldRequired("restorationLacksOC") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <div className="flex flex-col mx-3">
                <div className="flex items-center mb-2">
                  <CircularCheckbox
                    value={OCCLUSAL_CLEARANCE_ACTION.REDUCE_LATER}
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.restorationLacksOC === OCCLUSAL_CLEARANCE_ACTION.REDUCE_LATER
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "restorationLacksOC")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">{OCCLUSAL_CLEARANCE_ACTION.REDUCE_LATER}</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value={OCCLUSAL_CLEARANCE_ACTION.REDUCE_MODAL}
                    checked={
                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                        ?.restorationLacksOC === OCCLUSAL_CLEARANCE_ACTION.REDUCE_MODAL
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "restorationLacksOC")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">
                    {OCCLUSAL_CLEARANCE_ACTION.REDUCE_MODAL} (additional cost)
                  </p>
                </div>
              </div>
            </div>
            <div>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Please enter additional instructions here"}
                isFieldRequired={isFieldRequired("additionalInstructions")}
                htmlFor={"additionalInstructions"}
                name={"additionalInstructions"}
                value={
                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.additionalInstructions ?? ""
                }
                handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                // validationFunctionName={validatePatientConcern}
                // isSubmitted={isPatientInitialFormSubmitted}
                rows={4}
                cols={5}
              />
              {restorativeTypeSelected?.[
                selectedService?.serviceObjId
              ]?.additionalInstructions?.trim()?.length > 0 ? null : (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab(ORDER_STAGE.PATIENT_DETAILS);
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
