import {
  useReadAdminCommOrdersMutation,
  useReadDeliveryCommsMutation,
  useReadDentistCommOrdersMutation,
  useReadRejectionCommsMutation,
  useReadSPCommOrdersMutation,
  useSaveCommOrdersMutation,
} from "../../services/orders";

import ticket from "../../Assets/icons/ticket.svg";
import replyIcon from "../../Assets/icons/replyIcon.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { AiOutlineArrowUp, AiOutlineClose } from "react-icons/ai";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import { useSaveFileMutation } from "../../services/uploadFiles";

import fileIcon from "../../Assets/images/file.png";
import moment from "moment";
import { GrFormAttachment } from "react-icons/gr";
import { VscSend } from "react-icons/vsc";
import { TICKET_TYPES } from "../../constants/common";

const OrderTickets = ({ orderObjId, orderDetails, activeSubTab, setActiveSubTab }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  console.log("orderDetails 798798797", orderDetails);
  console.log("userProfileDetails", userProfileDetails);

  const assetInputRef = useRef(null);
  const messageInputRef = useRef(null);

  const userRole = localStorage.getItem("crowdHireUserRole");

  const [saveFile] = useSaveFileMutation();
  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readAdminCommOrders] = useReadAdminCommOrdersMutation();
  const [readSPCommOrders] = useReadSPCommOrdersMutation();
  const [readDentistCommOrders] = useReadDentistCommOrdersMutation();
  const [readRejectionComms] = useReadRejectionCommsMutation();
  const [readDeliveryComms] = useReadDeliveryCommsMutation();

  const [communicationTickets, setCommunicationTickets] = useState([]);
  const [ticketCommunications, setTicketCommunications] = useState([]);
  const [ticketAsset, setTicketAsset] = useState(null);
  const [concernDescription, setConcernDescription] = useState("");
  const [replyDetails, setReplyDetails] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalReplyIsOpen, setModalReplyIsOpen] = useState(false);
  const [communicatingTo, setCommunicatingTo] = useState(
    userRole === "89uij7" || userRole === "676dza2"
      ? "serviceprovider"
      : userRole === "34fs3"
      ? "dentist"
      : "admin"
  );

  console.log("userRole", userRole);

  const downloadAsset = (url) => {
    const link = document.createElement("a");
    link.target = "_blank";
    const urlArray = url.split(".");
    link.download = `Attachment_${new Date().getTime()}.${urlArray?.[urlArray?.length - 1]}`;
    link.href = url;
    link.click();
  };

  const readOrderCommunications = async (parentObjId = null) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    let resp = null;

    if (activeSubTab === "Rejection Tickets") {
      resp = await readRejectionComms({
        accessToken,
        orderObjId: orderObjId,
        parentObjId: parentObjId === null ? undefined : parentObjId,
      }).unwrap();
    } else if (activeSubTab === "Delivery Tickets") {
      resp = await readDeliveryComms({
        accessToken,
        orderObjId: orderObjId,
        parentObjId: parentObjId === null ? undefined : parentObjId,
      }).unwrap();
    } else {
      if (userRole === "89uij7") {
        resp = await readAdminCommOrders({
          accessToken,
          orderObjId: orderObjId,
          parentObjId: parentObjId === null ? undefined : parentObjId,
        }).unwrap();
      } else if (userRole === "34fs3") {
        resp = await readSPCommOrders({
          accessToken,
          orderObjId: orderObjId,
          parentObjId: parentObjId === null ? undefined : parentObjId,
        }).unwrap();
      } else if (userRole === "676dza2") {
        resp = await readDentistCommOrders({
          accessToken,
          orderObjId: orderObjId,
          parentObjId: parentObjId === null ? undefined : parentObjId,
        }).unwrap();
      }
    }

    console.log("communication resp", resp);
    if (parentObjId) {
      setTicketCommunications(resp?.data);
    } else {
      setCommunicationTickets(resp?.data);
    }
  };

  useEffect(() => {
    if (orderObjId) readOrderCommunications();
  }, [orderObjId, activeSubTab]);

  const handleAssetUpload = async (e) => {
    const file = e.target.files?.[0];
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file: file,
    }).unwrap();

    setTicketAsset({ url: resp?.url, name: file?.name });
  };

  const createTicket = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let typeofComm;
    let touserAuthId;
    const serviceproviderdetails = orderDetails?.serviceproviderdetails?.find(
      (d) => d?.status === "1"
    );
    if (userRole === "89uij7" && communicatingTo === "serviceprovider") {
      typeofComm = "1";
      touserAuthId = serviceproviderdetails?.spuserAuthId;
    } else if (userRole === "89uij7" && communicatingTo === "dentist") {
      typeofComm = "1";
      touserAuthId = orderDetails?.dtuserAuthId;
    } else if (userRole === "676dza2" && communicatingTo === "admin") {
      typeofComm = "2";
      touserAuthId = undefined;
    } else if (userRole === "676dza2" && communicatingTo === "serviceprovider") {
      typeofComm = "2";
      touserAuthId = serviceproviderdetails?.spuserAuthId;
    } else if (userRole === "34fs3" && communicatingTo === "admin") {
      typeofComm = "3";
      touserAuthId = undefined;
    } else if (userRole === "34fs3" && communicatingTo === "dentist") {
      typeofComm = "3";
      touserAuthId = orderDetails?.dtuserAuthId;
    }

    if (activeSubTab === "Delivery Tickets") {
      typeofComm = "5";
    }

    const payload = {
      orderObjId: orderObjId,
      messagetext: concernDescription,
      assetUrl: ticketAsset?.url,
      typeofComm: typeofComm,
      fromuserAuthId: userRole === "89uij7" ? undefined : userProfileDetails?.userAuthId,
      touserAuthId: touserAuthId,
    };
    if (serviceproviderdetails?.spuserAuthId) {
      payload.spuserAuthId = serviceproviderdetails?.spuserAuthId;
    }
    if (replyDetails?.messageObjId) {
      payload.parentObjId = replyDetails?.messageObjId;
    }
    const resp = await saveCommOrders({
      accessToken,
      data: payload,
    });

    if (replyDetails?.messageObjId) {
      readOrderCommunications(replyDetails?.messageObjId);
    } else {
      setModalIsOpen(false);
      setReplyDetails("");
      readOrderCommunications();
    }
    setTicketAsset("");
    setConcernDescription("");
  };

  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-white text-2xl font-medium">Tickets</p>
        {activeSubTab !== "Rejection Tickets" && (
          <button
            className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
            onClick={() => setModalIsOpen(true)}
          >
            + Add New Ticket
          </button>
        )}
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-12 gap-4">
        {communicationTickets?.map((item, index) => (
          <div className="border border-[#D2D2D2] rounded-lg p-4" key={`ticket-item${index}`}>
            <div className="flex gap-4 items-center">
              <div className="bg-[#DCE6FD] h-12 w-12 rounded-full p-2 flex items-center justify-center">
                <img src={ticket} alt="" className="w-full" />
              </div>
              <p className="text-slate-300 font-medium text-xl">
                Ticket#{item?.messageObjId?.substring(item?.messageObjId?.length - 7)}
              </p>
            </div>
            <p className="text-white/40 text-[16px] mt-2">{item?.messagetext}</p>
            <div
              className="flex justify-between items-center text-primary my-4 cursor-pointer"
              //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
            >
              <div className="flex gap-3">
                <img src={replyIcon} alt="" />
                <p
                  className=""
                  onClick={() => {
                    setReplyDetails(item);
                    let toUser = "";
                    if (userRole === "676dza2") {
                      if (item?.fromuserAuthId === userProfileDetails?.userAuthId) {
                        toUser = item?.fromuserAuthId?.length === 0 ? "admin" : "serviceprovider";
                      } else {
                        toUser = item?.touserAuthId?.length === 0 ? "admin" : "serviceprovider";
                      }
                    } else if (userRole === "34fs3") {
                      if (item?.fromuserAuthId === userProfileDetails?.userAuthId) {
                        toUser = item?.fromuserAuthId?.length === 0 ? "admin" : "dentist";
                      } else {
                        toUser = item?.touserAuthId?.length === 0 ? "admin" : "dentist";
                      }
                    } else if (userRole === "89uij7") {
                      if (item?.fromuserAuthId === userProfileDetails?.userAuthId) {
                        toUser =
                          item?.fromuserAuthId?.[0] === orderDetails?.dtuserAuthId
                            ? "dentist"
                            : "serviceprovider";
                      } else {
                        toUser =
                          item?.touserAuthId?.[0] === orderDetails?.dtuserAuthId
                            ? "dentist"
                            : "serviceprovider";
                      }
                    }
                    setCommunicatingTo(toUser);
                    setModalReplyIsOpen(true);
                    readOrderCommunications(item?.messageObjId);
                  }}
                >
                  Reply
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setReplyDetails(null);
        }}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => {
                    setModalIsOpen(false);
                    setReplyDetails(null);
                  }}
                >
                  <AiOutlineClose className="ml-auto text-white" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">Add New Ticket</p>
              </div>
              <div className="flex-1 py-4">
                <TextAreaInputField
                  labelColor={"#fff"}
                  labelText={"Raise a support concern"}
                  errorMessage={"Description is required"}
                  placeholder={"Description"}
                  //   isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={(e) => setConcernDescription(e.target.value)}
                  //   validationFunctionName={validateConcern}
                  // isSubmitted={isConcernSubmitted}
                  rows={10}
                />
                <div className="flex flex-col gap-2 mb-4">
                  <label className="text-[16px] leading-4 text-white">
                    Send support concern to
                  </label>
                  <select
                    className="bg-backgroundV3 text-white h-fit py-1.5 px-2 rounded-md text-sm"
                    value={communicatingTo}
                    onChange={(e) => setCommunicatingTo(e.target.value)}
                  >
                    {userRole !== "676dza2" && <option value="dentist">Dentist</option>}
                    {userRole !== "34fs3" &&
                      orderDetails?.serviceproviderdetails?.some(
                        (d) => d?.status === "1" && d?.spuserAuthId
                      ) && <option value="serviceprovider">Service Provider</option>}
                    {userRole !== "89uij7" && <option value="admin">Site Support</option>}
                  </select>
                </div>
                <div>
                  <p className="text-white text-[16px] mb-2">Attachment Files</p>
                  <div className="relative">
                    <input
                      type="file"
                      className="hidden"
                      ref={assetInputRef}
                      onChange={handleAssetUpload}
                    />
                    {!ticketAsset?.url && (
                      <button
                        className="border border-primary rounded-full px-4 py-2 text-[12px] font-medium text-primary"
                        onClick={() => assetInputRef.current.click()}
                      >
                        <AiOutlineArrowUp className=" inline-block" /> Upload
                      </button>
                    )}

                    {ticketAsset?.url && (
                      <div className="flex items-center justify-between bg-backgroundV3 rounded-xl text-sm overflow-hidden">
                        <div className="flex items-center gap-2 py-2 px-2 overflow-hidden">
                          <img src={fileIcon} alt="" className="h-10 object-contain" />
                          <span className="overflow-ellipsis overflow-hidden whitespace-nowrap text-white">
                            {ticketAsset?.name}
                          </span>
                        </div>
                        <button className="h-full p-2" onClick={() => setTicketAsset(null)}>
                          <AiOutlineClose className="ml-auto text-primary" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={createTicket}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </>
      </ReactModal>
      <ReactModal
        isOpen={modalReplyIsOpen}
        onRequestClose={() => {
          setModalReplyIsOpen(false);
          setTicketCommunications([]);
        }}
        contentLabel="Custom Modal"
        className="custom-modal w-[40%]"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => {
                    setModalReplyIsOpen(false);
                    setTicketCommunications([]);
                  }}
                >
                  <AiOutlineClose className="ml-auto text-white" />
                </button>
                <div className="flex gap-4 items-center">
                  <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
                    <img src={ticket} alt="" />
                  </div>
                  <p className="text-slate-300 font-medium text-xl">
                    Ticket#
                    {replyDetails?.messageObjId?.substring(replyDetails?.messageObjId?.length - 7)}
                  </p>
                </div>
              </div>
              <div className="my-5">
                <div className="border border-[#D2D2D2] text-slate-300 rounded-lg p-4">
                  {replyDetails?.assetUrl && (
                    <div
                      className="flex items-center justify-between bg-backgroundV3 text-sm overflow-hidden mb-3 rounded-md cursor-pointer"
                      onClick={() => downloadAsset(replyDetails?.assetUrl)}
                    >
                      <div className="flex items-center gap-2 py-2 px-2">
                        <img src={fileIcon} alt="" className="h-5 object-contain" />
                        <span className="overflow-ellipsis overflow-hidden whitespace-nowrap text-white text-xs">
                          Attachment
                        </span>
                      </div>
                    </div>
                  )}
                  {replyDetails?.messagetext}
                </div>
              </div>
              <ul className="flex-1 flex flex-col overflow-x-hidden overflow-y-auto text-white">
                {ticketCommunications?.map(
                  (item, index) =>
                    (item?.fromuserAuthId?.length === 0 || item?.fromuserAuthId?.[0]) && (
                      <li
                        key={"chat-item" + index}
                        className={`flex w-full flex-col ${
                          item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId ||
                          (userRole === "89uij7" && item?.fromuserAuthId?.length === 0)
                            ? "items-end"
                            : "items-start"
                        } ${
                          ticketCommunications?.[index - 1]?.fromuserAuthId?.[0] ===
                          item?.fromuserAuthId?.[0]
                            ? "pt-1"
                            : "pt-2"
                        }`}
                      >
                        {moment(item?.lastUpdatedDate)?.format("DD/MM/YYYY") !==
                          moment(ticketCommunications?.[index - 1]?.lastUpdatedDate)?.format(
                            "DD/MM/YYYY"
                          ) && (
                          <div className="w-full flex items-center justify-center text-xs py-2.5">
                            <span className="bg-white/10 rounded-md px-2 py-1">
                              {moment(item?.lastUpdatedDate)?.format("DD/MM/YYYY")}
                            </span>
                          </div>
                        )}
                        <div
                          className={`w-fit rounded-lg px-1.5 py-1.5 text-sm max-w-[60%] relative flex flex-col ${
                            ticketCommunications?.[index - 1]?.fromuserAuthId?.[0] ===
                            item?.fromuserAuthId?.[0]
                              ? ""
                              : item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId ||
                                (userRole === "89uij7" && item?.fromuserAuthId?.length === 0)
                              ? "rounded-se-none"
                              : "rounded-ss-none"
                          } ${
                            item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId ||
                            (userRole === "89uij7" && item?.fromuserAuthId?.length === 0)
                              ? "bg-primary"
                              : "bg-backgroundV3"
                          }`}
                          onDoubleClick={() => {
                            setReplyDetails(item);
                            messageInputRef.current.focus();
                          }}
                        >
                          {ticketCommunications?.[index - 1]?.fromuserAuthId?.[0] !==
                            item?.fromuserAuthId?.[0] && (
                            <p className="text-xs font-bold">
                              {item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId ||
                              (userRole === "89uij7" && item?.fromuserAuthId?.length === 0)
                                ? "You"
                                : `${item?.fromFirstName?.[0] ?? ""} ${
                                    item?.fromLastName?.[0] ?? ""
                                  }`}
                            </p>
                          )}
                          {item?.assetUrl && (
                            <div
                              className="flex items-center justify-between bg-backgroundV3 text-sm overflow-hidden rounded-md cursor-pointer"
                              onClick={() => downloadAsset(item?.assetUrl)}
                            >
                              <div className="flex items-center gap-2 py-2 px-2">
                                <img src={fileIcon} alt="" className="h-5 object-contain" />
                                <span className="overflow-ellipsis overflow-hidden whitespace-nowrap text-white text-xs">
                                  Attachment
                                </span>
                              </div>
                            </div>
                          )}
                          <p className="pr-3 break-words">{item?.messagetext}</p>
                          <span
                            className={`border-[6px] border-x-transparent border-b-transparent absolute top-0 ${
                              item?.fromuserAuthId?.[0] === userProfileDetails?.userAuthId ||
                              (userRole === "89uij7" && item?.fromuserAuthId?.length === 0)
                                ? "right-0 translate-x-1/2 border-primary"
                                : "left-0 -translate-x-1/2 border-backgroundV3"
                            } ${
                              ticketCommunications?.[index - 1]?.fromuserAuthId?.[0] ===
                              item?.fromuserAuthId?.[0]
                                ? "hidden"
                                : ""
                            }`}
                          ></span>
                          <p className="flex items-center justify-end text-xxs text-white/80">
                            {moment(item?.lastUpdatedDate)?.format("HH:MM")}
                          </p>
                        </div>
                      </li>
                    )
                )}
              </ul>

              {ticketAsset?.url && (
                <div className="flex items-center justify-between bg-backgroundV3 text-sm overflow-hidden">
                  <div className="flex items-center gap-2 py-2 px-2">
                    <img src={fileIcon} alt="" className="h-10 object-contain" />
                    <span className="overflow-ellipsis overflow-hidden whitespace-nowrap text-white">
                      {ticketAsset?.name}
                    </span>
                  </div>
                  <button className="h-full p-2" onClick={() => setTicketAsset(null)}>
                    <AiOutlineClose className="ml-auto text-primary" />
                  </button>
                </div>
              )}
              <div className="w-full flex items-center gap-2 py-1 mt-3 rounded-md px-1 bg-white/5 text-white text-sm">
                <input
                  type="file"
                  className="hidden"
                  ref={assetInputRef}
                  onChange={handleAssetUpload}
                />

                <GrFormAttachment
                  className="text-3xl cursor-pointer"
                  onClick={() => assetInputRef.current.click()}
                />
                {/* <select
                  className="bg-backgroundV2 h-fit py-2 px-0.5"
                  value={communicatingTo}
                  onChange={(e) => setCommunicatingTo(e.target.value)}
                >
                  {userRole !== "676dza2" && <option value="dentist">Dentist</option>}
                  {userRole !== "34fs3" &&
                    orderDetails?.serviceproviderdetails?.some(
                      (d) => d?.status === "1" && d?.spuserAuthId
                    ) &&
                    replyDetails?.fromuserAuthId?.[0] && (
                      <option value="serviceprovider">Service Provider</option>
                    )}
                  {userRole !== "89uij7" && <option value="admin">Site Support</option>}
                </select> */}
                <input
                  type="text"
                  placeholder="Type a message"
                  className="flex-1 py-3 bg-transparent outline-none"
                  value={concernDescription}
                  onChange={(e) => setConcernDescription(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      createTicket();
                      return;
                    }
                  }}
                  ref={messageInputRef}
                />
                {concernDescription?.length > 0 && (
                  <VscSend className="text-xl text-white cursor-pointer" onClick={createTicket} />
                )}
              </div>
            </div>
          </div>
        </>
      </ReactModal>
    </div>
  );
};

export default OrderTickets;
