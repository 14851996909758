import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function NumberInputWithCountryCodeField({ labelText, errorMessage, labelColor, placeholder, name, value, htmlFor, handleInputChange, isSubmitted, validationFunctionName, isFieldRequired }) {
  const [ifInvalid, setIfInvalid] = useState(false);
  useEffect(() => {
    if (value?.length > 0) {
      setIfInvalid(false);
    }
  }, [value]);

  return (
    <div className="relative">
      <label
        htmlFor={htmlFor}
        className="text-[16px] text-white leading-4"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500">*</span> : ""}
      </label>
      <PhoneInput
        inputProps={{
          name: name,
          required: isFieldRequired,
          autoFocus: true,
          placeholder: placeholder
        }}
        enableLongNumbers={true}
        value={value}
        onChange={(value)=> handleInputChange(value)}
        inputClass={`!border !w-full p-2 text-white !bg-black !text-[16px] !leading-4 !rounded-md !h-10 ${(isSubmitted && !validationFunctionName(value) && isFieldRequired) || ifInvalid ? "!border-red-500" : ""}`}
        containerClass="mt-2 mb-4"
      />
      {isSubmitted && !validationFunctionName(value) && isFieldRequired && <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">{errorMessage}</p>}
      {ifInvalid && <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>}
    </div>
  );
}
