import React, { useState, useEffect } from "react";
import classNames from "classnames";

const ToggleButton = ({ updateActiveStatus, currentServicePrDetails }) => {
  const [isActive, setIsActive] = useState(currentServicePrDetails?.active === 1 || false);

  const toggleButtonClasses = classNames(
    "relative inline-block w-[30px] h-[18px] rounded-full transition duration-300 ease-in-out",
    {
      "bg-gray-300": !isActive,
      "bg-primary": isActive,
    }
  );

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  console.log(isActive, 'active');

  return (
    <button className={toggleButtonClasses}
      onClick={() => {
        handleToggle();
        updateActiveStatus(!isActive ? 1 : 0, currentServicePrDetails?.userid);
      }}>
      <span
        className={classNames(
          "inline-block relative -left-[10px] bottom-[1px] w-[20px] aspect-square rounded-full bg-white shadow transform transition duration-300 ease-in-out",
          {
            "translate-x-4": isActive,
            "translate-x-0": !isActive,
          }
        )}
      ></span>
    </button>
  );
};

export default ToggleButton;
