import React, { useState, useRef } from "react";
import { AiOutlineArrowUp, AiOutlineCheck } from "react-icons/ai";
import { useSaveFileMutation } from "../../services/uploadFiles";
import { useAuth0 } from "@auth0/auth0-react";
import { useSaveOrderScansMutation, useUpdateOrderScansMutation } from "../../services/orders";
import JSZip from "jszip";
import LoaderSpinner from "../Loader/LoaderSpinner";

const ImageUpload = ({
  placeholderImage,
  imageUrl,
  setImageUrl,
  title,
  id,
  fileFor,
  fileType,
  orderObjId,
  acceptedFileTypes = "images/*",
  allowFolderUpload = false,
  showImagePreview = true,
  fileName = null,
  customImageUpload = false,
  handleCustomImageUpload = () => {},
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveFile] = useSaveFileMutation();
  const [saveOrderScans] = useSaveOrderScansMutation();
  const [updateOrderScans] = useUpdateOrderScansMutation();
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  console.log("orderDetails imageupload", orderObjId);

  const isDisplayableFormat = (filename) => {
    const extension = filename?.split(".")?.pop();

    if (["png", "jpg", "jpeg", "webp", "bmp"].includes(extension)) {
      return true;
    }
    return false;
  };

  const handleImageUpload = async (event) => {
    // debugger
    let file = null;
    setLoading(true);
    if (allowFolderUpload) {
      const files = event.target.files;
      console.log("files", files);
      const zip = new JSZip();
      console.log("zip", zip);
      [...files].forEach((file) => {
        zip.file(file.name, file);
      });
      console.log("zip with files added", zip);
      const content = await zip.generateAsync({ type: "blob" });
      console.log("zip blob content", content);
      const fileName =
        files?.[0]?.webkitRelativePath?.split("/")?.shift() + "-" + new Date().getTime() + ".zip";
      file = new File([content], fileName, {
        type: "application/zip",
        // lastModified: new Date().getTime(),
        lastModified: "Test",
      });
      console.log("zip file", file);
    } else {
      file = event.target.files[0];
    }

    // Access and use the uploaded files here
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file,
    });
    setLoading(false);
    setIsHovered(false);
    if (resp) {
      // debugger
      console.log("setImageUrl", setImageUrl);
      if (resp?.data?.url) {
        setImageUrl((preState) => ({
          ...preState,
          s3Url: resp?.data?.url,
          fileName: resp?.data?.filename,
          fileFor: fileFor,
        }));
      }
      if (customImageUpload) {
        handleCustomImageUpload(resp?.data?.url);
        return;
      }
      if (!id) {
        const saveResp = await saveOrderScans({
          accessToken,
          orderObjId: orderObjId,
          fileType: fileType,
          fileFor: fileFor,
          s3Url: resp?.data?.url,
        }).unwrap();
        console.log("saveResp", saveResp);
        setImageUrl((preState) => ({ ...preState, _id: saveResp?.data?.[0]?._id }));
      } else {
        await updateOrderScans({
          accessToken,
          _id: id,
          fileFor: fileFor,
          s3Url: resp?.data?.url,
        });
      }
    }

    // const reader = new FileReader();

    // // reader.onload = () => {
    // //   setImageUrl(reader.result);
    // // };

    // if (file) {
    //   reader.readAsDataURL(file);
    // }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative h-full w-full flex flex-col gap-2 items-center">
      <div
        className="image-container relative h-full w-full border rounded-md overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered && !isLoading && (
          <div className="upload-button h-full absolute w-full top-0 left-0 backdrop-blur-[2px] backdrop-contrast-50 backdrop-brightness-50  cursor-pointer  z-20 rounded-md">
            <button
              className="absolute flex items-center gap-2  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[12px] font-medium px-4 py-2 rounded-full border border-white"
              onClick={handleUploadButtonClick}
            >
              <AiOutlineArrowUp />
              <span>Upload</span>
            </button>
            <input
              type="file"
              accept={acceptedFileTypes}
              {...(allowFolderUpload
                ? { webkitdirectory: "", mozdirectory: "", directory: "", multiple: true }
                : {})}
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </div>
        )}
        {!isLoading && imageUrl && (
          <div className="absolute top-2 right-2 z-50 bg-[#8ED259] h-5 w-5 rounded-full text-white font-medium flex items-center justify-center">
            <AiOutlineCheck className="text-xs" />
          </div>
        )}
        {showImagePreview && isDisplayableFormat(imageUrl) && imageUrl && !isLoading ? (
          <img
            src={imageUrl}
            alt="Uploaded"
            className="absolute top-0 z-10 h-full w-full object-cover overflow-hidden"
          />
        ) : (
          <div className="bg-black w-full h-full flex justify-center flex-col items-center">
            <img
              src={placeholderImage}
              alt="Uploaded"
              className="z-10 w-auto max-w-full object-contain overflow-hidden"
            />
            {((allowFolderUpload && !isLoading && imageUrl) || !isDisplayableFormat(imageUrl)) && (
              <p className="text-primary text-sm mb-1">{fileName}</p>
            )}
          </div>
        )}
        {isLoading && (
          <div className="upload-button h-full absolute w-full top-0 left-0 backdrop-blur-[2px] backdrop-contrast-50 backdrop-brightness-50  cursor-pointer  z-20 rounded-md">
            <div className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
              <LoaderSpinner className="h-16 w-16 object-cover overflow-hidden" />
            </div>
          </div>
        )}
      </div>
      {title && <div className="text-base">{title}</div>}
    </div>
  );
};

export default ImageUpload;
