import { Fragment, useEffect, useRef, useState } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  superAdminIconArray,
  spIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import StageBox from "../../Components/Order-Comps/StageBox";
import rejectedIcon from "../../Assets/icons/rejectedIcon.svg";
import completeIcon from "../../Assets/icons/completeIcon.svg";
import { useLocation } from "react-router-dom";
import {
  useGetOrderByIdMutation,
  useGetOrderPaymentTransactionsMutation,
  useGetServiceProviderOrdersMutation,
  useSaveCommOrdersMutation,
  useUpdateOrderByIdMutation,
  useUpdateServiceProviderOrdersMutation,
  useVerifyOrderPaymentTransactionMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import OrderSummary from "../../Components/OrderSummary-Comps/OrderSummary";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import DynamicTable from "../../Components/Services-Comps/DynamicTable";
import OrderNotesHistory from "../Orders/OrderNotesHistory";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import OrderTickets from "../Orders/OrderTickets";
import { TICKET_TYPES } from "../../constants/common";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";

export const OrderDetailSummary = ({
  data,
  setIsStepOneChecked,
  isStepOneChecked,
  setActiveTab,
  orderDetails,
  setOrderDetails,
  pdfRef,
}) => {
  console.log(data?.statusCode, "statusCode");
  return (
    <OrderSummary data={orderDetails} transformDetails={true} showTopTiles={false} ref={pdfRef} />
  );
};

export const PaymentMethod = ({
  data,
  setActiveTab,
  setIsStepTwoChecked,
  setIsStepThreeChecked,
  setPaymentSuccess,
  orderDetails,
  sessionId,
  canceled,
  servicesInfo,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [updateOrderById] = useUpdateOrderByIdMutation();
  const [verifyOrderPaymentTransaction] = useVerifyOrderPaymentTransactionMutation();
  const [getOrderPaymentTransactions] = useGetOrderPaymentTransactionsMutation();
  const [transactions, setTransactions] = useState([]);
  const [paymentStatusLoading, setPaymentStatusLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentCanceled, setPaymentCanceled] = useState(false);

  console.log("canceled", canceled, typeof canceled);
  console.log("transactions987", transactions);
  console.log("transactions987data", data);

  const stripePromise = loadStripe(
    "pk_test_51NaIVOSDUVHTemdGBj5nt2XlOAsCvakNhQuMHofTaw3PU2P222GomNoaVQeJkIek8B3J9679HHDxRQPLRUEiKgE100IhZpG54x"
  );

  const priorityFee = ((orderDetails?.serviceproviderdetails?.[0].portalValue || 0) * 20) / 100;

  const totalAmount = (
    Number(priorityFee) + Number(orderDetails?.serviceproviderdetails?.[0].portalValue || 0)
  ).toFixed(2);

  const options = {
    mode: "payment",
    amount: parseInt(totalAmount),
    currency: "usd",
  };

  const handlePaymentSuccess = async (accessToken) => {
    const resp = await updateOrderById({
      accessToken,
      orderId: data?.orderObjId,
      currentStatus: "5",
    });
    if (resp) {
      GetToast("Order Payment Successful");
    }
  };

  const checkPaymentStatus = async (sessionId, callback) => {
    if (sessionId) {
      setPaymentStatusLoading(true);

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await verifyOrderPaymentTransaction({
        accessToken,
        data: { sessionId: sessionId, orderObjId: data?.orderObjId },
      }).unwrap();

      console.log("payment verification response", response);
      if (response?.success) {
        handlePaymentSuccess(accessToken);
        setIsStepTwoChecked(true);
        setIsStepThreeChecked(true);
        setPaymentSuccess(true);
        setActiveTab("Confirmation");
        // } else if (canceled === "true" && response?.sessionPaymentStatus === "unpaid") {
      } else if (response?.sessionPaymentStatus === "unpaid") {
        setPaymentCanceled(true);
        GetToast(response.message);
      }
      if (callback) {
        callback();
      }
      setPaymentStatusLoading(false);
    }
  };

  useEffect(() => {
    checkPaymentStatus(sessionId);
  }, [sessionId]);

  const fetchOrderPaymentTransactions = async () => {
    if (data?.orderObjId) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const resp = await getOrderPaymentTransactions({
        accessToken,
        data: {
          orderObjId: data?.orderObjId,
        },
      }).unwrap();
      console.log("resp transaction", resp?.data);
      const filterData = resp?.data?.filter((d) => d?.payments);
      setTransactions(
        filterData?.map((d) => ({
          ...d?.payments,
        }))
      );
    }
  };

  useEffect(() => {
    fetchOrderPaymentTransactions();
  }, [data?.orderObjId]);

  const headersTransaction = [
    {
      headerId: "amount",
      headerName: "Amount",
      headerSort: true,
    },
    {
      headerId: "lastUpdatedDate",
      headerName: "Payment Date",
      headerSort: true,
    },
    {
      headerId: "status",
      headerName: "Status",
      headerSort: true,
    },
    {
      headerId: "actions",
      headerName: "Actions",
      headerSort: false,
    },
  ];

  return (
    <>
      <div className="font-semibold h-full flex-1 flex flex-col text-white">
        <p className="text-xl mb-4">Past Trasactions</p>
        <div className="bg-white/10 p-4 rounded-md mb-6">
          <DynamicTable
            headers={headersTransaction}
            data={transactions}
            enableSearch={false}
            includeImgVideoButton={true}
            paymentTable={true}
            onPaymentRefresh={(item) => {
              console.log("item", item);
              checkPaymentStatus(item?.sessionId, () => {
                fetchOrderPaymentTransactions();
              });
            }}
          />
        </div>
      </div>
      <div className="font-semibold h-full flex-1 flex flex-col text-white mb-8">
        <p className="mb-3">
          Please be aware that if a refund for this order is ever initiated, the Stripe payment
          gateway's fees will not be refundable. Only the actual amount paid for the order will be
          refundable.
        </p>
        <div className="text-xl mb-4 flex items-center justify-between">
          <span>Payment</span>
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm
              amount={parseInt(totalAmount)}
              orderObjId={data?.orderObjId}
              setActiveTab={setActiveTab}
              paymentProcessing={paymentProcessing || paymentStatusLoading}
              setPaymentProcessing={setPaymentProcessing}
              servicesInfo={servicesInfo}
            />
          </Elements>
        </div>
        {paymentCanceled && (
          <p className="text-red-600 text-xs mb-2">
            Your last Payment was cancelled, please try again
          </p>
        )}
        <div className="p-2 border border-gray-400 rounded-md">
          <div className="flex justify-between m-4 ">
            <p>Price of the Service</p>
            <p>
              $ {parseFloat(orderDetails?.serviceproviderdetails?.[0].portalValue || 0).toFixed(2)}
            </p>
          </div>

          <div className="flex justify-between m-4 ">
            <p>Priority Order Fee</p>
            <p>$ {parseFloat(priorityFee).toFixed(2)}</p>
          </div>
          <div className="flex justify-between m-4 border-t border-gray-300 pt-4 ">
            <p>Total</p>
            <p>
              ${" "}
              {parseFloat(
                Number(priorityFee) +
                  Number(orderDetails?.serviceproviderdetails?.[0].portalValue || 0)
              ).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const Confirmation = ({ paymentSuccess }) => {
  const navigate = useNavigate();
  return (
    <div className="flex-1 flex flex-col h-full text-white">
      <div className="h-full flex-1 flex flex-col justify-center items-center gap-4">
        <img
          src={paymentSuccess ? completeIcon : rejectedIcon}
          alt=""
          className={`${paymentSuccess ? "w-3/12" : "w-2/12"}`}
        />
        <p className="text-xl font-medium">
          {paymentSuccess
            ? "Payment Successful!"
            : "Transaction failed. Please contact site administrator"}
        </p>
        <p className="text-[17px] font-[#555] ">
          {paymentSuccess ? "Order under process." : "Order pending."}
        </p>
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              navigate("/orders");
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export const Delivery = ({ orderDetails, setActiveSubTab, setActiveTab }) => {
  return (
    <div className="flex-col">
      <OrderNotesHistory notes={orderDetails?.notes} />
    </div>
  );
};

export const OrderTab = ({ data, userRole, userInfo }) => {
  // debugger
  const { user, isAuthenticated } = useAuth0();
  const pdfRef = useRef();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [updateOrderById] = useUpdateOrderByIdMutation();
  const [getOrderById] = useGetOrderByIdMutation();
  const [getServicesCategories] = useGetServicesCategoriesMutation();
  const [getServiceProviderOrders] = useGetServiceProviderOrdersMutation();
  const [updateServiceProviderOrders] = useUpdateServiceProviderOrdersMutation();
  const [saveCommOrders] = useSaveCommOrdersMutation();

  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [disputeModalOpen, setDisputeModalOpen] = useState(false);
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);
  const [isOrderRejected, setIsOrderRejected] = useState(false);
  const [isDisputeRaised, setIsDisputeRaised] = useState(false);
  const [rejectionNote, setRejectionNote] = useState("");
  const [isStepOneChecked, setIsStepOneChecked] = useState(false);
  const [isStepTwoChecked, setIsStepTwoChecked] = useState(false);
  const [isStepThreeChecked, setIsStepThreeChecked] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState(TICKET_TYPES.SUPPORT_TICKET);

  const tabNamesArray = [];
  if (
    orderDetails?.statusCode === "104" ||
    orderDetails?.statusCode === "106" ||
    orderDetails?.statusCode === "110" ||
    orderDetails?.statusCode === "119" ||
    orderDetails?.statusCode === "122" ||
    orderDetails?.statusCode === "125"
  ) {
    tabNamesArray.push("Order Details");
    // tabNamesArray.push("Order Communications");
  } else if (orderDetails?.statusCode === "113") {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Payment Method");
    tabNamesArray.push("Confirmation");
  } else {
    tabNamesArray.push("Order Details");
    // tabNamesArray.push("Order Communications");
  }

  if (["122", "125", "128", "131", "134", "137"].includes(orderDetails?.statusCode)) {
    tabNamesArray.push("Delivery Files");
  }
  tabNamesArray.push("Support Tickets");

  const [serviceProviderOrdersData, setServiceProviderOrdersData] = useState([]);
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const [servicesInfo, setServicesInfo] = useState([]);

  console.log("servicesInfo", servicesInfo);

  const getServicesCategoriesData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await getServicesCategories(accessToken).unwrap();
    console.log("test data?.categoryObjId", orderDetails?.categoryObjId?.[0]);
    console.log("test resp456", resp?.data, orderDetails?.categoryObjId?.[0]);
    const info = [];
    console.log("data.categoryObjId", orderDetails?.categoryObjId?.[0]);
    if (resp?.data && orderDetails?.categoryObjId?.[0]) {
      resp?.data?.forEach((d) => {
        if (d?._id === orderDetails?.categoryObjId?.[0]) {
          console.log("found data", d);
          d?.catservices?.forEach((service) => {
            const find = orderDetails?.services?.find(
              (f) => f?.serviceObjId === service?.serviceObjId
            );
            if (find) {
              info.push(service);
            }
          });
        }
      });
    }
    setServicesInfo(info);
  };

  const getOrderData = async (accessToken, orderId) => {
    console.log("orderId7784", orderId);
    const resp = await getOrderById({ accessToken, orderId, dentist: "1" }).unwrap();
    if (resp) {
      setOrderDetails({ ...resp.data?.[0], orderObjId: orderId });
      const respOrder = await getServiceProviderOrders({
        accessToken,
        orderObjId: resp.data[0]?._id,
      }).unwrap();
      setServiceProviderOrdersData(respOrder.data);
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      const orderObjId = data?.orderObjId ?? searchParams.get("orderObjId");
      await getOrderData(accessToken, orderObjId);
    }
  };

  useEffect(() => {
    console.log("useEffect services working --- ");
    readOrderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderDetails?.services?.length > 0) {
      getServicesCategoriesData();
    }
  }, [orderDetails]);

  console.log("data9878798", data);

  useEffect(() => {
    if (searchParams.has("orderObjId") && searchParams.has("session_id")) {
      setIsStepOneChecked(true);
      setActiveTab("Payment Method");
    }
  }, [searchParams]);

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  console.log("orderDetails 77888", orderDetails);
  console.log("statusCode 77888", orderDetails?.statusCode);

  var outputObject = {};
  if (
    orderDetails?.statusCode === "104" ||
    orderDetails?.statusCode === "106" ||
    orderDetails?.statusCode === "110" ||
    orderDetails?.statusCode === "119" ||
    orderDetails?.statusCode === "122" ||
    orderDetails?.statusCode === "125"
  ) {
    outputObject = {
      "Order Details": (
        <OrderDetailSummary
          data={orderDetails}
          setIsStepOneChecked={setIsStepOneChecked}
          isStepOneChecked={isStepOneChecked}
          setActiveTab={setActiveTab}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          pdfRef={pdfRef}
        />
      ),
      "Support Tickets": (
        <OrderTickets
          orderObjId={orderDetails?._id}
          orderDetails={orderDetails}
          activeSubTab={activeSubTab}
          setActiveSubTab={setActiveSubTab}
        />
      ),
    };
  } else if (orderDetails?.statusCode === "113") {
    outputObject = {
      "Order Details": (
        <OrderDetailSummary
          data={orderDetails}
          setIsStepOneChecked={setIsStepOneChecked}
          isStepOneChecked={isStepOneChecked}
          setActiveTab={setActiveTab}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          pdfRef={pdfRef}
        />
      ),
      "Payment Method": (
        <PaymentMethod
          data={orderDetails}
          setIsStepTwoChecked={setIsStepTwoChecked}
          setIsStepThreeChecked={setIsStepThreeChecked}
          setPaymentSuccess={setPaymentSuccess}
          isStepTwoChecked={isStepTwoChecked}
          setActiveTab={setActiveTab}
          orderDetails={orderDetails}
          sessionId={searchParams.get("session_id")}
          canceled={searchParams.get("canceled")}
          servicesInfo={servicesInfo}
        />
      ),
      Confirmation: <Confirmation paymentSuccess={paymentSuccess} />,
      "Support Tickets": (
        <OrderTickets
          orderObjId={orderDetails?._id}
          orderDetails={orderDetails}
          activeSubTab={activeSubTab}
          setActiveSubTab={setActiveSubTab}
        />
      ),
    };
  } else {
    outputObject = {
      "Order Details": (
        <OrderDetailSummary
          data={orderDetails}
          setIsStepOneChecked={setIsStepOneChecked}
          setIsStepThreeChecked={setIsStepThreeChecked}
          isStepOneChecked={isStepOneChecked}
          setActiveTab={setActiveTab}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          pdfRef={pdfRef}
        />
      ),
      "Payment Method": (
        <PaymentMethod
          data={orderDetails}
          setIsStepTwoChecked={setIsStepTwoChecked}
          setPaymentSuccess={setPaymentSuccess}
          isStepTwoChecked={isStepTwoChecked}
          setActiveTab={setActiveTab}
          orderDetails={orderDetails}
          sessionId={searchParams.get("session_id")}
          canceled={searchParams.get("canceled")}
          servicesInfo={servicesInfo}
        />
      ),
      "Support Tickets": (
        <OrderTickets
          orderObjId={orderDetails?._id}
          orderDetails={orderDetails}
          activeSubTab={activeSubTab}
          setActiveSubTab={setActiveSubTab}
        />
      ),
    };
  }

  if (["122", "125", "128", "131", "134", "137"].includes(orderDetails?.statusCode)) {
    outputObject["Delivery Files"] = (
      <Delivery
        data={data}
        orderDetails={orderDetails}
        setActiveSubTab={setActiveSubTab}
        setActiveTab={setActiveTab}
      />
    );
  }

  const createTicket = async (accessToken, message) => {
    const payload = {
      orderObjId: orderDetails?._id,
      messagetext: message + rejectionNote,
      assetUrl: "",
      typeofComm: "4",
      fromuserAuthId: userProfileDetails?.userAuthId,
      touserAuthId: undefined,
    };
    if (orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId) {
      payload.spuserAuthId = orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId;
    }
    const resp = await saveCommOrders({
      accessToken,
      data: payload,
    });
  };

  const handleRejectOrder = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await updateOrderById({
      accessToken,
      orderId: orderDetails?.orderObjId,
      currentStatus: "6",
    });
    serviceProviderOrdersData?.map((d) => {
      if (d?.status === "1") {
        updateServiceProviderOrders({
          accessToken,
          status: "0",
          _id: d?._id,
          spOrderObjId: d?.spOrderObjId,
          orderObjId: orderDetails?.orderObjId,
        });
      }
    });
    if (resp) {
      createTicket(accessToken, "ORDER PRICE REJECTED BY DENTIST: ");
      GetToast("Order rejected, you can contact Administrator for any queries.");
      navigate("/orders");
    }
  };

  const handleDeliverySubmit = async (type) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (type === "A") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?.orderObjId,
        currentStatus: "8",
      });
      if (resp) {
        GetToast("Order Delivered, you can contact Administrator for any queries.");
      }
    } else {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?.orderObjId,
        currentStatus: "9",
      });
      if (resp) {
        GetToast("Order Delivery Rejected, you can contact Administrator for any queries.");
        createTicket(accessToken, "Order Disputed by Dentist: ");
      }
    }
    readOrderInfo();
  };

  console.log(orderDetails?.status, orderDetails?.statusCode, "Status");

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width * 0.264583; // Convert pixels to mm (1 px = 0.264583 mm)
      const pdfHeight = canvas.height * 0.264583; // Convert pixels to mm

      const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center justify-between w-full">
          <div className="w-full flex items-center justify-between">
            <p
              className="font-medium text-[34px] text-white
"
            >
              {orderDetails?.categoryName?.[0]}
            </p>
          </div>
          {data?.statusCode === "113" && (
            <div className="w-full">
              <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
                <button
                  onClick={() => {
                    navigate("/orders");
                  }}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>

                {!isStepOneChecked && (
                  <button
                    onClick={() => {
                      setIsStepOneChecked(true);
                      setActiveTab("Payment Method");
                    }}
                    className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                  >
                    Continue
                  </button>
                )}
                {activeTab !== "Order Details" || orderDetails?.statusCode !== "113" ? null : (
                  <div className="flex gap-2 items-center">
                    <button
                      onClick={() => setRejectModalOpen(true)}
                      className="px-4 py-2 bg-primary text-white rounded-lg font-medium text-[17px]"
                    >
                      Reject
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          {orderDetails?.statusCode === "119" ? (
            <p className="px-4 py-2 text-[#F35F5D] rounded-lg font-medium text-[17px]">
              {orderDetails?.status}
            </p>
          ) : orderDetails?.statusCode === "122" ? (
            <div className="flex gap-2 items-center">
              <button
                onClick={() => handleDeliverySubmit("A")}
                className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px]"
              >
                Accepted
              </button>
              <button
                onClick={() => setDisputeModalOpen(true)}
                className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px] whitespace-nowrap"
              >
                Raise Dispute
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="bg-secondary p-4 rounded-md flex-1 flex flex-col">
        <div className="flex items-center justify-between">
          {orderDetails?.statusCode !== "113" ? null : (
            <p className="text-xl font-medium text-[#555] my-4">
              Price:{" "}
              <span className="text-primary font-bold">
                $ {orderDetails?.serviceproviderdetails?.[0].portalValue}
              </span>{" "}
            </p>
          )}
          <div onClick={downloadPDF} className="flex gap-4 items-center">
            {/* <img src={printerIcon} alt="" className="cursor-pointer" /> */}
            <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
              <img src={exitIcon} alt="" />
              Download Summary
            </button>
          </div>
          <p className="text-xl font-medium text-[#555] my-4">
            Order Id: <span className="text-primary font-bold">{orderDetails?.orderNumber}</span>{" "}
          </p>
        </div>
        {orderDetails?.statusCode !== "113" ? null : (
          <StageBox
            titleOne={"Order Details"}
            isStepOneChecked={isStepOneChecked}
            titleTwo={"Payment"}
            isStepTwoChecked={isStepTwoChecked}
            titleThree={"Confirmation"}
            isStepThreeChecked={isStepThreeChecked}
          />
        )}

        <div className="Tabs flex-1 h-full">
          {/* Tab nav */}
          <div className="flex items-center justify-between">
            {orderDetails?.statusCode !== "113" && (
              <ul className="NAV w-full flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-white/10 border-opacity-10 py-4">
                {tabNamesArray.map((title, i) => {
                  return (
                    <li
                      key={"tab-names" + (i + 1)}
                      className="relative"
                      onClick={() => handleTabSuper(title)}
                    >
                      <div
                        className={
                          activeTab === title
                            ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-primary before:left-0 before:-bottom-4"
                            : "hover:text-primary cursor-pointer text-[#999] font-normal"
                        }
                      >
                        {title}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <div className="outlet md:h-fit h-[50vh] overflow-auto pt-4 ">
            {Object.keys(outputObject).map((key, index) => {
              if (tabNamesArray.includes(key)) {
                return (
                  <>
                    {activeTab === key ? (
                      <Fragment key={"tab-" + (index + 1)}>{outputObject[key]}</Fragment>
                    ) : null}
                  </>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={disputeModalOpen}
        onRequestClose={() => setDisputeModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full z-[1000] relative">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setDisputeModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">Raise Dispute</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isDisputeRaised ? (
                  <>
                    <img src={rejectedIcon} alt="" className="w-4/12" />

                    <p className="text-center text-white my-0 md:px-12">
                      We will review your dispute and respond as soon as possible.
                    </p>
                  </>
                ) : (
                  <>
                    <img src={rejectedIcon} alt="" className="w-4/12" />
                    <p className="text-center text-white my-4 md:px-12">
                      We are sorry to see that you are not satisfied. Please rest assured that we
                      will respond to your dispute as soon as possible.
                    </p>
                    <div className="w-full mt-6">
                      <TextAreaInputField
                        labelColor={"#777"}
                        labelText={"Raise a dispute"}
                        errorMessage={"Description is required"}
                        placeholder={"Description"}
                        isFieldRequired={true}
                        htmlFor={"rejectionNote"}
                        name={"rejectionNote"}
                        value={rejectionNote}
                        handleInputChange={(e) => setRejectionNote(e.target.value)}
                        rows={6}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isDisputeRaised ? null : (
                  <button
                    onClick={() => setDisputeModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    if (isDisputeRaised) {
                      setDisputeModalOpen(false);
                    } else {
                      setIsDisputeRaised(true);
                      handleDeliverySubmit("R");
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isDisputeRaised ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        isOpen={acceptModalOpen}
        onRequestClose={() => setAcceptModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setAcceptModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">Accept Order</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isOrderAccepted ? (
                  <>
                    <img src={assignSuccess} alt="" />
                    <p className="text-2xl font-semibold">Congratulations!</p>
                    <p className="text-center my-0 md:px-12">
                      Your order is successfully accepted.
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={assignedIcon} alt="" />
                    <p className="text-center my-4 md:px-12">
                      By clicking confirm this order will be accepted for
                      <span className="text-xl text-[#4E81F4]"> 29$</span>
                    </p>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isOrderAccepted ? null : (
                  <button
                    onClick={() => setAcceptModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    setIsOrderAccepted(true);
                    if (isOrderAccepted) {
                      setAcceptModalOpen(false);
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isOrderAccepted ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        isOpen={rejectModalOpen}
        onRequestClose={() => setRejectModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setRejectModalOpen(false)}>
                  <AiOutlineClose className="ml-auto text-white" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">Reject Order</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isOrderRejected ? (
                  <>
                    <img src={rejectedIcon} alt="" className="w-4/12" />
                    <p className="text-center text-white my-0 md:px-12">
                      We will review your dispute and respond as soon as possible.
                    </p>
                  </>
                ) : (
                  <>
                    <img src={rejectedIcon} alt="" className="w-4/12" />
                    <p className="text-center text-white my-4 md:px-12">
                      Are you sure you want to reject this order!
                    </p>
                    <div className="w-full mt-6">
                      <TextAreaInputField
                        labelColor={"#777"}
                        labelText={"Add Rejection Note"}
                        errorMessage={"Note is required"}
                        placeholder={"Type here"}
                        isFieldRequired={true}
                        htmlFor={"rejectionNote"}
                        name={"rejectionNote"}
                        value={rejectionNote}
                        handleInputChange={(e) => setRejectionNote(e.target.value)}
                        rows={6}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isOrderRejected ? null : (
                  <button
                    onClick={() => setRejectModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    if (!isOrderRejected) {
                      handleRejectOrder();
                      setIsOrderRejected(true);
                    } else {
                      setRejectModalOpen(false);
                    }
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isOrderRejected ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>

      {/* <OrderCommunication
        isOpen={isCommunicalModelOpen}
        onClose={setIsCommunicalModelOpen}
        orderObjId={orderDetails?._id}
        orderDetails={orderDetails}
      /> */}
    </div>
  );
};

const OrderDetailsDentist = ({ userRole, userInfo }) => {
  // debugger
  const location = useLocation();
  console.log("OrderDetailsDentist", location.state);
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderTab data={location.state} userRole={userRole} userInfo={userInfo} />
    </div>
  );
};
export default OrderDetailsDentist;
