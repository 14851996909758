export const ORDER_STAGE = {
  PATIENT_DETAILS: "Patient Details",
  CASE_DETAILS: "Case Details",
  UPLOAD_FILES: "Upload Files",
  DISCLAIMER: "Disclaimer",
};

export const TYPE_OF_CROWN = {
  FULL_COUNTOUR_DESIGN: "Full Contour Design",
  CUT_BACK_DESIGN: "Cut Back Design",
  COPING_DESIGN_ONLY: "Coping Design Only",
};

export const TYPE_OF_VENEER = {
  FULL_COUNTOUR_DESIGN: "Full Contour Design",
  CUT_BACK_DESIGN: "Cut Back Design",
};

export const INTERPROXIMAL_CONTACTS = {
  BROAD: "Broad",
  NORMAL: "Normal",
  POINT: "Point",
};

export const OCCLUSAL_CONTACTS = {
  NONE: "None",
  LIGHT: "Light",
  MEDIUM: "Medium",
  HEAVY: "Heavy",
};

export const PONTIC_DESIGN = {
  SANITARY: "Sanitary/Hygenic",
  SADDLE_RIDGE_LAP: "Saddle-ridge-lap",
  CONICAL: "Conical",
  MODIFIED_RIDGE_LAP: "Modified-ridge-lap",
  OVATE: "Ovate",
};

export const OPTIONS_VALUE = {
  MILLING: "Milling",
  PRINTING: "Printing",
  CASTING: "Casting",
};

export const IMPLANT_RESTORATION_DESIGN = {
  ONE_PIECE_RESTORATION: "One piece restoration",
  SINGLE_RESTORATION: "Single restoration",
  SPLIT_RESTORATION: "Split restoration",
};

export const OCCLUSAL_CLEARANCE_ACTION = {
  REDUCE_LATER: "Will reduce antagonist later, no advise needed",
  REDUCE_MODAL: "Reduce model and provide printable reduction guide STL file",
};

export const THERMOFORMING_TYPE = {
  PRINTABLE_RETAINERS: "Printable retainer",
  MODEL: "Model",
};

export const THERMOFORMING_TYPE2 = {
  PRINTABLE_ALIGNERS: "Printable aligners",
  MODEL: "Model",
};

export const ORTHODONTIC_OPTIONS = {
  OTP: "Orthodontic Treatment Planning (OTP)",
  OTP_STL: "OTP + STL files for 3D printing",
};

export const CLINICAL_CONDITIONS = {
  CROWDING: "Crowding",
  SPACING: "Spacing",
  CLASS2_DIVISION1: "Class 2 Division1",
  CLASS2_DIVISION2: "Class 2 Division 2",
  UNEVEN_SMILE: "Uneven Smile",
  OPEN_BITE: "Open Bite",
  DEEP_BITE: "Deep Bite",
  ANTERIOR_CROSS_BITE: "Anterior Cross Bite",
  POSTERIOR_CROSS_BITE: "Posterior Cross Bite",
  NARROW_ARCH: "Narrow Arch",
  INCREASED_OVERJECT: "Increased Overjet",
  MISSHAPPENED_TEETH: "Misshapened Teeth",
  FLARED_TEETH: "Flared Teeth",
  OTHERS: "Others",
};

export const ARCHES_TO_TREAT = {
  UPPER: "Upper",
  LOWER: "Lower",
  BOTH: "Both",
  NONE: "None",
};

export const MODAL_OCCLUSAL_GOALS = {
  CLASS1_MOLAR_OCCLUSION: "Class I Molar Occulsion",
  MAINTAIN_MOLAR_RELATIONSHIP: "Maintain Molar Relationship",
  CANINE_CORRECTION: "Canine to Canine correction (Social 6 only)",
};

export const MIDLINE = {
  OPTIMIZE: "Optimize",
  MAINTAIN: "Maintain",
  MOVE_UPPER_ONLY: "Move Upper Only",
  MOVE_LOWER_ONLY: "Move Lower Only",
};

export const IPR = {
  ANTERIORS: "Anteriors",
  ANTERIORS_AND_PREMOLARS: "Anteriors and Premolars",
  ALL_TEETH: "All Teeth",
};

export const CORRECTION_TECHNIQUE = {
  OPTIMIZE: "Optimize",
  MAINTAIN: "Maintain",
  IMPROVE: "Improve",
};

export const SPLINT_DESIGN = {
  MICHIGAN_STYLE: "Michigan style",
  NTI: "NTI",
  TSS_STYLE: "Tss Style",
  DAWSON_B_SPLINT: "Dawson B splint",
};

export const DEPROGRAMMER_DESIGN = {
  KOIS_STYLE: "Kois style deprogrammer",
  ANTERIOR_STYLE: "Anterior full arch deprogrammer",
};

export const MAJOR_CONNECTOR_TYPE = {
  PALATAL_BAR: "Palatal bar",
  PALATAL_STRAP: "Palatal strap",
  ANTEROPOSTERIOR_BAR: "Anteroposterior palatal bar",
  ANTEROPOSTERIOR_STRAP: "Anteroposterior palatal strap",
  FULL_PALATAL_COVERAGE: "Full palatal coverage",
  LINGUAL_BAR: "Lingual bar",
  LINGUAL_PLATE: "Lingual plate",
  LINGUAL_PLATE_WITH_CE: "Lingual plate with cingulum extension",
};

export const TEETH_SIZE = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
  EXTRA_LARGE: "Extra Large",
};

export const CROSSBYTE_OPTIONS = {
  ANTERIOR: "Anterior",
  POSTERIOR_RIGHT: "Posterior right",
  POSTERIOR_LEFT: "Posterior left",
  BOTH_SIDES: "Both sides",
  NONE: "None",
};

export const TEETH_SETUP_STYLE = {
  STANDARD: "Standard",
  MASCULINE: "Masculine",
  FEMININE: "Feminine",
};

export const TEETH_MOULD_SHAPE = {
  UNIVERSAL: "Universal",
  SQUARE: "Square",
  OVAL: "Oval",
};

export const OCCLUSION_SETUP = {
  CLASS1: "Class I",
  CLASS2: "Class II",
  CLASS3: "Class III",
};

export const SERVICES_REQUIRED = {
  REGENERATION_MESH: "Regeneration mesh design only",
  TITANIUM_MESH: "Printed titanium regeneration mesh",
  IMPLANT_TREATMENT: "Implant treatment planning only",
  SURGICAL_GUIDE: "Surgical guide fabrication",
  ABUTMENT_PLANNING: "Abutment planning",
};

export const GUIDE_TYPE = {
  TOOTH_SUPPORTED: "Tooth Supported Guide",
  BONE_SUPPORTED: "Bone supported regeneration surgical mesh",
  MUCOSA_SUPPORTED: "Mucosa Supported Guide",
  STACKABLE_GUIDE1: "Stackable guides Option 1",
  STACKABLE_GUIDE2: "Stackable guides Option 2",
};

export const GUIDANCE_LEVEL = {
  PILOR_DRILL: "Pilot drill guide",
  FULLY_GUIDED: "Fully guided",
};

export const ARTICULATION_ATTACHMENTS = {
  HINGE1: "1 Hinge type connector",
  ARMS2: "2 Arms on the sides",
  ARMS3: "3 Arms with one in the midline",
  ARMS4: "4 Arms on the sides",
};

export const MODEL_FILE = {
  HOLLOW: "Hollow",
  SOLID: "Solid",
};

export const DRAIN_HOLES = ["4", "6", "8", "No Preference"];

export const SERVICE_TEETH_MAPPING = {
  restorationSites: 1,
  implantSites: 2,
  teethNotToMove: 3,
  teethNeedToExtract: 4,
  splintExtendToTeeth: 5,
  deprogrammerTeeth: 6,
  recontouringGuidesTeeth: 7,
  partialDentureSetupTeeth: 8,
  dentureSetupTeeth: 9,
  noClaspDesignTeeth: 10,
  waxupSimulationTeeth: 11,
  cutForDiesTeeth: 12,
  graftingSiteTeeth: 13,
  endodonticAccessTeeth: 14,
  densahBursTeeth: 15,
};

export const SERVICE_TEETH_MAPPING_REVERSE = {
  1: "restorationSites",
  2: "implantSites",
  3: "teethNotToMove",
  4: "teethNeedToExtract",
  5: "splintExtendToTeeth",
  6: "deprogrammerTeeth",
  7: "recontouringGuidesTeeth",
  8: "partialDentureSetupTeeth",
  9: "dentureSetupTeeth",
  10: "noClaspDesignTeeth",
  11: "waxupSimulationTeeth",
  12: "cutForDiesTeeth",
  13: "graftingSiteTeeth",
  14: "endodonticAccessTeeth",
  15: "densahBursTeeth",
};
