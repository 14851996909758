import React, { useState, useEffect } from "react";
import bell from "../../Assets/images/bellIcon.svg";
import hut from "../../Assets/images/hut.svg";
import { IoIosArrowForward, IoIosClose } from "react-icons/io";

import Modal from "react-modal";

import {
  useDeleteServicesMutation,
  useDeleteSoftwareCategoryMutation,
  useDeleteSoftwareMutation,
  useGetServicesMutation,
  useReadSoftwareCategoryListMutation,
  useReadSoftwareCategoryMutation,
} from "../../services/services";
import { useAuth0 } from "@auth0/auth0-react";
import greenTick from "../../Assets/images/greenTick.svg";
import softwareCategoryIcon from "../../Assets/images/software.png";
import addIcon from "../../Assets/icons/addCircle.svg";
import { LoginButton } from "../../Screens/Login/Login";
import { GetToast } from "../Toast-Comps/GetToast";
import Notification from "../Notification/Notification";
import { useSelector } from "react-redux";
import DynamicTable from "../Services-Comps/DynamicTable";
import InputFieldsServicesTab from "../Services-Comps/InputFieldsServicesTab";
import InputFieldSoftwareTab from "./InputFieldSoftwareTab";
import {
  useGetSoftwaresCategoriesMutation,
  useGetSoftwaresCategoryListMutation,
} from "../../services/softwareCategory";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";

const headersForSoftwareTab = [
  { headerId: "softwareName", headerName: "Software Name", headerSort: true },
  {
    headerId: "softwareCategoryName",
    headerName: "Software Category Name",
    headerSort: true,
  },
  { headerId: "sortOrder", headerName: "Sort Order", headerSort: true },
  // {
  //   headerId: "lastUpdatedDate",
  //   headerName: "Created Date",
  //   headerSort: true,
  //   rowAlignment: "left",
  //   headerAlignment: "left",
  // },

  { headerId: "actions", headerName: "Actions", headerSort: false },
];

export const SoftwareTab = () => {
  // modal state and styles
  const [readSoftwareCategory] = useReadSoftwareCategoryMutation();
  const [readSoftwareCategoryList] = useReadSoftwareCategoryListMutation();
  const [deleteSoftware] = useDeleteSoftwareMutation();
  const [deleteSoftwareCategory] = useDeleteSoftwareCategoryMutation();
  const { user, isAuthenticated } = useAuth0();
  const [isSoftwareUpdated, setIsSoftwareUpdated] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditSoftwareCategoryModal, setIsEditSoftwareCategoryModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const [editingServicesRow, setEditingServicesRow] = useState(null);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const [softwareData, setSoftwareData] = useState([]);
  const [categoryOptions, setCategoryOption] = useState([]);
  const [categoryModalIsOpen, setCategoryModalIsOpen] = useState(false);
  const [selectedSoftwareCategory, setSelectedSoftwareCategory] = useState({});
  const [getSoftwaresCategories, { data: softwareCategoriesData }] =
    useGetSoftwaresCategoriesMutation();
  const [getSoftwaresCategoryList, { data: softwareCategoriesListData }] =
    useGetSoftwaresCategoryListMutation();
  const [activePage, setActivePage] = useState({
    title: "InitialLoading",
    id: "",
    active: 0,
    softwareDescription: "",
    softwareSpecilization: "",
    price: "",
    offerPrice: "",
    tagsArray: [],
  });

  const handleDeleteSoftware = async (_id, softwareObjId) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await deleteSoftware({
      accessToken: accessToken,
      _id: _id,
      softwareObjId: softwareObjId,
    });

    if (resp?.data?.type === 1) {
      GetToast("Software Successfully Delete!");
      setSoftwareData(softwareData.filter((item) => item?.softwareObjId !== softwareObjId));
    }
  };

  const readSoftwareCategoriesInfoDeatails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  const readSoftwareCategoryInfo = async (accessToken) => {
    const responce = await getSoftwaresCategories(accessToken).unwrap();
    // const responce = await readSoftwareCategory({ accessToken }).unwrap();
    // if (responce?.type === 1) {
    //   setSoftwareData(responce.data);
    // }
    if (responce?.data && selectedSoftwareCategory?._id) {
      const softwaresList = responce?.data.find((d) => d?._id === selectedSoftwareCategory?._id);
      // if(softwaresList) setSoftwareData(softwaresList?.softwares)
      if (softwaresList)
        setSoftwareData(
          softwaresList?.softwares?.map((v) => ({
            ...v,
            softwareCategoryName: selectedSoftwareCategory?.softwareCategoryName,
          }))
        );
      else setSoftwareData([]);
      // setSoftwareData(selectedSoftwareCategory?.softwares);
      responce?.data?.forEach((d) => {
        if (d?._id === selectedSoftwareCategory?._id) {
          setSelectedSoftwareCategory(d);
        }
      });
    }

    // const listRes = await readSoftwareCategoryList({ accessToken }).unwrap();
    // if (listRes?.type === 1) {
    //   setCategoryOption(
    //     listRes?.data?.map((item) => {
    //       return {
    //         label: item.softwareCategoryName,
    //         value: item._id,
    //       };
    //     })
    //   );
    // }
  };

  const openEditCategoryModal = (row) => {
    setEditingServicesRow(row);
    setIsEditSoftwareCategoryModal(true);
    setModalIsOpen(true);
  };

  const handleUpdate = () => {
    setIsSoftwareUpdated(true);
    setModalIsOpen(false);
    setCategoryModalIsOpen(false);
  };

  const onDeleteSoftwaresCategory = async (id) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await deleteSoftwareCategory({
      accessToken,
      _id: id,
    }).unwrap();
    await readSoftwareCategoryInfo(accessToken);
    setSelectedSoftwareCategory({});
    GetToast(resp["message"]);
  };

  const onEditSoftwaresCategory = async (card) => {
    setEditingServicesRow(card);
    setIsEditSoftwareCategoryModal(true);
    setCategoryModalIsOpen(true);
  };

  useEffect(() => {
    if (isSoftwareUpdated) {
      readSoftwareCategoriesInfoDeatails();
      setIsSoftwareUpdated(false);
    }
  }, [isSoftwareUpdated]);

  const openAddCategoryModal = () => {
    setEditingServicesRow(null);
    setIsEditSoftwareCategoryModal(false);
    setCategoryModalIsOpen(true);
  };
  const closeCategoryModal = () => {
    setCategoryModalIsOpen(false);
    // setIsSoftwareUpdated(false);
  };

  const openSoftwareModal = () => {
    setEditingServicesRow(null);
    setIsEditSoftwareCategoryModal(false);
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setEditingServicesRow(null);
    setIsSoftwareUpdated(false);
    setIsEditSoftwareCategoryModal(false);
  };

  console.log("softwareCategoriesData", softwareCategoriesData);
  console.log("selectedSoftwareCategory", selectedSoftwareCategory);
  console.log("activePage", activePage);
  console.log("softwareData", softwareData);

  const prepareSoftwareElements = () => {
    if (softwareCategoriesData?.data?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on softwaresData
      softwareCategoriesData?.data.map((eachSoftware) =>
        arrayOfCards.push(
          <QuickOrderCard
            id={eachSoftware?._id}
            active={false}
            image={eachSoftware?.serCategroyIcon ?? softwareCategoryIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachSoftware?.softwareCategoryName}
            serviceDescription={eachSoftware?.softwareDescription}
            price={eachSoftware.actualPrice ? eachSoftware.actualPrice : ""}
            offerPrice={eachSoftware.offerPrice ? eachSoftware.offerPrice : ""}
            clickHandler={() => setSelectedSoftwareCategory(eachSoftware)}
            handleDeleteIconClick={() => onDeleteSoftwaresCategory(eachSoftware?._id)}
            handleEditIconClick={() => onEditSoftwaresCategory(eachSoftware)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if softwaresData is empty or null
  };

  console.log(softwareCategoriesData, "st data");

  const handleCardOnClick = async (card) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    // let userservicefound = softwareCategoriesData?.data.find((d) => d?._id === card.props.id);
    // let found = softwareCategoriesData?.data.find(
    //   (d) => d?._id === userservicefound?.serviceObjId?._id
    // );
    setActivePage({
      title: card.props.title,
      softwareDescription: card.props.softwareDescription,
      softwareSpecilization: card.props.softwareSpecilization,
      price: card.props.price,
      offerPrice: card.props.offerPrice,
      tagsArray: card.props.tagsArray,
      id: card.props.id,
      active: card.props.active,
      // serviceId: userservicefound?.serviceObjId?._id,
    });
    const listResponse = await getSoftwaresCategoryList({
      accessToken,
      _id: card.props.id,
    }).unwrap();
    if (listResponse?.type === 1 && listResponse.data[0]?.softwares) {
      const listOfSoftwares = listResponse.data[0].softwares.map((v) => ({
        ...v,
        softwareCategoryName: card.props.title,
      }));
      setSoftwareData(listOfSoftwares);
    } else {
      setSoftwareData([]);
    }
  };

  return (
    <>
      <div className="NAVBAR flex justify-between items-center mb-4">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">
            Settings <IoIosArrowForward />{" "}
            <span className="font-medium text-[15px] text-primary"> Software</span>
          </span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user.name}
            </div>
          ) : (
            <LoginButton />
          )}

          <Notification />
        </div>
      </div>
      <div className="HEADING flex md:justify-between items-center gap-4 mb-4 mt-2">
        <p className=" font-medium text-[34px] leading-7 md:mr-8 text-white">
          What software do you use ?
        </p>
        {/* <button
          onClick={() => {
            setModalIsOpen(true);
            setEditingServicesRow(null);
            setIsEditSoftwareCategoryModal(false);
          }}
          className="bg-[#FF7E00] px-2 py-4 text-white rounded-lg text-[16px] leading-4 font-medium"
        >
          + {"   "} Add Software
        </button> */}
      </div>
      <div className="bg-secondary py-4 px-4 rounded-md h-[80vh] overflow-y-auto">
        <div className="flex w-full justify-between items-center mb-2">
          <p className="text-xl font-semibold text-white">Categories</p>
          <button
            className="bg-[#FF7E00] px-4 py-2 font-sm text-white rounded-md flex items-center gap-2"
            onClick={openAddCategoryModal}
          >
            <img className="" src={addIcon} alt="" />
            Add Category
          </button>
        </div>
        {softwareCategoriesData?.data?.length === 0 ? (
          <div
            role="status"
            className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
          >
            <div className="w-full flex gap-5">
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            </div>
          </div>
        ) : (
          <div className="CAROUSEL mb-8">
            <div className="md:block hidden">
              <CustomCarousel
                rows={1}
                cols={8}
                gapY={20}
                gapX={20}
                // numberOfSliders={[
                //   // these are three slides and three arrays of cards for each slide
                //   { 0: prepareSoftwareElements() },
                // ]}
                // CHANGE DATA HERE
                completeSliders={prepareSoftwareElements()}
                numberOfCardsShownInSingleSlide={8}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
            <div className="md:hidden block">
              <CustomCarousel
                rows={1}
                cols={2}
                gapY={20}
                gapX={20}
                completeSliders={prepareSoftwareElements()}
                numberOfCardsShownInSingleSlide={8}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
          </div>
        )}
        {activePage.title !== "InitialLoading" && selectedSoftwareCategory?._id && (
          <>
            <div className="bg-white bg-opacity-10 w-full h-[1px] mb-3"></div>
            <div>
              <div className="flex w-full justify-end items-center">
                <button
                  className="bg-[#FF7E00] px-4 py-2 font-sm text-white rounded-md flex items-center gap-2"
                  onClick={openSoftwareModal}
                >
                  <img className="" src={addIcon} alt="" />
                  Add Software
                </button>
              </div>
              <DynamicTable
                key={selectedSoftwareCategory?._id}
                headers={headersForSoftwareTab}
                data={softwareData}
                includeImgVideoButton={true}
                softwareTable={true}
                onClickEditRow={openEditCategoryModal}
                onClickDeleteRow={(row) => {
                  handleDeleteSoftware(activePage?.id, row?.softwareObjId);
                }}
              />
            </div>
          </>
        )}
      </div>
      {/* <div className="bg-secondary p-4 rounded-md overflow-auto md:h-[80vh] h-[70vh]">
        {softwareData ? (
          <DynamicTable
            headers={headersForSoftwareTab}
            data={softwareData}
            includeImgVideoButton={true}
            softwareTable={true}
            onClickEditRow={openEditCategoryModal}
            onClickDeleteRow={(row) => {
              handleDeleteSoftware(row?._id, row?.softwares?.softwareObjId);
            }}
          />
        ) : (
          "loading..."
        )}
      </div> */}

      {/* to add category  */}
      <Modal
        isOpen={categoryModalIsOpen}
        onRequestClose={closeCategoryModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeCategoryModal}>
              <IoIosClose className="ml-auto text-white text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditSoftwareCategoryModal ? "Edit Software Category" : "Add Software Category"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldSoftwareTab
                editingServicesRow={editingServicesRow}
                isEditModal={isEditSoftwareCategoryModal}
                setModalIsOpen={setCategoryModalIsOpen}
                options={categoryOptions}
                setIsSoftwareUpdated={setIsSoftwareUpdated}
                handleUpdateCallback={handleUpdate}
                onlyCategory={true}
                getData={readSoftwareCategoriesInfoDeatails}
              />
            </div>
          </div>
        </>
      </Modal>

      {/* to add/edit software  */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full" onClick={closeModal}>
              <IoIosClose className="ml-auto text-white text-[32px]" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-8">
              {isEditSoftwareCategoryModal ? "Edit Software" : "Add Software"}
            </p>
            <div className="MODAL-TAB-SECTION h-3/5 md:flex-1">
              <InputFieldSoftwareTab
                editingServicesRow={editingServicesRow}
                isEditModal={isEditSoftwareCategoryModal}
                setModalIsOpen={setModalIsOpen}
                options={categoryOptions}
                setIsSoftwareUpdated={setIsSoftwareUpdated}
                handleUpdateCallback={handleUpdate}
                softwareCategoryID={activePage?.id}
                softwareCategoryName={activePage?.title}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
