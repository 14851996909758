import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { ChangePasswordTab } from "../../Components/Profile-Comps/ChangePasswordTab";
import { ProfileInfoTab } from "../../Components/Profile-Comps/ProfileInfoTab";
import { ServicesTab } from "../../Components/Services-Comps/ServicesTab";
import Navbar from "../../Components/Navbar-Comps/Navbar";

import { useReadPersonalDetailsMutation } from "../../services/user";
import { setUserProfileDetails } from "../../reducers/PersonalDetailsReducer";

// navbar icon imports
import "./profile.css";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";

function Profile({ emailFromUserProfile, profileId, setProfileId, userRole, userInfo }) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();

  const readProfileInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await readPersonalDetails({ accessToken }).unwrap((res) => res);
    dispatch(setUserProfileDetails(response?.data?.[0]));
  };

  useEffect(() => {
    readProfileInfo();
  }, []);

  const middleTabTitleArray = [
    "Profile Info",
    // "Change Password"
  ];

  const middleTabContentObject = {
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
        userInfo={userInfo}
        userRole={userRole}
      />
    ),
    "Change Password": <ChangePasswordTab userInfo={userInfo} userRole={userRole} />,
  };
  const middleServiceProviderTabTitleArray = [
    "Profile Info",
    // "Change Password",
    //"Services",
    // "Testominals & Ratings",
  ];
  const middleServiceProviderTabContentObject = {
    "Profile Info": (
      <ProfileInfoTab
        emailFromUserProfile={emailFromUserProfile}
        profileId={profileId}
        setProfileId={setProfileId}
        userInfo={userInfo}
        userRole={userRole}
      />
    ),
    "Change Password": <ChangePasswordTab userInfo={userInfo} userRole={userRole} />,
    "Service Categories": <ServicesTab userInfo={userInfo} userRole={userRole} />,
    // "Testominals & Ratings": <TestominalsAndRatingsTab />,
  };
  return (
    <div className="flex min-h-screen max-h-fit">
      <Navbar
        navActiveCss={navActiveCss}
        navHoverCss={navHoverCss}
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        middleSection={true}
        middleNavHeading={"Profile"}
        middleNavPosition={0}
        middleTabTitleArray={
          userRole === "34fs3" ? middleServiceProviderTabTitleArray : middleTabTitleArray
        }
        middleTabContentObject={
          userRole === "34fs3" ? middleServiceProviderTabContentObject : middleTabContentObject
        }
        userRole={userRole}
        active="Profile"
      />
    </div>
  );
}

export default Profile;
