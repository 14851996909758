import * as React from "react";
export const TeethFilter = () => {
  return (
    <svg
      width={0}
      height={0}
      fill="none"
      viewBox="0 0 0 0"
      style={{ visibility: "hidden", width: "0px", height: "0px" }}
    >
      <defs>
        <linearGradient
          id="a"
          x1={17.018}
          x2={15.185}
          y1={17.904}
          y2={48.458}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F8B" />
          <stop offset={1} stopColor="#5A7CEF" stopOpacity={0.05} />
        </linearGradient>
        <linearGradient
          id="b"
          x1={17.096}
          x2={15.793}
          y1={20.784}
          y2={48.385}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F8B" />
          <stop offset={1} stopColor="#5A7CEF" stopOpacity={0.05} />
        </linearGradient>
        <linearGradient
          id="c"
          x1={15.366}
          x2={11.755}
          y1={37.436}
          y2={113.284}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8F8B" />
          <stop offset={1} stopColor="#5A7CEF" stopOpacity={0.05} />
        </linearGradient>
      </defs>
    </svg>
  );
};
