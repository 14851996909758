import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        saveCategory: builder.mutation({
            query: ({ accessToken, categoryName, categoryCode, categoryIcon, categoryDescription }) => {
                var formData = {
                    "categoryName": categoryName,
                    "categoryCode": categoryCode,
                    "categoryIcon": categoryIcon,
                    "categoryDescription": categoryDescription,
                }

                return {
                    url: 'categories/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        updateCategory: builder.mutation({
            query: ({ accessToken, _id, categoryName, categoryCode, categoryDescription, categoryIcon }) => {

                // const formData = new FormData();

                // formData.append('_id', _id);
                // formData.append('categoryName', categoryName);
                // formData.append('categoryCode', categoryCode);
                // formData.append('assets', categoryIcon);
                // formData.append('categoryDescription', categoryDescription);
                var formData = {
                    "_id": _id,
                    "categoryName": categoryName,
                    "categoryCode": categoryCode,
                    "categoryDescription": categoryDescription,
                }
                if(categoryIcon){
                    formData["categoryIcon"] = categoryIcon;
                }
                return {
                    url: 'categories/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        getCategories: builder.mutation({
            query: ({ accessToken, id }) => {
                if (id) {
                    var formData = {
                        "_id": id
                    }
                }
                return {
                    url: 'categories/read',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        getAllCategories: builder.mutation({
            query: (accessToken) => {
                return {
                    url: 'categories/read',
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        deleteCategory: builder.mutation({
            query: ({ accessToken, _id }) => {
                // const formData = new FormData();
                // formData.append('_id', _id);
                var formData = {
                    "_id": _id
                }
                return {
                    url: 'categories/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
    }),
});

export const {
    useGetCategoriesMutation,
    useSaveCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation,
    useGetAllCategoriesMutation
} = categoryApi;