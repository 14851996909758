import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userProfileDetails: [],
    userProfilePicture : null,
    notification: [],
};

export const personalDetailsSlice = createSlice({
    name: 'personalDetails',
    initialState,
    reducers: {
        setUserProfileDetails: (state, action) => {
            state.userProfileDetails = action.payload;
        },
        setUserProfilePicture: (state, action) => {
            state.userProfilePicture = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        },
        addNotification: (state, action) => {
            state.notification = [...state.notification, action.payload];
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setUserProfileDetails,
    setUserProfilePicture,
    setNotification,
    addNotification
} = personalDetailsSlice.actions
