import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const serviceApi = createApi({
  reducerPath: "serviceApi",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
  endpoints: (builder) => ({
    saveServices: builder.mutation({
      query: ({
        accessToken,
        serviceName,
        serviceCode,
        serviceDescription,
        priceRangeFrom,
        priceRangeTo,
        serviceIcon,
        _id,
        sortOrder,
      }) => {
        var formData = {
          _id: _id,
          serviceName: serviceName,
          serviceCode: serviceCode,
          serviceDescription: serviceDescription,
          priceRangeFrom: priceRangeFrom,
          priceRangeTo: priceRangeTo,
          serviceIcon: serviceIcon,
          sortOrder: sortOrder,
        };

        return {
          url: "services/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateServices: builder.mutation({
      query: ({
        accessToken,
        _id,
        serviceObjId,
        serviceName,
        serviceCode,
        serviceDescription,
        priceRangeFrom,
        priceRangeTo,
        serviceIcon,
        sortOrder,
      }) => {
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
          serviceName: serviceName,
          serviceCode: serviceCode,
          serviceDescription: serviceDescription,
          priceRangeFrom: priceRangeFrom,
          priceRangeTo: priceRangeTo,
          sortOrder: sortOrder,
        };
        if (serviceIcon) {
          formData.serviceIcon = serviceIcon;
        }
        // const formData = new FormData();

        // formData.append('_id', _id);
        // formData.append('serviceName', serviceName);
        // formData.append('serviceCode', serviceCode);
        // formData.append('serviceDescription', serviceDescription);
        // formData.append('priceRangeFrom', priceRangeFrom);
        // formData.append('priceRangeTo', priceRangeTo);
        // if (parentObjId) {
        //     formData.append('parentObjId', parentObjId);
        // }

        return {
          url: "services/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getServices: builder.mutation({
      query: (accessToken) => ({
        url: "services/read",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    getServicesForCC: builder.mutation({
      query: (accessToken) => ({
        url: "services/readForCC",
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    }),
    deleteServices: builder.mutation({
      query: ({ accessToken, _id, serviceObjId }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          serviceObjId: serviceObjId,
        };
        return {
          url: "services/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveSoftwareCategory: builder.mutation({
      query: ({ accessToken, softwareCategoryName, sortOrder }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          softwareCategoryName: softwareCategoryName,
          sortOrder: sortOrder,
        };
        return {
          url: "/registration/softwarecategory/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateSoftwareCategory: builder.mutation({
      query: ({ accessToken, _id, softwareCategoryName, sortOrder }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          softwareCategoryName: softwareCategoryName,
          sortOrder: sortOrder,
        };
        return {
          url: "/registration/softwarecategory/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readSoftwareCategory: builder.mutation({
      query: ({ accessToken, _id }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
        };
        return {
          url: "/registration/softwarecategory/read",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readSoftwareCategoryList: builder.mutation({
      query: ({ accessToken, _id }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
        };
        return {
          url: "/registration/softwarecategory/list",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteSoftwareCategory: builder.mutation({
      query: ({ accessToken, _id }) => {
        var formData = {
          _id: _id,
        };
        return {
          url: "/registration/softwarecategory/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveSoftware: builder.mutation({
      query: ({
        accessToken,
        _id,
        softwareName,
        softwareImageUrl,
        softwareDescription,
        sortOrder,
      }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          softwareName: softwareName,
          softwareImageUrl: softwareImageUrl,
          softwareDescription: softwareDescription,
          sortOrder: sortOrder,
        };
        return {
          url: "/registration/software/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateSoftware: builder.mutation({
      query: ({
        accessToken,
        _id,
        softwareName,
        softwareImageUrl,
        softwareDescription,
        softwareObjId,
        sortOrder,
      }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          softwareName: softwareName,
          softwareImageUrl: softwareImageUrl,
          softwareDescription: softwareDescription,
          softwareObjId: softwareObjId,
          sortOrder: sortOrder,
        };
        return {
          url: "/registration/software/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteSoftware: builder.mutation({
      query: ({ accessToken, _id, softwareObjId }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          softwareObjId: softwareObjId,
        };
        return {
          url: "/registration/software/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveQuestion: builder.mutation({
      query: ({ accessToken, question, sortOrder }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          question: question,
          sortOrder: sortOrder,
        };
        return {
          url: "/registration/questions/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateQuestion: builder.mutation({
      query: ({ accessToken, _id, question, sortOrder }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          question: question,
          sortOrder: sortOrder,
        };
        return {
          url: "/registration/questions/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteQuestion: builder.mutation({
      query: ({ accessToken, _id }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
        };
        return {
          url: "/registration/questions/delete",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    saveAnswer: builder.mutation({
      query: ({ accessToken, _id, option }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          option: option,
        };
        return {
          url: "/registration/answer/save",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    updateAnswer: builder.mutation({
      query: ({ accessToken, _id, answerObjId, option }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
          answerObjId: answerObjId,
          option: option,
        };
        return {
          url: "/registration/answer/update",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    readQuestion: builder.mutation({
      query: ({ accessToken, _id }) => {
        // const formData = new FormData();
        // formData.append('_id', _id);
        var formData = {
          _id: _id,
        };
        return {
          url: "/registration/questions/read",
          method: "POST",
          body: formData,
          formData: true,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetServicesMutation,
  useGetServicesForCCMutation,
  useSaveServicesMutation,
  useUpdateServicesMutation,
  useDeleteServicesMutation,
  useSaveSoftwareCategoryMutation,
  useReadSoftwareCategoryMutation,
  useSaveSoftwareMutation,
  useUpdateSoftwareMutation,
  useUpdateSoftwareCategoryMutation,
  useReadQuestionMutation,
  useSaveQuestionMutation,
  useUpdateQuestionMutation,
  useSaveAnswerMutation,
  useUpdateAnswerMutation,
  useDeleteQuestionMutation,
  useDeleteSoftwareMutation,
  useDeleteSoftwareCategoryMutation,
  useReadSoftwareCategoryListMutation,
} = serviceApi;
