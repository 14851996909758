import React, { useState } from "react";

function ServiceCard({
  image,
  title,
  desc,
  specializationList,
  price,
  offerPrice,
  portalPrice,
  cardClick,
  className,
  bodyClassName,
}) {
  const [portalPriceValue, setPortalPriceValue] = useState(portalPrice);
  const handlePortalPriceChange = (e) => {
    setPortalPriceValue(e.target.value);
  };
  return (
    <div className={`${className ?? ""}`}>
      <div
        className={`flex flex-col gap-4 bg-background rounded-lg shadow border border-backgroundV2 p-4 cursor-pointer ${
          bodyClassName ?? ""
        }`}
      >
        <div className="flex items-center gap-4">
          <div className="w-14 aspect-square bg-primary bg-opacity-10 object-contain rounded-md overflow-hidden">
            <img className="h-full w-full object-contain" src={image} alt="" />
          </div>
          <p className="text-[#bbb] text-xl font-bold">{title}</p>
          <button
            onClick={() => cardClick(portalPriceValue)}
            className="text-primary font-medium underline cursor-pointer"
          >
            Update Price
          </button>
        </div>
        <div>
          <p className="text-[#999] text-[17px] font-semibold">Service Description</p>
          <p className="text-[15px] text-[#777]">{desc}</p>
        </div>
        <div>
          <p className="text-[#999] text-[17px] font-semibold">Service Specialization</p>
          <ul className="text-[15px] text-[#777] list-decimal pl-4">
            {specializationList.map((item) => (
              <li className="">{item}</li>
            ))}
          </ul>
        </div>
        <div className="bg-[#9FA1AD] bg-opacity-10 flex items-center justify-between py-4 px-8 rounded-md">
          <div className="">
            <p className="font-medium text-[17px] text-[#999]">Price</p>
            <div className=" font-extralight text-[26px] text-[#999] relative">
              ${price}
              <div className="h-[2px] w-full bg-black absolute top-1/2 left-0"></div>
            </div>
          </div>
          <div className="">
            <p className="font-medium text-[17px] text-[#999]">Offer Price</p>
            <div className=" font-semibold text-[26px] text-[#999] relative">${offerPrice}</div>
          </div>
          <div className="w-[1px] h-[40px] border-l border-dotted border-[#000] border-opacity-50 "></div>
          <div className="">
            <p className="font-medium text-[17px] text-[#999]">Portal price</p>
            <div className="flex items-center justify-center gap-2">
              <p className="font-semibold text-[26px] text-primary">$</p>
              <input
                placeholder="---"
                value={portalPriceValue}
                onChange={handlePortalPriceChange}
                className=" font-semibold text-[26px] text-primary placeholder:text-primary relative px-1 w-[80px] text-left bg-[#dddee3] bg-opacity-10 border-none outline-none"
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
