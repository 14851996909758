const patientDetailsData = {
  patientInitials: "",
  detailsAnonymized: false,
  dob: "",
  gender: "",
  address: "",
  country: "",
  pinCode: "",
  location: {},
};

const uploadFilesData = {
  photographs: {
    imageOne: { fileType: "", s3Url: null, _id: "" },
    imageTwo: { fileType: "", s3Url: null, _id: "" },
    imageThree: { fileType: "", s3Url: null, _id: "" },
    imageFour: { fileType: "", s3Url: null, _id: "" },
    imageFive: { fileType: "", s3Url: null, _id: "" },
    imageSix: { fileType: "", s3Url: null, _id: "" },
    imageSeven: { fileType: "", s3Url: null, _id: "" },
    imageEight: { fileType: "", s3Url: null, _id: "" },
    imageNine: { fileType: "", s3Url: null, _id: "" },
    imageTen: { fileType: "", s3Url: null, _id: "" },
  },
  radiographs: {
    orthopantomogram: { fileType: "", s3Url: null, _id: "" },
    periapicalXrays: { fileType: "", s3Url: null, _id: "" },
    cephalogram: { fileType: "", s3Url: null, _id: "" },
  },
  intraOralScans: {
    upperScan: { fileType: "", s3Url: null, _id: "" },
    lowerScan: { fileType: "", s3Url: null, _id: "" },
    biteScan1: { fileType: "", s3Url: null, _id: "" },
    biteScan2: { fileType: "", s3Url: null, _id: "" },
  },
  rawDicomData: {
    fileOne: { fileType: "", s3Url: null, _id: "" },
    fileTwo: { fileType: "", s3Url: null, _id: "" },
    fileThree: { fileType: "", s3Url: null, _id: "" },
  },
  additionalFiles: {}
};

const disclaimersData = {
  isTncChecked: false,
  tncDocument: null,
};

export const crownAndBridgeInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: null,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    services: [
      //   {
      //     serviceObjId: "65bde9a4a03607278060f6c9",
      //     teeth: [17, 45, 14],
      //     crownType: ["Full Contour Design", "Cut Back Design", "Coping Design Only"],
      //     veneerType: ["Full Contour Design", "Cut Back Design"],
      //     cementGap: 0,
      //     interproximalContacts: "Normal",
      //     occlusalContacts: "Foil Relief 1",
      //     ponticDesign: "Conical",
      //     needDesignPreview: false,
      //     processType: {
      //       process: "Milling",
      //       preferredRestorativeSpace: 0,
      //     },
      //     designFileNeeded: true,
      //     preferredSoftware: "3Shape",
      //     constructionFileNeeded: true,
      //     printableModelRequired: {
      //       modelRequired: true,
      //       includeDies: true,
      //     },
      //     restorationLacksOC: "Will reduce antagonist later, no advise needed",
      //     additionalInstructions: "Test",
      //   },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const dentalImplantInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: null,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    services: [
      // {
      //   "serviceObjId": "65bde9a4a03607278060f6c9",
      //   "teeth": [17, 45, 14],
      //   "crownType": ["Full Contour Design", "Cut Back Design", "Coping Design Only"],
      //   "restorationDesign": "Single restoration",
      //   "interproximalContacts": "Normal",
      //   "occlusalContacts": "Foil Relief 1",
      //   "ponticDesign": "Conical",
      //   "processType": {
      //     "process": "Milling",
      //     "preferredRestorativeSpace": 0
      //   },
      //   "cementGap": 0,
      //   "needDesignPreview": false,
      //   "designFileNeeded": true,
      //   "preferredSoftware": "3Shape",
      //   "constructionFileNeeded": true,
      //   "printableModelRequired": {
      //     "modelRequired": true,
      //     "includeDies": true
      //   },
      //   "restorationLacksOC": "Will reduce antagonist later, no advise needed",
      //   "additionalInstructions": ""
      // }
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const orthodonticInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: false,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    services: [
      // {
      //   serviceObjId: "",
      //   options: "",
      //   clinicalConditions: [],
      //   printableAligners: "",
      //   archesToTreat: "",
      //   midline: [],
      //   ipr: "",
      //   teethNotToMove: {
      //     needToMove: null,
      //     teeth: [],
      //   },
      //   overjetCorrection: {
      //     option: "",
      //     improveLength: 0,
      //   },
      //   overbiteCorrection: {
      //     option: "",
      //     improveLength: 0,
      //   },
      //   crossbiteCorrection: "",
      //   attachmentsAllowed: null,
      //   attachmentsAllowedOn: "",
      //   retainerRequired: null,
      //   preferredSoftware: "",
      //   molarOcclusalGoals: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const removablesInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: false,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    services: [
      // {
      //   "serviceObjId": "",
      //   "impressionTrayFor": "",
      //   "teethNeedToExtract": [],
      //   "implantSite": [],
      //   "needHandle": null,
      //   "holesInImpressionTray": null,
      //   "impressionMaterialHoles": 0,
      //   "preferredSoftware": "",
      //   "intraoralScansFor": "",
      //   "digitalInversionFor": "",
      //   "biteRegistrationDesired": null,
      //   "dentureBaseProcessing": "",
      //   "teethProcessing": "",
      //   "monoblocPrintDesired": null,
      //   "dentureSetupTeeth": [],
      //   "partialDentureSetupTeeth": [],
      //   "noClaspDesignTeeth": [],
      //   "splintExtendToTeeth": [],
      //   "recontouringGuidesTeeth": [],
      //   "deprogrammerTeeth": [],
      //   "dentureThickness": 0,
      //   "deprogrammerThickness": 0,
      //   "finalLevelDistance": 0,
      //   "copyDentureDesired": null,
      //   "majorConnector": [],
      //   "splintProcessing": "",
      //   "recontouringGuideProcessing": "",
      //   "softTissueReductionGuideFor": "",
      //   "hardTissueReductionGuideFor": "",
      //   "preferredDeprogrammerDesign": "",
      //   "archDeprorammerOn": "",
      //   "occlusionSetup": "",
      //   "toothMouldShape": "",
      //   "archNightguardOn": "",
      //   "archSplintOn": "",
      //   "preferredSplintDesign": "",
      //   "teethSetupStyle": "",
      //   "teethSize": "",
      //   "crossbiteCreated": "",
      //   "dentureForPhotoScan": null
      // }
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const smileInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: false,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    services: [
      // {
      //   "serviceObjId": "",
      //   "waxupSimulationTeeth": [],
      //   "deprogrammerTeeth": [],
      //   "needDesignPreview": null,
      //   "designFileNeeded": null,
      //   "preferredSoftware": "",
      //   "needPrintableModelFile": null,
      //   "needTwoPrintableModelFile": null,
      //   "needPrintableEggshellFiles": null,
      //   "additionalInstructions": "Test",
      //   "archNightguardOn": "",
      //   "nightguardThickness": 0,
      //   "deprogrammerThickness": 0,
      //   "softTissueReductionGuideFor": "",
      //   "hardTissueReductionGuideFor": "",
      //   "recontouringGuidesTeeth": [],
      //   "finalLevelDistance": 0,
      //   "recontouringGuideProcessing": "",
      //   "deprogrammerProcessing": "",
      //   "preferredDeprogrammerDesign": "",
      //   "archDeprorammerOn": ""
      // }
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const modelInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: false,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    services: [
      // {
      //   serviceObjId: "",
      //   baseThickness: 0,
      //   cutForDiesTeeth: [],
      //   needArticulationAttachments: null,
      //   articulationAttachmentsNeeded: "",
      //   modelFile: "",
      //   needEngravedText: null,
      //   needDrainHoles: null,
      //   drainHolesNeeded: "",
      //   preferredSoftware: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};

export const surgicalInitialOrderDetails = {
  expectedDelivery: "",
  categoryObjId: "",
  isPriority: false,
  scanMedium: "",
  patientDetails: patientDetailsData,
  caseDetails: {
    numberSystem: "",
    hasMissingTeeth: null,
    missingTeethSelectedTeeth: [],
    anyTeethMobile: null,
    anyTeethMobileSelectedTeeth: [],
    teethToExtract: null,
    teethToExtractSelectedTeeth: [],
    services: [
      // {
      //   serviceObjId: "",
      //   implantSiteTeeth: [],
      //   teethToExtract: {
      //     teethToExtract: null,
      //     teeth: [],
      //   },
      //   anyTeethMobile: {
      //     anyTeethMobile: null,
      //     teeth: [],
      //   },
      //   guideType: "",
      //   surgeryDate: "",
      //   preferredImplantCompany: "",
      //   implantLine: "",
      //   preferredPinCompany: "",
      //   pinLine: "",
      //   implantSurgicalKit: "",
      //   densahBursUsed: null,
      //   densahBurGuideDesired: null,
      //   densahBursTeeth: [],
      //   flapBeRaised: null,
      //   additionalGuideOptions: [
      //     { option: "", position: "" },
      //     { option: "", position: "" },
      //   ],
      //   guidanceLevel: "",
      //   servicesRequired: [],
      //   boneReductionInPlan: null,
      //   conversionDentureAvailable: null,
      //   digitalConversionDenture: null,
      //   abutmentPreferences: "",
      //   endodonticAccessTeeth: [],
      //   graftingSiteTeeth: [],
      //   guidedAccessDrillBrand: "",
      //   drillShankDiameter: "",
      //   additionalInstructions: "",
      // },
    ],
  },
  uploadFiles: uploadFilesData,
  disclaimers: disclaimersData,
};
