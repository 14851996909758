export const FDItoUTNMapping = {
  18: 1,
  17: 2,
  16: 3,
  15: 4,
  14: 5,
  13: 6,
  12: 7,
  11: 8,
  21: 9,
  22: 10,
  23: 11,
  24: 12,
  25: 13,
  26: 14,
  27: 15,
  28: 16,
  48: 32,
  47: 31,
  46: 30,
  45: 29,
  44: 28,
  43: 27,
  42: 26,
  41: 25,
  31: 24,
  32: 23,
  33: 22,
  34: 21,
  35: 20,
  36: 19,
  37: 18,
  38: 17,
};

export const UTNtoFDIMapping = {
  1: 18,
  2: 17,
  3: 16,
  4: 15,
  5: 14,
  6: 13,
  7: 12,
  8: 11,
  9: 21,
  10: 22,
  11: 23,
  12: 24,
  13: 25,
  14: 26,
  15: 27,
  16: 28,
  32: 48,
  31: 47,
  30: 46,
  29: 45,
  28: 44,
  27: 43,
  26: 42,
  25: 41,
  24: 31,
  23: 32,
  22: 33,
  21: 34,
  20: 35,
  19: 36,
  18: 37,
  17: 38,
};

export const updateTeethMapping = (values, notation) => {
  if (notation === "FDI") {
    const newValues = values?.map((d) => UTNtoFDIMapping[d]);
    return newValues;
  } else if (notation === "UTN") {
    const newValues = values?.map((d) => FDItoUTNMapping[d]);
    return newValues;
  }
  return values;
};

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    // behavior: "smooth",
  });
};

export const implantCompanyList = [
  "Biohorizons",
  "Biomet 3i",
  "Blue Sky Bio",
  "Bredent",
  "Camlog",
  "Dentium",
  "Dentsply Sirona",
  "Dio",
  "Hi Ossen",
  "Implant Direct",
  "Keystone",
  "MIS",
  "Megagen",
  "Neodent",
  "Neoss",
  "Nobel Biocare",
  "Osstem",
  "Ritter",
  "Straumann",
  "TRI Matrix",
  "TruAbutment",
  "ZimVie",
  "Other",
];

export const stackableGuideOptionsList1 = [
  "Multi unit abutment indexing or positioning guide",
  "Tissue spacer for temporary prosthesis pick up",
  "Provisional denture for conversion or backup",
  "Printable reduced jaw model",
  "Printable pre reduction jaw model",
  "Printable reduced jaw model with multi unit digital analog recesses",
  "Printable scalloped bone reduction guide for FP1 cases",
  "Printed metal base bone reduction guide",
  "Printed metal implant drill guide",
  "Printed metal scalloped bone reduction guide for FP1 cases",
];

export const stackableGuideOptionsList2 = [
  "Multi unit abutment indexing or positioning guide",
  "Tissue spacer for temporary prosthesis pick up",
  "Provisional denture for conversion or backup",
];

export const findExtraElement = (array1, array2) => {
  const sum1 = array1.reduce((acc, val) => acc + val, 0);
  const sum2 = array2.reduce((acc, val) => acc + val, 0);
  return sum2 - sum1;
};

export const MAP_LIBRARIES = ["places"];

export const checkObjects = (firstObj, secondObj) => {
  if (
    typeof firstObj !== "object" ||
    typeof secondObj !== "object" ||
    Object.keys(firstObj)?.length !== Object.keys(secondObj)?.length
  ) {
    return false;
  }

  let result = true;

  Object.keys(firstObj)?.forEach((key) => {
    if (firstObj?.[key] !== secondObj?.[key]) result = false;
  });

  return result;
};

export const isImageFormatDisplayable = (name) => {
  const DISPLAYABLE_FORMATS = ["jpeg", "jpg", "png", "gif", "webp", "avif"];
  const fileNameArray = name?.split(".");
  const fileFormat = fileNameArray[fileNameArray?.length - 1];
  if (DISPLAYABLE_FORMATS.includes(fileFormat?.toLowerCase())) {
    return true;
  }
  return false;
};

export const getServicePriceRange = (categories) => {
  const priceRange = {
    priceRangeFrom: 1000,
    priceRangeTo: 0,
  };

  categories.forEach((d) => {
    if (priceRange.priceRangeFrom > d?.priceRangeFrom) {
      priceRange.priceRangeFrom = d?.priceRangeFrom;
    }
    if (priceRange.priceRangeTo < d?.priceRangeTo) {
      priceRange.priceRangeTo = d?.priceRangeTo;
    }
  });

  return priceRange;
};
