import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const userServiceApi = createApi({
    reducerPath: 'userServiceApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        saveUserService: builder.mutation({
            query: ({ accessToken, serviceObjId, offerPrice, actualPrice, active }) => {
                // debugger
                var formData = {
                    "serviceObjId": serviceObjId,
                    "offerPrice": offerPrice,
                    "actualPrice": actualPrice,
                    "active": active
                }

                return {
                    url: 'userService/save',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        updateUserService: builder.mutation({
            query: ({ accessToken, _id, serviceObjId, offerPrice, actualPrice, portalPrice, active }) => {

                var formData = {
                    "_id": _id,
                    "serviceObjId": serviceObjId,
                    "offerPrice": offerPrice,
                    "actualPrice": actualPrice,
                    "portalPrice": portalPrice,
                    "active": active
                }

                return {
                    url: 'userService/update',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    },
                }
            },
        }),
        getUserService: builder.mutation({
            query: ({ accessToken, id }) => {
                // debugger
                if (id) {
                    var formData = {
                        "_id": id
                    }
                }
                return {
                    url: 'userService/read',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
        deleteUserService: builder.mutation({
            query: ({ accessToken, _id }) => {
                var formData = {
                    "_id": _id
                }
                return {
                    url: 'userService/delete',
                    method: 'POST',
                    body: formData,
                    formData: true,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            },
        }),
    }),
});

export const {
    useDeleteUserServiceMutation,
    useSaveUserServiceMutation,
    useUpdateUserServiceMutation,
    useGetUserServiceMutation,
} = userServiceApi;