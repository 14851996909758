import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import Modal from "react-modal";

import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../../Components/Dashboard-Comps/QuickOrderCard";
import Notification from "../../Components/Notification/Notification";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner";
import StageBox from "../../Components/Order-Comps/StageBox";
import EstimatedServicePrice from "./EstimatedServicePrice";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import { LoginButton } from "../Login/Login";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";

import { useGetOrdersForDentistMutation, useSaveOrderMutation } from "../../services/orders";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";

import { AiOutlineClose } from "react-icons/ai";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import { getServicePriceRange } from "../../Utils/utility";

function AddNewOrder({ userRole, userInfo }) {
  const location = useLocation();

  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <AddNewOrderTab userInfo={userInfo} userRole={userRole} data={location.state} />
    </div>
  );
}

export default AddNewOrder;

export const AddNewOrderTab = ({ userInfo, userRole, data }) => {
  const { user, isAuthenticated } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const [getServicesCategories, { data: servicesData }] = useGetServicesCategoriesMutation();
  const [selectedServiceCategory, setSelectedServiceCategory] = useState({});
  const [isPriority, setIsPriority] = useState(data?.isPriority === 1 || false);
  const [expectedDelivery, setExpectedDelivery] = useState("");
  const checkPriority = data?.isPriority === 1 || false;

  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const [activePage, setActivePage] = useState({
    id: "",
    title: "InitialLoading",
    description: "",
    priceTo: "",
    priceFrom: "",
    gallery: {},
  });

  console.log("selectedServiceCategory", selectedServiceCategory);

  const getServicesData = async (accessToken) => {
    const resp = await getServicesCategories(accessToken).unwrap();
    if (data?.serviceId && resp?.data?.length > 0) {
      let servicedetails = resp?.data?.find((d) => d._id === data?.serviceId);
      if (servicedetails?.orderUrl) {
        const priceRange = getServicePriceRange(servicedetails?.catservices);
        setActivePage({
          id: servicedetails?._id,
          title: servicedetails?.serviceName,
          description: servicedetails?.serviceDescription,
          priceTo: priceRange?.priceRangeTo,
          priceFrom: priceRange?.priceRangeFrom,
          gallery: {
            images: servicedetails?.images,
            videos: servicedetails?.videos,
          },
          orderUrl: servicedetails?.orderUrl,
        });
      } else {
        GetToast(
          `Order form for ${servicedetails?.serviceName} is offline and will be live soon…`,
          "error"
        );
      }
    }
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesData(accessToken);
  };

  useEffect(() => {
    readServicesInfo();
  }, []);
  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here

  useEffect(() => {
    if (data?.serviceObjId && servicesData?.length > 0) {
      servicesData?.data?.forEach((eachCategory) => {
        if (eachCategory?.catservices?.length > 0) {
          eachCategory?.catservices?.forEach((eachService) => {
            if (eachService.serviceObjId === data.serviceObjId) {
              setSelectedServiceCategory(eachCategory);
              const priceRange = getServicePriceRange(eachCategory?.catservices);
              setActivePage({
                id: eachService.serviceObjId,
                title: eachService.serviceName,
                description: eachService.serviceDescription,
                priceTo: priceRange?.priceRangeTo,
                priceFrom: priceRange?.priceRangeFrom,
                gallery: eachService.gallery,
                orderUrl: eachCategory.orderUrl,
              });
              return;
            }
          });
        }
      });
    }
  }, [servicesData]);

  const prepareServicesCategoryElements = () => {
    if (servicesData?.data?.length > 0) {
      if (data === null || data?.serviceId) {
        const arrayOfCards = [];
        // debugger;

        // Create HTML elements based on servicesData
        servicesData.data
          .filter((d) => d?.catservices && d?.catservices?.length > 0)
          .map((eachService) => {
            const priceRange = getServicePriceRange(eachService?.catservices);
            return arrayOfCards.push(
              <QuickOrderCard
                id={eachService?._id}
                image={eachService?.serCategroyIcon}
                bgColor={"#171818"}
                textColor={"gray"}
                title={eachService?.serCategoryName}
                orderUrl={eachService?.orderUrl ?? ""}
                description={eachService.serCategoryDescription}
                priceFrom={priceRange?.priceRangeFrom ?? ""}
                priceTo={priceRange?.priceRangeTo ?? ""}
                clickHandler={(e) => {
                  setSelectedServiceCategory(eachService);
                  if (eachService?.orderUrl) {
                    setActivePage({
                      id: eachService?._id,
                      title: eachService?.serCategoryName,
                      description: eachService.serCategoryDescription,
                      priceTo: priceRange?.priceRangeTo ?? "",
                      priceFrom: priceRange?.priceRangeFrom,
                      gallery: {},
                      orderUrl: eachService?.orderUrl || "",
                    });
                  } else {
                    setActivePage({
                      id: "",
                      title: "InitialLoading",
                      description: "",
                      priceTo: "",
                      priceFrom: "",
                      gallery: {},
                    });
                    GetToast(
                      `Order form for ${eachService?.serCategoryName} is offline and will be live soon…`,
                      "error"
                    );
                  }
                }}
                isPriority={0}
                active={selectedServiceCategory?._id === eachService?._id}
              />
            );
          });
        return arrayOfCards;
      } else {
        const arrayOfCards = [];
        // debugger;

        // Create HTML elements based on servicesData
        servicesData?.data?.map((eachService) => {
          const priceRange = getServicePriceRange(eachService?.catservices);
          return arrayOfCards.push(
            <QuickOrderCard
              id={eachService?._id}
              image={eachService?.serCategroyIcon}
              bgColor={"#171818"}
              textColor={"gray"}
              title={eachService?.serCategoryName}
              orderUrl={eachService?.orderUrl ?? ""}
              description={eachService.serCategoryDescription}
              priceFrom={priceRange?.priceRangeFrom ?? ""}
              priceTo={priceRange?.priceRangeTo ?? ""}
              clickHandler={(e) => {
                setSelectedServiceCategory(eachService);
                // setActivePage({
                //   id: "",
                //   title: "InitialLoading",
                //   description: "",
                //   priceTo: "",
                //   priceFrom: "",
                //   gallery: {},
                // });
              }}
              isPriority={0}
              active={selectedServiceCategory?._id === eachService?._id}
            />
          );
        });
        return arrayOfCards;
      }
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const prepareServicesElements = () => {
    if (servicesData?.data?.length > 0) {
      const arrayOfCards = [];

      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            id={eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            orderUrl={eachService?.orderUrl ?? ""}
            description={eachService.serCategoryDescription}
            priceFrom={eachService?.priceRangeFrom ?? ""}
            priceTo={eachService?.priceRangeTo ?? ""}
            clickHandler={() => {
              if (!selectedServiceCategory?.orderUrl) {
                GetToast(
                  `Order form for ${selectedServiceCategory?.serCategoryName} is offline and will be live soon…`,
                  "error"
                );
              }
            }}
            isPriority={0}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };
  useEffect(() => {
    if (servicesData?.data && data?.serviceObjId) {
      servicesData?.data.forEach((eachCategory) => {
        eachCategory?.catservices?.forEach((eachService) => {
          if (eachService?.serviceObjId === data?.serviceObjId) {
            setSelectedServiceCategory(eachCategory);
            const priceRange = getServicePriceRange(eachCategory?.catservices);
            setActivePage({
              id: eachService?.serviceObjId,
              title: eachService?.serviceName,
              description: eachService?.serviceDescription,
              priceTo: priceRange?.priceRangeTo,
              priceFrom: priceRange?.priceRangeFrom,
              gallery: {
                images: eachService?.images,
              },
              orderUrl: eachCategory?.orderUrl,
            });
          }
        });
      });
    }
  }, [servicesData, data]);

  console.log("activePage", activePage);

  const handleCardOnClick = (card) => {
    // debugger;
    if (card.props.orderUrl) {
      setActivePage({
        id: card.props.id,
        title: card.props.title,
        description: card.props.description,
        priceTo: card.props.priceTo,
        priceFrom: card.props.priceFrom,
        gallery: card.props.gallery,
        orderUrl: card.props.orderUrl,
      });
    } else {
      setActivePage({
        id: "",
        title: "InitialLoading",
        description: "",
        priceTo: "",
        priceFrom: "",
        gallery: {},
      });
      GetToast(`Order form for ${card.props.title} is offline and will be live soon…`, "error");
    }
  };
  return (
    <div className="MAIN-DASH bg-black p-4 w-full h-screen flex flex-col md:py-4 pt-4 pb-20 overflow-y-auto">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-backgroundV2 font-medium text-[15px] text-white border border-[#dfdfdf]">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-white">Orders</p>
        </div>
      </div>
      <div className="CAROUSEL mb-8">
        <div className="md:block hidden">
          <CustomCarousel
            rows={1}
            cols={8}
            gapY={40}
            gapX={40}
            completeSliders={prepareServicesCategoryElements()}
            numberOfCardsShownInSingleSlide={8}
            // handleCardOnClick={handleCardOnClick}
          />
        </div>
        <div className="md:hidden block">
          <CustomCarousel
            rows={1}
            cols={2}
            gapY={20}
            gapX={20}
            completeSliders={prepareServicesCategoryElements()}
            numberOfCardsShownInSingleSlide={2}
            // handleCardOnClick={handleCardOnClick}
          />
        </div>
      </div>
      {selectedServiceCategory?._id && selectedServiceCategory?.catservices?.length > 0 && (
        <div className="CAROUSEL mb-8 mx-4 bg-backgroundV2 px-4 py-3 rounded-xl">
          <p className="font-medium text-[24px] text-white mb-2">
            Services offered in this category (select later)
          </p>
          <div className="md:block hidden">
            <CustomCarousel
              rows={1}
              cols={9}
              gapY={40}
              gapX={40}
              cardClassName="!cursor-default"
              completeSliders={prepareServicesElements()}
              numberOfCardsShownInSingleSlide={9}
              key={selectedServiceCategory?._id}
            />
          </div>
          <div className="md:hidden block w-full">
            <CustomCarousel
              rows={1}
              cols={2}
              gapY={20}
              gapX={20}
              cardClassName="!cursor-default"
              completeSliders={prepareServicesElements()}
              numberOfCardsShownInSingleSlide={2}
              key={selectedServiceCategory?._id}
            />
          </div>
        </div>
      )}
      {activePage.title !== "InitialLoading" && (
        <ServiceInformationTab
          id={activePage.id}
          title={activePage.title}
          description={activePage.description}
          priceFrom={activePage.priceFrom}
          priceTo={activePage.priceTo}
          gallery={activePage.gallery}
          orderUrl={selectedServiceCategory.orderUrl}
          isPriority={isPriority}
          setIsPriority={setIsPriority}
          expectedDelivery={expectedDelivery}
          setExpectedDelivery={setExpectedDelivery}
          orderData={data}
          checkPriority={checkPriority}
        />
      )}
    </div>
  );
};
export const ServiceInformationTab = ({
  id,
  title,
  description,
  gallery,
  priceFrom,
  priceTo,
  orderData,
  checkPriority,
  orderUrl,
}) => {
  const navigate = useNavigate();
  const [getOrdersForDentist] = useGetOrdersForDentistMutation();
  const [saveOrder] = useSaveOrderMutation();
  const { getAccessTokenSilently } = useAuth0();
  const [orderByData, setOrderBydata] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  const handleSaveOrder = async () => {
    if (orderData === null) {
      setOrderLoading(true);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const resp = await saveOrder({
        accessToken,
        data: {
          categoryObjId: id,
        },
      });
      console.log("resp", resp);
      if (resp?.data?.type === 1) {
        const newresp = await getOrdersForDentist({
          accessToken,
          data: { _id: resp?.data?.data[0]._id },
        });
        console.log("newresp", newresp);
        if (newresp?.data?.type === 1) {
          setOrderBydata(newresp?.data?.data?.[0]);
          setOrderLoading(false);
          GetToast(
            "Your order has been successfully initiated and is in the Draft stage. Please do not use the Back button on your browser. Please only use the Back button at the bottom of each page to return to any stage of this order to edit it !!",
            "success",
            10000
          );
          navigate(`/${orderUrl}`, {
            state: {
              categoryObjId: id,
              orderByData: newresp.data?.data?.[0] ?? { _id: resp?.data?.data[0]._id },
            },
          });
        } else {
          setOrderLoading(false);
          GetToast("Priority orders will charge 20% extra on the regular price", "success");
        }
      } else {
        setOrderLoading(false);
        GetToast(
          "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
          "error"
        );
      }
    } else {
      setOrderLoading(false);
      navigate(`/${orderUrl}`, {
        state: {
          categoryObjId: id,
          orderByData: {
            ...orderByData,
          },
        },
      });
    }
  };

  useEffect(() => {
    const getOrderData = async () => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const resp = await getOrdersForDentist({
        accessToken,
        data: { _id: orderData?.orderObjId },
      });
      if (resp?.data) {
        setOrderBydata(resp.data.data[0]);
      }
    };
    if (orderData !== null) {
      getOrderData();
    }
  }, []);

  return (
    <div className="bg-black p-4 rounded-md flex-1">
      <div className="flex md:flex-row flex-col gap-4 items-center justify-between">
        <p className="font-medium text-2xl text-white">{title}</p>
      </div>
      <StageBox
        titleOne={"Service Details"}
        titleTwo={"Order Details"}
        titleThree={"Confirmation"}
      />
      <div className="grid md:grid-cols-3 grid-cols-1 grid-rows-1 gap-8 border-b pb-8 border-dotted">
        <div className=" col-span-2 flex flex-col gap-8">
          <div>
            <p className="font-medium text-[20px] text-white">Service Description</p>
            <p className="text-[17px] text-[#555]">{description}</p>
          </div>
          <EstimatedServicePrice priceFrom={priceFrom} priceTo={priceTo} />
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 pt-4">
        <button
          onClick={() => {
            navigate("/orders");
          }}
          className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            handleSaveOrder();
          }}
          className="font-medium text-[17px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl flex justify-center"
        >
          {orderLoading ? <LoaderSpinner className="my-1" /> : "Save & Continue"}
        </button>
      </div>
    </div>
  );
};

export const ServiceGallery = ({ gallery, rows, cols, gapX, gapY }) => {
  const [selectedVideo, setSelectedVideo] = useState("");
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const openVideoModal = (video) => {
    setSelectedVideo(video);
    setIsVideoModalOpen(true);
  };
  const openImageModal = (image) => {
    setSelectedImage(image);
    setIsImageModalOpen(true);
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
        gridTemplateColumns: `repeat(${cols}, minmax(0, 1fr))`,
        gridGap: `${gapY}px ${gapX}px `,
      }}
      className="grid grid-row-3 grid-cols-4 gap-4 flex-1"
    >
      {gallery["videos"].length > 0 &&
        gallery["videos"].map((item) => {
          return (
            <div className="col-span-1 row-span-1">
              <video
                className="h-[80px] w-[80px] object-cover rounded-sm cursor-pointer"
                src={item?.s3Url ?? ""}
                // controls
                height={28}
                width={28}
                onClick={() => openVideoModal(item?.s3Url ?? "")}
              >
                Your browser does not support the video tag.
              </video>
            </div>
          );
        })}
      {gallery["images"].length > 0 &&
        gallery["images"].map((item) => {
          return (
            <div className="col-span-1 row-span-1">
              <img
                className="h-[80px] w-[80px] object-cover rounded-sm cursor-pointer"
                src={item?.s3Url ?? ""}
                onClick={() => openImageModal(item?.s3Url ?? "")}
                alt="imgHere"
              />
            </div>
          );
        })}

      {isImageModalOpen && (
        <div className="image-modal">
          <Modal
            isOpen={isImageModalOpen}
            onRequestClose={() => setIsImageModalOpen(false)}
            contentLabel="Custom Modal"
            className="image-modal"
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                <button className="w-full text-right" onClick={() => setIsImageModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <img className="h-full w-full object-contain" src={selectedImage} alt="" />
              </div>
            </>
          </Modal>
        </div>
      )}
      {isVideoModalOpen && (
        <div className="video-modal">
          <Modal
            isOpen={isVideoModalOpen}
            onRequestClose={() => setIsVideoModalOpen(false)}
            contentLabel="Custom Modal"
            className="image-modal"
            ariaHideApp={false}
          >
            <>
              <div className="MODAL-BODY flex flex-col h-full">
                <button className="w-full text-right" onClick={() => setIsVideoModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <video
                  className="h-full w-full object-contain"
                  src={selectedVideo}
                  alt=""
                  controls
                  autoPlay
                />
              </div>
            </>
          </Modal>
        </div>
      )}
    </div>
  );
};
