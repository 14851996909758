import React, { useState } from "react";
import Navbar from "../../Components/Navbar-Comps/Navbar";
// inner section imports
// modal imports
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import bell from "../../Assets/images/bellIcon.svg";
import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import QuickOrderCard from "../../Components/Dashboard-Comps/QuickOrderCard";
import { Tooltip } from "react-tooltip";
import { CategoriesTab } from "../../Components/Services-Comps/CategoriesTab";
import { ServicesTab } from "../../Components/Services-Comps/ServicesTab";
import { ProfileInfoTab } from "../../Components/Profile-Comps/ProfileInfoTab";
import { ChangePasswordTab } from "../../Components/Profile-Comps/ChangePasswordTab";
import { TestominalsAndRatingsTab } from "../../Components/Profile-Comps/TestominalsAndRatingsTab";
import toothPic from "../../Assets/images/toothPic.svg";
import priceSlip from "../../Assets/images/priceSlip.svg";
import video from "../../Assets/videos/video.mp4";
import Modal from "react-modal";
import { ServiceGallery, ServiceInformationTab } from "../Orders/AddNewOrder";
import addIcon from "../../Assets/icons/addCircle.svg";
import { useNavigate } from "react-router-dom";
import StageBox from "../../Components/Order-Comps/StageBox";
import EstimatedServicePrice from "../Orders/EstimatedServicePrice";
import editIcon from "../../Assets/icons/editIcon.svg";
import infoIcon from "../../Assets/icons/infoIcon.png";
import DetailTile from "../../Components/Profile-Comps/DetailTile";
import { AiOutlineClose } from "react-icons/ai";
import Accordion from "../../Components/InputField-Comps/Accordion";
import completeIcon from "../../Assets/icons/completeIcon.svg";
import TextInputField from "../../Components/InputField-Comps/TextInputField";
import { useEffect } from "react";
import TextInputFieldV2 from "../../Components/InputField-Comps/TextInputFieldV2";
import { useGetServicesMutation, useGetServicesForCCMutation } from "../../services/services";
import {
  useSaveUserServiceMutation,
  useGetUserServiceMutation,
  useUpdateUserServiceMutation,
  useDeleteUserServiceMutation,
} from "../../services/userServices";
import { useAuth0 } from "@auth0/auth0-react";
import MyStatefulEditor from "../../Components/InputField-Comps/MyStatefulEditor";
import RichTextEditor from "react-rte";
import DetailTileHtml from "../../Components/Profile-Comps/DetailTileHtml";
import { LoginButton } from "../Login/Login";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import {
  setAllServiceDetails,
  setCurrencyRates,
  setUserServiceDetails,
} from "../../reducers/SPServiceDetailsReducer";
import { useSelector, useDispatch } from "react-redux";
import { Convert } from "easy-currencies";
import PriceInputField from "../../Components/InputField-Comps/PriceInputField";
import PriceDetailTile from "../../Components/Profile-Comps/PriceDetailTile";
import ActiveToggleButton from "../../Components/UserManagement-Comps/ActiveToggleButton";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import Notification from "../../Components/Notification/Notification";
import AccordionV2 from "../../Components/InputField-Comps/AccordionV2";
import { useGetServicesCategoriesMutation } from "../../services/servicesCategory";
import { setServiceProviderServicesOffered } from "../../reducers/registrationReducer";
import { useReadPersonalDetailsMutation } from "../../services/user";
import { setUserProfileDetails } from "../../reducers/PersonalDetailsReducer";
import axios from "axios";

// const converter = new Converter();

export const ServicesServiceProvider = ({ userInfo, userRole }) => {
  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="My Services"
      />
      <ServicesServiceProviderTab userRole={userRole} userInfo={userInfo} />
    </div>
  );
};

export const ServicesServiceProviderTab = ({ userInfo, userRole }) => {
  const dispatch = useDispatch();
  const { allService, userService, currencyRates } = useSelector((state) => state.servicesDetails);
  const { user, isAuthenticated } = useAuth0();
  const [isServicesUpdated, setIsServicesUpdated] = useState(false);
  const [servicesData, setServicesData] = useState();
  const [userServicesData, setUserServicesData] = useState(userService);
  const [allItems, setAllItems] = useState([]);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  // get query can be here
  const { getAccessTokenSilently } = useAuth0();
  const [saveUserService] = useSaveUserServiceMutation();
  const [updateUserService] = useUpdateUserServiceMutation();
  const [deleteUserService] = useDeleteUserServiceMutation();
  const [getUserService] = useGetUserServiceMutation();
  const [getServicesCategories] = useGetServicesCategoriesMutation();
  const [readPersonalDetails] = useReadPersonalDetailsMutation();

  const getServicesData = async (accessToken) => {
    const response = await getServicesCategories(accessToken);
    dispatch(setAllServiceDetails(response?.data?.data));
    setServicesData(response?.data?.data);
  };

  useEffect(() => {
    if (servicesData?.length > 0 && userServicesData?.length > 0) {
      const userServData = userServicesData?.map((d) => d?.serviceObjId?._id);
      servicesData.forEach((cat) => {
        cat?.catservices?.forEach((serv) => {
          if (userServData.includes(serv.serviceObjId)) {
            userServData.push(cat?._id);
          }
        });
      });
      setAllItems(userServData);
    }
  }, [servicesData, userServicesData]);

  const getUserServicesData = async (accessToken) => {
    const response = await getUserService({ accessToken }).unwrap();
    dispatch(setUserServiceDetails(response?.data));
    setUserServicesData(response?.data);
  };

  const getCurrencyData = async () => {
    if (Object.keys(currencyRates)?.length > 0) {
      return currencyRates;
    }

    const response = await axios.get(
      "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json"
    );
    console.log("currency response", response);
    const { usd } = response.data;
    dispatch(setCurrencyRates(usd));
    return usd;
  };

  const convertCurrency = async (amount, userCurrency, reverse = false) => {
    const usdCurrencyRates = await getCurrencyData();
    const conversionrate = usdCurrencyRates[userCurrency?.toLowerCase()];
    if (conversionrate && reverse) {
      return amount * conversionrate;
    } else if (conversionrate) {
      return amount / conversionrate;
    }
    return 0;
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesData(accessToken);
    await getUserServicesData(accessToken);
  };

  useEffect(() => {
    if (isServicesUpdated === true) {
      readServicesInfo();
    }
    setIsServicesUpdated(false);
  }, [isServicesUpdated]);

  useEffect(() => {
    if (!userServicesData?.length || !servicesData?.length) {
      readServicesInfo();
    }
    setIsServicesUpdated(false);
  }, []);

  const readProfileInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const response = await readPersonalDetails({ accessToken }).unwrap((res) => res);
    dispatch(setUserProfileDetails(response?.data?.[0]));
  };

  useEffect(() => {
    readProfileInfo();
  }, []);

  const removeQuickCardHandler = async (id) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await deleteUserService({
      accessToken,
      _id: id,
    }).unwrap();
    GetToast(resp.data["message"]);
    await getUserServicesData(accessToken);

    // removing from desktop
    setArrayOfQuickOrderCards((prevComponents) =>
      prevComponents.filter((component) => component.props.id !== id)
    );
    // removing from mobile array
    setArrayOfQuickOrderCardsMobile((prevComponents) =>
      prevComponents.filter((component) => component.props.id !== id)
    );
    // setting the page to initial state
    setActivePage({
      title: "InitialLoading",
      id: "",
      active: 0,
      serviceDescription: "",
      price: "",
      offerPrice: "",
      tagsArray: [],
    });
  };

  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  const prepareServicesElements = () => {
    if (userServicesData?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      userServicesData.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            id={eachService._id}
            active={eachService._id === activePage?.id}
            itemActive={eachService.active}
            selectedIcon={eachService?.active === 1}
            image={eachService.serviceObjId?.image}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService.serviceObjId.serviceName}
            serviceDescription={eachService.serviceObjId.serviceDescription}
            price={eachService.actualPrice ? eachService.actualPrice : ""}
            offerPrice={eachService.offerPrice ? eachService.offerPrice : ""}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  // array of components with data for carousel here (mobile)

  const arrayCardsMobile = [];

  // Prepare the HTML element once servicesData has data
  // array of components with data for carousel here
  const prepareServicesList = () => {
    const arrayOfServiceList = [];
    if (servicesData?.length) {
      // Create HTML elements based on servicesData
      servicesData.forEach((eachServiceCategory) => {
        arrayOfServiceList.push({
          id: eachServiceCategory._id,
          title: eachServiceCategory.serCategoryName,
          icon: eachServiceCategory.serCategroyIcon,
          catservices: eachServiceCategory.catservices,
        });
      });
    }
    return arrayOfServiceList;
  };
  const prepareServicesListSelected = () => {
    if (servicesData?.length) {
      const arrayOfServiceListSelected = [];
      // Create HTML elements based on servicesData
      servicesData.map((eachService) => arrayOfServiceListSelected.push({ id: eachService._id }));

      return { items: arrayOfServiceListSelected };
    }
    return [];
    // Return null if servicesData is empty or null
  };
  const [activePage, setActivePage] = useState({
    title: "InitialLoading",
    id: "",
    active: 0,
    serviceDescription: "",
    price: "",
    offerPrice: "",
    tagsArray: [],
  });

  const [arrayOfQuickOrderCards, setArrayOfQuickOrderCards] = useState([]);
  //   const htmlelements = prepareServicesElements();
  // // debugger
  // if(htmlelements.length > 0){
  //   setArrayOfQuickOrderCards(htmlelements);
  // }

  const [arrayOfQuickOrderCardsMobile, setArrayOfQuickOrderCardsMobile] =
    useState(arrayCardsMobile);

  // modal states
  const [addServiceModalIsOpen, setAddServiceModalIsOpen] = useState(false);
  const [editServiceModalIsOpen, setEditServiceModalIsOpen] = useState(false);
  const [isServiceAdded, setIsServiceAdded] = useState(false);
  // edit service modal states
  const [serviceDescription, setServiceDescription] = useState(activePage?.serviceDescription);
  const [id, setid] = useState(activePage?.id);
  const [title, setTitle] = useState(activePage?.title);
  const [subServices, setSubServices] = useState([]);
  const [price, setPrice] = useState(activePage.price);
  const [userPrice, setUserPrice] = useState("");
  const [userOfferPrice, setUserOfferPrice] = useState("");
  const [offerPrice, setOfferPrice] = useState(activePage.offerPrice);
  const [isEditServiceSubmitted, setIsEditServiceSubmitted] = useState(false);
  const [selectedSer, setselectedSer] = useState([]);
  const [unselectedSer, setunselectedSer] = useState([]);
  const [active, setActive] = useState(activePage?.active === 1 || false);
  // const [selectedItems, setSelectedItems] = useState([]);
  const [cardClicked, setCardClick] = useState(false);
  console.log("toggleactive", active);
  console.log("userPrice", userPrice);

  const handleCardOnClick = async (card) => {
    let userservicefound = userService.find((d) => d?._id === card.props.id);
    let found = allService.find((d) => {
      const findservice = d?.catservices?.find(
        (dd) => dd?.serviceObjId === userservicefound?.serviceObjId?._id
      );
      if (findservice) return d;
      return null;
    });
    console.log("toggleactive card props", card.props);
    console.log("toggleactive found", found);
    console.log("toggleactive allService", allService);
    console.log("toggleactive userService", userService);
    console.log("toggleactive userservicefound", userservicefound);
    setActivePage({
      title: card?.props?.title,
      serviceDescription: card?.props?.serviceDescription,
      price: card?.props?.price,
      offerPrice: card?.props?.offerPrice,
      tagsArray: card?.props?.tagsArray,
      id: card?.props?.id,
      active: card?.props?.active,
      itemActive: card?.props?.itemActive,
      serviceId: userservicefound?.serviceObjId?._id,
    });
    if (found?.catservices) {
      setSubServices(
        found?.catservices?.map((d) => {
          let found = userService.find((dd) => dd?.userSelectedService?._id === d?._id);
          return { ...d, selected: found ? true : false };
        })
      );
    }
    setCardClick(true);

    console.log(
      "toggleactive",
      card.props.price,
      userProfileDetails?.bankDetails?.[0]?.currency || "USD"
    );
    console.log(
      "toggleactive1111",
      (
        await convertCurrency(
          card.props.price,
          userProfileDetails?.bankDetails?.[0]?.currency || "USD"
        )
      ).toFixed(2)
    );

    setUserPrice(
      (
        await convertCurrency(
          card.props.price,
          userProfileDetails?.bankDetails?.[0]?.currency,
          true
        )
      ).toFixed(2)
    );
    setUserOfferPrice(
      (
        await convertCurrency(
          card.props.offerPrice,
          userProfileDetails?.bankDetails?.[0]?.currency,
          true
        )
      ).toFixed(2)
    );
  };
  // remove quick card func here

  const addServiceHandler = async () => {
    // saveUserService
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let promiseArray = [];
    if (selectedSer.length > 0) {
      selectedSer.forEach((element) => {
        promiseArray.push(handleEachServiceSave(accessToken, element));
      });
    } else {
      GetToast("Please select any one service to save !!!");
    }

    if (unselectedSer.length > 0) {
      unselectedSer.forEach((element) => {
        promiseArray.push(handleEachServiceDelete(accessToken, element.id));
      });
    }
    await Promise.all(promiseArray);
    setIsServicesUpdated(true);
    setIsServiceAdded(true);
  };
  const handleEachServiceSave = async (accessToken, serviceObjId) => {
    // debugger;
    return new Promise(async (resolve, reject) => {
      await saveUserService({
        accessToken,
        serviceObjId,
        active: 1,
      }).unwrap();
      resolve(true);
    });
  };

  const handleEachServiceDelete = async (accessToken, _id) => {
    // debugger;
    return new Promise(async (resolve, reject) => {
      await deleteUserService({
        accessToken,
        _id,
      }).unwrap();
      resolve(true);
    });
  };

  //   const handleSelectionChange = (selectedTitlesArray) => {
  //     const preSelectedItems = allItems
  //       .filter((item) => item.sectionChecked === true)
  //       .map((item) => {
  //         return {
  //           id: item.sectionId,
  //           title: item.title,
  //         };
  //       });

  //     const newSelectedItems = selectedTitlesArray.filter((item) => item.all === true);

  //     const arrayOfUnselectedServicesOffered = preSelectedItems.filter((preItems) => {
  //       const titleValue = preItems.title;
  //       return !newSelectedItems.some((selectedItem) => selectedItem.title === titleValue);
  //     });

  //     setunselectedSer(arrayOfUnselectedServicesOffered);

  //     const arrayOfServicesOffered = selectedTitlesArray.reduce((acc, curr) => {
  //       if (curr.all === true) {
  //         acc.push(curr.id);
  //       }
  //       if (curr.items && curr.items.length > 0) {
  //         curr.items.forEach((i) => {
  //           acc.push(i);
  //         });
  //       }

  //       return acc;
  //     }, []);
  //     // pass on the data to the backend (arrayOfServicesOffered)
  //     setselectedSer(arrayOfServicesOffered);
  //   };

  const handleSaveUserService = async ({ serviceObjId, offerPrice, actualPrice, active }) => {
    return new Promise(async (resolve, reject) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await saveUserService({
        accessToken,
        serviceObjId,
        offerPrice,
        actualPrice,
        active,
      });
      if (response) {
        resolve();
        //show response if required
      } else {
        reject();
      }
    });
  };

  const handleDeleteUserService = async ({ _id }) => {
    return new Promise(async (resolve, reject) => {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      const response = await deleteUserService({ accessToken, _id });
      if (response) {
        resolve();
        //show response if required
      } else {
        reject();
      }
    });
  };

  const handleSelectionChange = async ({ id: selectedItemId, type, serviceId }) => {
    return new Promise(async (resolve, reject) => {
      const selectedItems = [...allItems];
      if (selectedItems.includes(selectedItemId)) {
        const processList = [];
        if (type === "service") {
          servicesData.forEach((parentItem) => {
            if (parentItem.id === selectedItemId) {
              parentItem?.catservices?.forEach((childItem) => {
                if (selectedItems.includes(childItem.serviceObjId)) {
                  selectedItems.splice(selectedItems.indexOf(childItem.serviceObjId), 1);
                  processList.push(
                    handleDeleteUserService({
                      _id: childItem.serviceObjId,
                    })
                  );
                }
              });
            }
          });
        } else {
          if (selectedItems.includes(serviceId)) {
            selectedItems.splice(selectedItems.indexOf(serviceId), 1);
          }
          processList.push(
            handleDeleteUserService({
              _id: selectedItemId,
            })
          );
        }

        selectedItems.splice(selectedItems.indexOf(selectedItemId), 1);
        setAllItems(selectedItems);
        await Promise.all(processList);
      } else {
        const processList = [];
        if (type === "service") {
          servicesData.forEach((parentItem) => {
            if (parentItem.id === selectedItemId) {
              parentItem?.catservices?.forEach((childItem) => {
                if (!selectedItems.includes(childItem.serviceObjId)) {
                  selectedItems.push(childItem.serviceObjId);
                  processList.push(
                    handleSaveUserService({
                      serviceObjId: childItem.serviceObjId,
                      offerPrice: "",
                      actualPrice: "",
                      active: true,
                    })
                  );
                }
              });
            }
          });
        } else {
          if (!selectedItems.includes(serviceId)) {
            selectedItems.push(serviceId);
          }
          processList.push(
            handleSaveUserService({
              serviceObjId: selectedItemId,
              offerPrice: "",
              actualPrice: "",
              active: true,
            })
          );
        }
        if (!selectedItems.includes(selectedItemId)) selectedItems.push(selectedItemId);
        setAllItems(selectedItems);
        await Promise.all(processList);
      }
      dispatch(setServiceProviderServicesOffered(selectedItems));
      resolve();
    });
  };

  // edit service modal functions
  const editServiceHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await updateUserService({
      accessToken,
      _id: id,
      offerPrice: offerPrice,
      actualPrice: price,
      active: active === true ? 1 : 0,
    }).unwrap();
    if (resp?.type === 1) {
      setActivePage({});
      setCardClick(false);
    }
    setIsServicesUpdated(true);
    setActivePage((prev) => ({
      ...prev,
      title: title,
      id: id,
      //active: active,
      price: price,
      offerPrice: offerPrice,
    }));
    setIsEditServiceSubmitted(true);
  };

  // functions for edit service modal
  const validateServiceDescription = (value) => {
    return value?.trim().length > 0;
  };
  const validateServiceSpecilization = (value) => {
    return value?.trim().length > 0;
  };
  const validatePrice = (value) => {
    return value?.trim() > 0;
  };
  const validateOfferPrice = (value) => {
    return value?.trim() > 0;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      // "serviceDescription",
      "price",
      "offerPrice",
    ];
    return requiredFields.includes(fieldName);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "serviceDescription") setServiceDescription(value);
    if (name === "price") setUserPrice(value);
    if (name === "offerPrice") setUserOfferPrice(value);
  };

  useEffect(() => {
    setid(activePage?.id);
    setServiceDescription(activePage?.serviceDescription);
    setTitle(activePage?.title ?? "");
    setPrice(activePage?.price);
    setOfferPrice(activePage?.offerPrice);
    setActive(activePage?.itemActive === 1);
  }, [
    activePage?.id,
    activePage?.title,
    activePage?.offerPrice,
    activePage?.price,
    activePage?.serviceDescription,
    activePage?.active,
  ]);

  const priceConverter = async (userCurrency, amount) => {
    if (userCurrency && amount) {
      console.log("priceConverter", amount, userCurrency);
      const value = await convertCurrency(amount, userCurrency);
      console.log("priceConverter", value);
      setPrice(value.toFixed(2));
    }
  };

  const offerPriceConverter = async (userCurrency, amount) => {
    if (userCurrency && amount) {
      const value = await convertCurrency(amount, userCurrency);
      setOfferPrice(value.toFixed(2));
    }
  };

  const handleEditServiceModalClose = () => {
    setEditServiceModalIsOpen(false);
    setIsEditServiceSubmitted(false);
  };

  console.log("userProfileDetails", userProfileDetails);

  useEffect(() => {
    priceConverter(userProfileDetails?.bankDetails?.[0]?.currency || "USD", userPrice);
    offerPriceConverter(userProfileDetails?.bankDetails?.[0]?.currency || "USD", userOfferPrice);
  }, [userPrice, userOfferPrice]);

  return (
    <div className="MAIN-DASH bg-background p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-white flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center w-full">
          <p className="font-medium text-[34px] text-white">Services</p>
        </div>
      </div>
      <div className="bg-secondary py-4 px-4 rounded-md">
        <div className="flex w-full justify-between items-center mb-2">
          <p className="text-xl font-semibold text-white">Your Services</p>
          <button
            className="bg-[#FF7E00] px-4 py-2 font-sm text-white rounded-md flex items-center gap-2"
            onClick={() => {
              setAddServiceModalIsOpen(true);
              setIsServiceAdded(false);
            }}
          >
            <img className="" src={addIcon} alt="" />
            Add Service
          </button>
        </div>
        {userServicesData?.length === 0 ? (
          <div
            role="status"
            className="animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
          >
            <div className="w-full flex gap-5">
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
              <div className="h-24 w-28 rounded-xl bg-black dark:bg-gray-300  mb-4"></div>
            </div>
          </div>
        ) : (
          <div className="CAROUSEL mb-8">
            <div className="md:block hidden">
              <CustomCarousel
                rows={1}
                cols={8}
                gapY={20}
                gapX={20}
                // numberOfSliders={[
                //   // these are three slides and three arrays of cards for each slide
                //   { 0: prepareServicesElements() },
                // ]}
                // CHANGE DATA HERE
                completeSliders={prepareServicesElements()}
                numberOfCardsShownInSingleSlide={8}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
            <div className="md:hidden block">
              <CustomCarousel
                rows={1}
                cols={2}
                gapY={20}
                gapX={20}
                completeSliders={prepareServicesElements()}
                numberOfCardsShownInSingleSlide={8}
                handleCardOnClick={handleCardOnClick}
              />
            </div>
          </div>
        )}
        <div className="bg-white bg-opacity-10 w-full h-[1px]"></div>
        {activePage.title !== "InitialLoading" && (
          <ServiceInformationTabAdmin
            title={activePage.title}
            serviceDescription={activePage.serviceDescription}
            tagsArray={activePage.tagsArray}
            price={activePage.price}
            offerPrice={activePage.offerPrice}
            removeQuickCardHandler={removeQuickCardHandler}
            id={activePage.id}
            setEditServiceModalIsOpen={setEditServiceModalIsOpen}
            userPrice={userPrice}
            userOfferPrice={userOfferPrice}
            cardClicked={cardClicked}
          />
        )}
      </div>
      <Modal
        isOpen={addServiceModalIsOpen}
        onRequestClose={() => setAddServiceModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal bg-backgroundV2"
        // style={customStyles}
        ariaHideApp={false}
      >
        <div className="h-full bg-backgroundV2">
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={() => setAddServiceModalIsOpen(false)}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <p className="text-[26px] font-medium text-white leading-8 mb-12">Add Services</p>
            <div className="flex-1">
              {isServiceAdded ? (
                <div className="h-full flex flex-col">
                  <div className="flex-1 flex flex-col items-center justify-center">
                    <img src={completeIcon} alt="saved" />
                    <p className="text-white font-medium text-2xl my-4">Service Updated</p>
                    <p className="text-[#777777] text-center">
                      Your Service has been Updated successfully.
                    </p>
                  </div>
                  <div className="flex items-center justify-end gap-4 pt-4">
                    <button
                      onClick={() => setAddServiceModalIsOpen(false)}
                      className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                    >
                      Okay
                    </button>
                  </div>
                </div>
              ) : (
                <div className="h-full flex flex-col">
                  <div className=" flex-1 border-b border-[#000] border-opacity-20">
                    <AccordionV2
                      items={prepareServicesList()}
                      onSelectionChange={handleSelectionChange}
                      setAllItems={setAllItems}
                      allItems={allItems}
                    />
                  </div>
                  <div className="flex items-center justify-end gap-4 pt-4">
                    <button
                      onClick={() => setAddServiceModalIsOpen(false)}
                      className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => addServiceHandler()}
                      type="submit"
                      className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                    >
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={editServiceModalIsOpen}
        onRequestClose={handleEditServiceModalClose}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <div className="h-full">
          <div className="MODAL-BODY flex flex-col h-full">
            <button className="w-full text-right" onClick={handleEditServiceModalClose}>
              <AiOutlineClose className="ml-auto text-white" />
            </button>
            <div className="mb-8 mt-2 flex justify-between">
              <p className="text-[26px] font-medium text-white leading-8 ">Edit Services</p>
              {isEditServiceSubmitted ? (
                <></>
              ) : (
                <div className="p-2 flex items-center">
                  <p className="mr-4 text-base text-gray-500">Active</p>
                  <ActiveToggleButton currentState={active} setState={setActive} />
                </div>
              )}
            </div>
            <div className="flex-1 MODAL-CONTENT">
              {isEditServiceSubmitted ? (
                <div className="h-full">
                  <div className=" h-full flex flex-col items-center justify-center">
                    <img src={completeIcon} alt="saved" />
                    <p className="text-white font-medium text-2xl my-4">Service Edited</p>
                    <p className="text-[#777777] text-center">
                      Your Service has been edited successfully.
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="text-xl font-semibold text-white">{title}</p>
                  <br></br>
                  <br></br>
                  <p className="text-white">{serviceDescription}</p>
                  <br></br>
                  <br></br>
                  <PriceInputField
                    labelColor={"#777"}
                    labelText={"Price"}
                    errorMessage={"Please enter a Price"}
                    placeholder={"Enter Price"}
                    htmlFor={"price"}
                    name={"price"}
                    value={userPrice}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validatePrice}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("price")}
                    currency={userProfileDetails?.bankDetails?.[0]?.currency || "USD"}
                    userValue={price}
                    labelTooltipId={"servicePriceEdit"}
                    labelTooltipContent={"This is the price you wish to charge for this service"}
                    labelTooltipPosition="left-end"
                  />
                  <PriceInputField
                    labelColor={"#777"}
                    labelText={"Offer Price"}
                    errorMessage={"Please enter a valid offer price"}
                    placeholder={"Enter offer price"}
                    htmlFor={"offerPrice"}
                    name={"offerPrice"}
                    value={userOfferPrice}
                    handleInputChange={handleInputChange}
                    validationFunctionName={validateOfferPrice}
                    isSubmitted={isEditServiceSubmitted}
                    isFieldRequired={isFieldRequired("offerPrice")}
                    currency={userProfileDetails?.bankDetails?.[0]?.currency || "USD"}
                    userValue={offerPrice}
                    labelTooltipId={"serviceOfferPriceEdit"}
                    labelTooltipContent={
                      "This is the price you may wish to offer on occasion as a discounted price. If no offer price is entered, your regular price will apply"
                    }
                    labelTooltipPosition="left-end"
                  />
                  {/* <TextInputField
                      labelColor={"#777"}
                      labelText={"Offer Price"}
                      errorMessage={"Please enter a valid offer price"}
                      placeholder={"Enter offer price"}
                      htmlFor={"offerPrice"}
                      name={"offerPrice"}
                      value={offerPrice}
                      handleInputChange={handleInputChange}
                      validationFunctionName={validateOfferPrice}
                      isSubmitted={isEditServiceSubmitted}
                      isFieldRequired={isFieldRequired("offerPrice")}
                    />
                    <p>{userOfferPrice}</p> */}
                </div>
              )}
            </div>
            {isEditServiceSubmitted ? (
              <div className="flex items-center justify-end gap-4 pt-4">
                <button
                  onClick={() => {
                    setEditServiceModalIsOpen(false);
                    setIsEditServiceSubmitted(false);
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Close
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-end gap-4 pt-4">
                <button
                  onClick={() => setEditServiceModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={editServiceHandler}
                  type="submit"
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Update
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export const ServiceInformationTabAdmin = ({
  id,
  removeQuickCardHandler,
  title,
  serviceDescription,
  tagsArray,
  price,
  offerPrice,
  setEditServiceModalIsOpen,
  userPrice,
  userOfferPrice,
  cardClicked,
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-secondary p-4 rounded-md md:flex-1 md:h-auto h-[50vh] overflow-auto">
      <div className="flex md:flex-row flex-col w-full justify-between md:items-center md:gap-0 gap-2">
        <div className="flex gap-2 items-center">
          <p className=" font-semibold text-xl text-white">{title}</p>
          {/* <p className="text-[#2E2C34]">
              If You Are Not Providing this service{" "}
              <span
                className="text-[#F35F5D] font-semibold cursor-pointer"
                onClick={() => removeQuickCardHandler(id)}
              >
                Remove
              </span>
            </p> */}
        </div>
        <button
          onClick={() => {
            setEditServiceModalIsOpen(true);
          }}
          disabled={!cardClicked}
          className={
            cardClicked
              ? "bg-secondary px-4 py-2 border border-primary rounded-md flex gap-2 items-center text-primary text-base font-semibold w-fit"
              : "bg-secondary px-4 py-2 border border-primary opacity-50 rounded-md flex gap-2 items-center text-primary text-base font-semibold w-fit"
          }
        >
          <span>
            <img src={editIcon} alt="" />
          </span>
          Edit Service
        </button>
      </div>
      <div className="grid md:grid-cols-3 grid-rows-2 gap-4 md:my-8 my-4">
        <DetailTile
          heading={"Service Description"}
          subHeading={cardClicked ? serviceDescription : "Click on a card to view data..."}
          icon={infoIcon}
        />

        {/* <DetailTile
            heading={"Tags"}
            subHeading={" Location of dummy, address of dummy, location ma..."}
            tags={true}
            tagsArray={tagsArray}
            icon={editIcon}
          /> */}
        <PriceDetailTile
          heading={"Price"}
          subHeading={cardClicked ? price ?? "--" : "Click on a card to view data..."}
          icon={infoIcon}
          iconTooltipId={"servicePrice"}
          iconTooltipContent={"This is the price you wish to charge for this service"}
        />
        <PriceDetailTile
          heading={"Offer Price"}
          subHeading={cardClicked ? offerPrice ?? "--" : "Click on a card to view data..."}
          icon={infoIcon}
          iconTooltipId={"serviceOfferPrice"}
          iconTooltipContent={
            "This is the price you may wish to offer on occasion as a discounted price. If no offer price is entered, your regular price will apply"
          }
        />
      </div>
    </div>
  );
};
