import { OPTIONS_VALUE } from "../../constants/orderForms";

const CrownOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Restoration and/or pontic sites</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.restorationSites?.length > 0
                    ? service?.restorationSites?.join(", ")
                    : "NA"}
                </p>
              </div>
              {[
                "Single or Multiple single crowns",
                "Bridge(s)",
                "Bridge(s) with gingiva",
                "Maryland Bridge",
                "Post and core crown",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    {["Bridge(s)", "Bridge(s) with gingiva", "Maryland Bridge"].includes(
                      serviceDetails?.serviceName
                    )
                      ? "Type of Bridge Retainer and Pontic"
                      : "Type of Crown"}
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.typeOfCrown?.length > 0 ? service?.typeOfCrown.join(", ") : "NA"}
                  </p>
                </div>
              )}
              {serviceDetails?.serviceName === "Veneers" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Type of Veneer</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.typeOfVeneer?.length > 0 ? service?.typeOfVeneer.join(", ") : "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Cement Gap (µ microns)</p>
                <p className="text-[#555] font-medium mt-2">{service?.cementGap || "NA"}</p>
              </div>
              {[
                "Single or Multiple single crowns",
                "Bridge(s)",
                "Bridge(s) with gingiva",
                "Maryland Bridge",
                "Veneers",
                "Onlays",
                "Post and core crown",
              ].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Interproximal Contacts</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.interproximalContacts || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Occlusal Contacts</p>
                <p className="text-[#555] font-medium mt-2">{service?.occlusalContacts || "NA"}</p>
              </div>
              {["Bridge(s)", "Bridge(s) with gingiva", "Maryland Bridge"].includes(
                serviceDetails?.serviceName
              ) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">Pontic Design</p>
                  <p className="text-[#555] font-medium mt-2">{service?.ponticDesign || "NA"}</p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Do you need to see a design preview?</p>
                <p className="text-[#555] font-medium mt-2">{service?.needDesignPreview || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Will you be Milling or Printing?</p>
                <p className="text-[#555] font-medium mt-2">{service?.processType || "NA"}</p>
              </div>
              {service?.processType === OPTIONS_VALUE.PRINTING && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    If you are printing what restorative space do you prefer? (µ microns)
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.processTypePreferredRestorativeSpace || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Design file to be provided?</p>
                <p className="text-[#555] font-medium mt-2">{service?.designFileNeeded || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred design software?</p>
                <p className="text-[#555] font-medium mt-2">{service?.preferredSoftware || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  Restorations are normally provided as .STLS, Please advise if a construction.info
                  file is desired.
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.constructionFileNeeded || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Is a printable restorative model desired?</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.printableModelRequired || "NA"}
                </p>
              </div>
              {service?.printableModelRequired === "Yes" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Should the crown and bridge model include dies?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.printableModelincludeDies || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  If a certain restoration lacks occlusal clearance? Please advise your preferred
                  course of action
                </p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.restorationLacksOC || "NA"}
                </p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additional Instructions</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.additionalInstructions || "NA"}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default CrownOrderSummary;
