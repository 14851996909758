import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import {
  useSaveNotesMutation,
  useSaveServiceProviderOrderDetailsMutation,
  useSaveServiceProviderOrdersMutation,
  useUpdateServiceProviderOrdersMutation,
} from "../../services/orders";
import { useAuth0 } from "@auth0/auth0-react";
import { GetToast } from "../Toast-Comps/GetToast";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";

function ServiceProviderCard({
  isOrderAssigned,
  setIsOrderAssigned,
  setIsOrderConfirm,
  isOrderConfirm,
  crowdHireId,
  serviceDescription,
  specialisation,
  actualPrice,
  currency,
  offerPrice,
  portalPrice,
  user_id,
  serviceProviderOrdersData,
  orderObjId,
  setIsUpdated,
  userServiceObjId,
  firstName,
  lastName,
  isMultiService = false,
  userServiceDetails = null,
  sercategoryObjId,
  notes,
  setNotes,
  orderDetails,
  handleUpdateStatus,
  status,
}) {
  const { getAccessTokenSilently } = useAuth0();

  const [saveNotes] = useSaveNotesMutation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [saveServiceProviderOrders] = useSaveServiceProviderOrdersMutation();
  const [saveServiceProviderOrderDetails] = useSaveServiceProviderOrderDetailsMutation();
  const [updateServiceProviderOrders] = useUpdateServiceProviderOrdersMutation();

  console.log(offerPrice, currency, "detadfnjefnejf");
  console.log(serviceProviderOrdersData, "serviceProviderOrdersData");
  console.log(userServiceDetails, "userServiceDetails");
  console.log(userServiceDetails, "orderObjId");
  console.log(userServiceDetails, "user_id");
  console.log(firstName, "firstName");

  const updatedActualPrice = isMultiService
    ? userServiceDetails?.reduce((total, item) => {
        total = total + parseFloat(item?.actualPrice || 0);
        return total;
      }, 0)
    : actualPrice;

  const updatedOfferPrice = isMultiService
    ? userServiceDetails?.reduce((total, item) => {
        total = total + parseFloat(item?.offerPrice || 0);
        return total;
      }, 0)
    : offerPrice;

  const updatedPortalPrice = isMultiService
    ? userServiceDetails?.reduce((total, item) => {
        total = total + parseFloat(item?.portalPrice || 0);
        return total;
      }, 0)
    : portalPrice;

  console.log("userServiceDetails", userServiceDetails);
  const assignOrderHandler = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    serviceProviderOrdersData?.map(async (d) => {
      if (d?.status === "1") {
        await updateServiceProviderOrders({
          accessToken,
          status: "0",
          _id: d?._id,
          spOrderObjId: d?.spOrderObjId,
          orderObjId: orderObjId,
        });
      }
    });
    // if (serviceProviderOrdersData?.length === 0) {
    const resp = await saveServiceProviderOrders({
      accessToken,
      spuserAuthId: user_id,
      orderObjId: orderObjId,
      orderValue: updatedOfferPrice,
      portalValue: updatedPortalPrice,
      orderCurrency: currency,
      sercategoryObjId: sercategoryObjId,
    }).unwrap();
    console.log("save response", resp);
    GetToast(resp.data?.message);
    console.log("final userServiceDetails", userServiceDetails);
    userServiceDetails?.map((d) => {
      saveServiceProviderOrderDetails({
        accessToken,
        userServiceObjId: d?._id,
        orderValue: d?.offerPrice,
        portalValue: d?.portalPrice,
        orderCurrency: currency,
        orderObjId: orderObjId,
        spOrderObjId: resp?.data?.[0]?.spOrderObjId,
      });
    });
    setIsOrderAssigned(true);
    setIsUpdated(true);
    // } else {
    //   const resp = await updateServiceProviderOrders({
    //     accessToken,
    //     orderObjId,
    //     portalValue: updatedPortalPrice,
    //     orderValue: updatedOfferPrice,
    //     orderCurrency: currency,
    //   });
    //   GetToast(resp.data["message"]);
    //   setIsOrderAssigned(true);
    //   setIsUpdated(true);
    // }

    if (orderDetails?.statusCode === "126") {
      // const deliveryObjId =
      //   orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
      //     ?.orderdelivery?.[0]?.deliveryObjId;

      // if (deliveryObjId) {
      //   await saveNotes({
      //     accessToken,
      //     data: {
      //       notes: notes,
      //       deliveryObjId: deliveryObjId,
      //       orderObjId: orderDetails?._id,
      //     },
      //   });
      //   setNotes("");
      // }

      handleUpdateStatus("C", notes);
    }
  };
  return (
    <>
      <div className="bg-backgroundV2 p-4 rounded-lg">
        <div className="flex flex-col justify-between items-start mb-4">
          <div className="flex-col items-start justify-start mb-2">
            <p className="text-[#777] text-[12px]">Service Provider Name</p>
            <p className="text-white text-left font-medium">
              {firstName} {lastName}
            </p>
          </div>
          <div className="flex-col items-start justify-start">
            <p className="text-[#777] text-[12px]">Service Provider ID</p>
            <p className="text-white text-left font-medium">{crowdHireId}</p>
          </div>
          {/* <div>stars</div> */}
        </div>
        <div className="flex flex-col gap-4">
          {serviceDescription && (
            <div>
              <p className="text-[#777] text-[12px] text-left mb-2">Service Description</p>
              <p className="text-white text-[17px] text-left">{serviceDescription}</p>
            </div>
          )}
          {specialisation ? (
            <div>
              <p className="text-[#777] text-[12px] text-left mb-2">Service specialisation</p>
              <div
                className="text-white text-[17px] text-left"
                dangerouslySetInnerHTML={{ __html: specialisation }}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-wrap w-full gap-2">
            <div className="bg-[#E2D7ED] text-[12px] py-1 px-3 rounded-full">
              Total Orders Delivered: <span className="font-bold">0</span>
            </div>
            <div className="bg-[#E2D7ED] text-[12px] py-1 px-3 rounded-full">
              Last Delivery: <span className="font-bold">0 Day ago</span>
            </div>
          </div>
          {isMultiService && (
            <div className="border-y border-[#777] py-4 my-5">
              <p className="text-[#777] text-[12px] text-start mb-2">Services</p>
              <ul className="flex flex-col gap-2">
                {userServiceDetails?.map((item) => (
                  <li className="bg-[#E2D7ED] flex items-center gap-2 text-[12px] py-1 px-3 rounded-full leading-none w-fit">
                    <img
                      src={item?.serviceIcon}
                      alt="icon"
                      className="!w-5 aspect-square object-cover object-top"
                    />{" "}
                    {item?.serviceName}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="flex flex-row justify-between flex-wrap gap-4">
            <div className="flex flex-col gap-2 text-left">
              <p className="text-[#777] text-[12px]">Service price</p>
              {(isMultiService || actualPrice) && (
                <p className="text-[#777] text-[17px] relative w-fit">
                  $ {updatedActualPrice} / Order
                  <div className="bg-[#777] h-[1px] w-full top-1/2 -translate-y-1/2 left-0 absolute"></div>
                </p>
              )}
              <p className=" text-white text-[38px] font-bold">
                ${updatedOfferPrice} <span className="text-lg font-normal">/ Order</span>{" "}
              </p>
              {(isMultiService || actualPrice) && (
                <p className="text-[#fefefe] font-semibold text-[12px] bg-primary px-3 py-1 w-fit rounded-full">
                  Savings{" "}
                  {(((updatedActualPrice - updatedOfferPrice) / updatedActualPrice) * 100).toFixed(
                    2
                  )}
                  %
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2 text-left">
              <p className="text-[#777] text-[12px]">Dentist Price</p>
              {/* <p className="text-[#777] text-[17px] relative w-fit">
                --
                <div className="bg-[#777] h-[1px] w-full top-1/2 -translate-y-1/2 left-0 absolute hidden"></div>
              </p> */}
              <p className=" text-white text-[38px] font-bold">
                ${updatedPortalPrice} <span className="text-lg font-normal">/ Order</span>{" "}
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              onClick={
                serviceProviderOrdersData?.length > 0 &&
                orderDetails?.statusCode !== "126" &&
                orderDetails?.statusCode !== "108" &&
                orderDetails?.statusCode !== "117"
                  ? null
                  : () => setModalIsOpen(true)
              }
              className={`w-fit bg-transparent border hover:shadow-md border-[#FF7E00] rounded-lg hover:bg-[#FF7E00] text-[#FF7E00] hover:text-white py-2 px-4 font-medium ${
                serviceProviderOrdersData?.length > 0 ? "cursor-default" : ""
              }`}
            >
              {serviceProviderOrdersData?.length > 0 &&
              orderDetails?.statusCode !== "126" &&
              orderDetails?.statusCode !== "108" &&
              orderDetails?.statusCode !== "117"
                ? status === "0"
                  ? "Rejected"
                  : orderDetails?.currentStatus === "2"
                  ? "Waiting for Service Provider Confirmation"
                  : "Accepted By Service Provider"
                : "Assign & Confirm"}
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        style={{ overlay: { zIndex: 1000 } }}
        // style={customStyles}
        ariaHideApp={false}
      >
        <div className="MODAL-BODY h-full z-[1000000]">
          <div className="h-full flex flex-col">
            <div className="h-fit">
              <button className="w-full text-right" onClick={() => setModalIsOpen(false)}>
                <AiOutlineClose className="ml-auto" />
              </button>
              <p className="text-[26px] font-medium text-white leading-8 mb-0">
                Service Provider Confirmation
              </p>
            </div>
            {isOrderAssigned ? (
              <div className="flex-1 flex flex-col items-center justify-center text-white">
                <img src={assignSuccess} alt="" className="w-4/12" />
                <p className="text-2xl font-medium">Successful!</p>
                <p className="text-center my-4">
                  You have successfully Assigned this order to Service provider ({crowdHireId}) for{" "}
                  <span className="text-xl text-primary font-semibold">${updatedOfferPrice}</span>
                </p>
              </div>
            ) : (
              <div className="flex-1 flex flex-col items-center justify-center text-white">
                <img src={assignedIcon} alt="" />

                <p className="text-center my-4">
                  This Order will be assigned to Service provider ({crowdHireId}) for{" "}
                  <span className="text-xl text-primary font-semibold">${updatedOfferPrice}</span>
                </p>

                {orderDetails?.statusCode === "126" && (
                  <div className="w-full mt-6">
                    <TextAreaInputField
                      labelColor={"#777"}
                      labelText={"Add Resolution Note"}
                      errorMessage={"Note is required"}
                      placeholder={"Type here"}
                      isFieldRequired={true}
                      htmlFor={"notes"}
                      name={"notes"}
                      value={notes}
                      handleInputChange={(e) => setNotes(e.target.value)}
                      rows={6}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="flex items-center justify-end gap-4">
              <button
                onClick={() => setModalIsOpen(false)}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                {isOrderAssigned ? "Close" : "Cancel"}
              </button>
              {!isOrderAssigned && (
                <button
                  onClick={assignOrderHandler}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ServiceProviderCard;
