import { format } from "date-fns";

import DateInputFieldV2 from "./DateInputFieldV2";

const DateFilter = ({ range, setRange }) => {
  function handleRange(e) {
    setRange((prev) => ({ ...prev, [e.target.name]: new Date(e.target.value) }));
  }

  return (
    <div className="flex gap-2 items-center relative">
      <div className="flex flex-col">
        <p className="text-xs text-white">Start Date</p>
        <DateInputFieldV2
          labelColor={"#777"}
          labelText={"Start Date"}
          showLabel={false}
          errorMessage={""}
          placeholder={"DD-MM-YYYY"}
          htmlFor={"Start Date"}
          name={"startDate"}
          value={format(range?.startDate, "yyyy-MM-dd")}
          handleInputChange={handleRange}
          validationFunctionName={(value) => (value.trim() === "" ? false : true)}
        />
      </div>
      <div className="flex flex-col">
        <p className="text-xs text-white">End Date</p>
        <DateInputFieldV2
          labelColor={"#777"}
          labelText={"End Date"}
          showLabel={false}
          errorMessage={""}
          placeholder={"DD-MM-YYYY"}
          htmlFor={"End Date"}
          name={"endDate"}
          value={format(range?.endDate, "yyyy-MM-dd")}
          handleInputChange={handleRange}
          validationFunctionName={(value) => (value.trim() === "" ? false : true)}
        />
      </div>

      {/* <input
          type="date"
          name="startDate"
          value={format(range?.startDate, "yyyy-MM-dd")}
          onChange={handleRange}
        />
        <input
          type="date"
          name="endDate"
          value={format(range?.endDate, "yyyy-MM-dd")}
          onChange={handleRange}
        /> */}
    </div>
  );
};

export default DateFilter;
