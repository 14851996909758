import React from "react";

function CardOne({
  bgOne,
  bgTwo,
  icon,
  percentTextColor,
  bigBox,
  showPercentText = false,
  numberTextColor,
  headingTextColor,
  subtextTextColor,
  title,
  count,
  circles,
  handleClick,
}) {
  return (
    <div
      // eslint-disable-next-line react/style-prop-object
      style={{
        background: `linear-gradient( ${bigBox ? "313deg" : "143deg"},  ${bgOne} 10%,${bgTwo} 90%)`,
      }}
      className={`flex justify-between p-5 rounded-md w-full overflow-hidden relative ${
        bigBox ? "md:col-span-2 lg:col-span-2" : ""
      } ${handleClick ? "cursor-pointer" : ""}`}
      onClick={() => {
        if (handleClick) handleClick();
      }}
    >
      <div className="flex flex-col z-40 justify-between">
        <p style={{ color: `${headingTextColor}` }} className="font-medium text-[17px]">
          {title}
        </p>
        <p style={{ color: `${numberTextColor}` }} className="py-4 font-semibold text-2xl">
          {count}
        </p>
        {showPercentText && (
          <div>
            <span style={{ color: `${percentTextColor}` }} className="text-[15px] font-medium">
              +0%
            </span>
            <span style={{ color: `${subtextTextColor}` }} className="text-[15px]">
              {"  "}
              vs previous month
            </span>
          </div>
        )}
      </div>

      <div
        className={` flex z-40  flex-col ${
          bigBox ? "justify-center items-end" : "justify-start items-end"
        }`}
      >
        <img
          className={` object-contain  ${bigBox ? "w-[114px] h-[54px]" : ""}`}
          src={icon}
          alt=""
        />
      </div>
      {circles && (
        <div className="absolute h-[40vh] -bottom-[60%] aspect-square bg-gradient-to-r from-[#202121cb] from-10% to-backgroundV2 to-90%  rounded-full"></div>
      )}
      {circles && (
        <div className="absolute h-[40vh] top-[5%] left-[15%] aspect-square bg-gradient-to-r from-[#202121] from-10% to-backgroundV2 to-90% rounded-full flex justify-center items-center">
          <div className="h-[28vh] aspect-square bg-backgroundV2 rounded-full"></div>
        </div>
      )}
    </div>
  );
}

export default CardOne;
