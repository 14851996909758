import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import RangeInputField from "../InputField-Comps/RangeInputField";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import { TeethV2 } from "../Teeth-Comps/TeethV2";

import {
  useDeleteOrderMissingTeethMutation,
  useDeleteOrderServiceDetailsMutation,
  useDeleteOrderServiceTeethMutation,
  useSaveOrderMissingTeethMutation,
  useSaveOrderServiceDetailsMutation,
  useSaveOrderServiceTeethMutation,
} from "../../services/orders";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";
import { setModelsOrderDetails } from "../../reducers/orderFormReducer";

import {
  ARTICULATION_ATTACHMENTS,
  DRAIN_HOLES,
  MODEL_FILE,
  ORDER_STAGE,
  SERVICE_TEETH_MAPPING,
} from "../../constants/orderForms";
import { findExtraElement, updateTeethMapping } from "../../Utils/utility";
import classes from "../Teeth-Comps/Teeth.module.scss";

function ModelForPrintingCaseDetailsSection({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  categoryObjId,
  submitOrderData,
  selectedServiceCategory,
}) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { modelsOrderDetails } = useSelector((state) => state.orderFormDetails);

  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();
  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [deleteOrderServiceDetails] = useDeleteOrderServiceDetailsMutation();
  const [saveOrderServiceTeeth] = useSaveOrderServiceTeethMutation();
  const [deleteOrderServiceTeeth] = useDeleteOrderServiceTeethMutation();

  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.hasMissingTeeth === null ? "" : orderCaseData?.hasMissingTeeth ? "Yes" : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeethSelectedTeeth || []
  );
  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [notation, setNotation] = useState(orderCaseData?.numberSystem || "FDI");
  const [selectedService, setSelectedService] = useState("");
  const [restorativeTypeSelected, setRestorativeTypeSelected] = useState(
    orderCaseData?.services?.reduce((prev, curr) => {
      return { ...prev, [curr.serviceObjId]: curr };
    }, {}) ?? {}
  );

  const handleFieldChange = (value, name, isMulti = false, checked = false) => {
    setRestorativeTypeSelected((prev) => {
      let actualValue = value;

      if (isMulti) {
        if (checked) {
          actualValue = [...(prev?.[selectedService?.serviceObjId]?.[name] ?? []), value];
        } else {
          actualValue = (prev?.[selectedService?.serviceObjId]?.[name] ?? [])?.filter(
            (item) => item !== value
          );
        }
      }

      return {
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          [name]: actualValue,
        },
      };
    });
  };

  console.log("restorativeTypeSelected", restorativeTypeSelected);

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "Orthodontic Treatment Planning",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) {
        // setPreferredSoftwares(softwaresList[0]?.softwares);
        setPreferredSoftwares([
          {
            softwareName: "Exocad Model Creator",
          },
          {
            softwareName: "3Shape Model Maker",
          },
          {
            softwareName: "inLab Model ",
          },
          {
            softwareName: "Medit Model Builder",
          },
        ]);
      } else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDetails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDetails();
  }, []);

  const getRestrictedTeeth = (serviceObjId) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...(restorativeTypeSelected[d]?.cutForDiesTeeth ?? []));
      });
    return restrictedTeethData;
  };

  const deleteService = async (service) => {
    setRestorativeTypeSelected((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      delete copy[service?.serviceObjId];
      return copy;
    });

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    deleteOrderServiceDetails({
      accessToken,
      _id: orderByData?._id,
      serviceObjId: service?.serviceObjId,
    });
  };

  const prepareServicesElements = useMemo(() => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeSelected.hasOwnProperty(eachService?.serviceObjId) &&
              Object.keys(restorativeTypeSelected[eachService?.serviceObjId] ?? {})?.length > 0
            }
            handleSelectedIcon={() => deleteService(eachService)}
            id={categoryObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  }, [
    categoryObjId,
    restorativeTypeSelected,
    selectedService?.serviceObjId,
    selectedServiceCategory?.catservices,
  ]);

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    if (restorativeTypeSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeSelected((prev) => ({
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          teeth: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId],
            event.target.value
          ),
        },
      }));
    }
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = ["specialInstructions", "baseThickness"];

    return requiredFields.includes(fieldName);
  };

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
    if (event.target.value === "No") {
      setMissingTeethSelectedTeeth([]);
    }
  };

  const validateNumber = (value) => {
    return Number(value) ? true : false;
  };

  const submitCaseDetailsForm = async () => {
    submitOrderData({
      numberSystem: notation,
      hasMissingTeeth: hasMissingTeeth,
      missingTeethSelectedTeeth: missingTeethSelectedTeeth,
      services: Object.keys(restorativeTypeSelected)?.map((key) => ({
        ...restorativeTypeSelected[key],
        serviceObjId: key,
      })),
    });
    setActiveTab(ORDER_STAGE.UPLOAD_FILES);
  };

  const handleMissingTeethSelection = async (value) => {
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleServiceTeethSave = async (value, teethKey) => {
    console.log("value", value);
    const actualTeethMapping = structuredClone(
      restorativeTypeSelected[selectedService?.serviceObjId]?.[teethKey] ?? []
    );
    console.log("actualTeethMapping", actualTeethMapping);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    const serviceDetails = modelsOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: modelsOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
      dispatch(
        setModelsOrderDetails({
          ...modelsOrderDetails,
          caseDetails: {
            ...(modelsOrderDetails?.caseDetails ?? {}),
            services: [
              ...(modelsOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [teethKey]: value },
            ],
          },
        })
      );
    }

    if (value?.length > actualTeethMapping?.length) {
      const teethNo = findExtraElement(actualTeethMapping, value);
      saveOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    } else if (value?.length < actualTeethMapping?.length) {
      const teethNo = findExtraElement(value, actualTeethMapping);
      deleteOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-4">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white mb-8">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth (This helps us to assess whether
            your intraoral scans are complete enough for a model print){" "}
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={handleMissingTeethSelection}
                defaultNotation={notation}
                allowBulkTeethSelection={true}
                key={`missingTeethSelectedTeeth${notation}`}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <p className="font-medium text-[24px] text-white mb-4">
            Models for Printing Service Type
          </p>
          <p className="text-[16px] text-[#777] mb-8">
            Please choose a Models for Printing service type below. For Die models alone, you will
            be able to choose the teeth that apply for that service type to identify specific teeth
            that need to be 'cut' for dies.{" "}
            <span className="!text-white">
              Multiple model types (for example: for different arches or different model types) can
              also be selected.
            </span>{" "}
            If you prefer not to use our charting system for Die models, you can also provide
            written instructions in the Additional Instructions column below.
          </p>
          {selectedServiceCategory?._id && Object.keys(restorativeTypeSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {["Die models for cosmetic restorations"].includes(selectedService?.serviceName) && (
              <div>
                <div className="mb-8">
                  <p className="text-white text-[17px] mb-2">
                    Please select all teeth that need to be 'cut' for dies
                    {isFieldRequired("cutForDiesTeeth") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div className="">
                    <TeethV2
                      allTeethData={[]}
                      key={
                        selectedService?.serviceObjId +
                        restorativeTypeSelected[selectedService?.serviceObjId]?.cutForDiesTeeth
                          ?.length +
                        notation
                      }
                      selectedTeeth={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.cutForDiesTeeth ??
                        []
                      }
                      setSelectedTeeth={(value) => {
                        let result = value(
                          restorativeTypeSelected[selectedService?.serviceObjId]?.cutForDiesTeeth ||
                            []
                        );
                        handleFieldChange(result, "cutForDiesTeeth");
                        handleServiceTeethSave(result, "cutForDiesTeeth");
                      }}
                      defaultNotation={notation}
                      notationVisible={false}
                      missingTeeth={missingTeethSelectedTeeth}
                      restrictedTeeth={getRestrictedTeeth(selectedService?.serviceObjId)}
                      restrictedTeethMessage={
                        "This tooth is already selected in other restorative type"
                      }
                      disabledTeeths={
                        ["Clear Aligners anterior", "Clear Aligners anterior refinement"].includes(
                          selectedService?.serviceName
                        )
                          ? notation === "FDI"
                            ? [
                                14, 15, 16, 17, 18, 24, 25, 26, 27, 28, 44, 45, 46, 47, 48, 34, 35,
                                36, 37, 38,
                              ]
                            : [
                                1, 2, 3, 4, 5, 12, 13, 14, 15, 16, 28, 29, 30, 31, 32, 17, 18, 19,
                                20, 21,
                              ]
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="mb-8">
                <div className="w-full">
                  <RangeInputField
                    labelColor={"#777"}
                    labelText={"Would you prefer a specific base thickness? (mm)"}
                    subtitle={
                      "If you are unsure what thickness to request, please leave slider at 0 mm. A suitable setting for the height of your digital file will then apply."
                    }
                    errorMessage={"Please enter a valid value"}
                    placeholder={""}
                    htmlFor={"baseThickness"}
                    name={"baseThickness"}
                    value={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.baseThickness ?? "0"
                    }
                    handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                    validationFunctionName={validateNumber}
                    isSubmitted={isFieldRequired}
                    isFieldRequired={isFieldRequired("baseThickness")}
                    unit="mm"
                    min={0}
                    step={1}
                    max={6}
                  />
                </div>
              </div>
              <div className="mb-8">
                <p className="text-white my-4">
                  Would you like articulation attachments ? (applicable if you are printing upper
                  and lower models only, otherwise please select No)
                  <span className="text-red-500 ml-1">*</span>
                </p>
                <div className="text-white flex">
                  <div className="flex items-center mr-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]
                          ?.needArticulationAttachments === "Yes"
                      }
                      onChange={(e) =>
                        handleFieldChange(e.target.value, "needArticulationAttachments")
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]
                          ?.needArticulationAttachments === "No"
                      }
                      onChange={(e) =>
                        handleFieldChange(e.target.value, "needArticulationAttachments")
                      }
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
              </div>
              {restorativeTypeSelected[selectedService?.serviceObjId]
                ?.needArticulationAttachments === "Yes" && (
                <div className="mb-8">
                  <p className="text-white my-4">
                    How many articulation attachments would you like?
                    <span className="text-red-500 ml-1">*</span>
                  </p>

                  <div className="text-white flex gap-8">
                    {Object.values(ARTICULATION_ATTACHMENTS)?.map((articulation) => (
                      <div
                        className="flex items-center mr-3"
                        key={"articulationAttachmentsNeeded-" + articulation}
                      >
                        <CircularCheckbox
                          value={articulation}
                          checked={
                            restorativeTypeSelected[selectedService?.serviceObjId]
                              ?.articulationAttachmentsNeeded === articulation
                          }
                          onChange={(e) =>
                            handleFieldChange(e.target.value, "articulationAttachmentsNeeded")
                          }
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{articulation}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="mb-8">
              <p className="text-white mb-4">
                What type of model file would you like? <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="flex md:gap-32 gap-4 items-center">
                {Object.values(MODEL_FILE)?.map((model) => (
                  <div className="flex items-center" key={"modelFile-" + model}>
                    <CircularCheckbox
                      value={model}
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]?.modelFile === model
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "modelFile")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">{model}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-8">
              <p className="text-white mb-4">
                Would you like engraved text (we do not provide embossed) on your models? If Yes,
                please name your files that you upload with the text you want on your models.
                <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="flex md:gap-32 gap-4 items-center">
                <div className="flex items-center">
                  <CircularCheckbox
                    value="Yes"
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.needEngravedText ===
                      "Yes"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "needEngravedText")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.needEngravedText ===
                      "No"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "needEngravedText")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="text-white my-4">
                Would you like drain holes on your models?
                <span className="text-red-500 ml-1">*</span>
              </p>
              <div className="text-white flex">
                <div className="flex items-center mr-3">
                  <CircularCheckbox
                    value="Yes"
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.needDrainHoles ===
                      "Yes"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "needDrainHoles")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">Yes</p>
                </div>
                <div className="flex items-center">
                  <CircularCheckbox
                    value="No"
                    checked={
                      restorativeTypeSelected[selectedService?.serviceObjId]?.needDrainHoles ===
                      "No"
                    }
                    onChange={(e) => handleFieldChange(e.target.value, "needDrainHoles")}
                    borderColor={"#FF8503"}
                    backgroundColor={"#FF8503"}
                  />
                  <p className="text-white text-[16px]">No</p>
                </div>
              </div>
            </div>
            {restorativeTypeSelected[selectedService?.serviceObjId]?.needDrainHoles === "Yes" && (
              <div className="mb-8">
                <p className="text-white my-4">
                  How many drain holes would you like? <span className="text-red-500 ml-1">*</span>
                </p>

                <div className="text-white grid grid-cols-8">
                  {DRAIN_HOLES?.map((holes) => (
                    <div className="flex items-center mr-3" key={"drianHoles-" + holes}>
                      <CircularCheckbox
                        value={holes}
                        checked={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.drainHolesNeeded === holes
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "drainHolesNeeded")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{holes}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p className="text-white mb-4">
                  Preferred model design software?
                  {isFieldRequired("preferredSoftware") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="grid grid-cols-4 gap-y-3">
                  {preferredSoftwares?.map((item) => {
                    return (
                      <div className="flex items-center mr-3" key={item?.softwareObjId}>
                        <CircularCheckbox
                          value={item?.softwareName}
                          checked={
                            restorativeTypeSelected[selectedService?.serviceObjId]
                              ?.preferredSoftware === item?.softwareName
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{item?.softwareName}</p>
                      </div>
                    );
                  })}
                  <div className="flex items-center mr-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={
                        restorativeTypeSelected[selectedService?.serviceObjId]
                          ?.preferredSoftware === "No Preferance"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-6">
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Enter Additional Instructions"}
                isFieldRequired={isFieldRequired("additionalInstructions")}
                htmlFor={"additionalInstructions"}
                name={"additionalInstructions"}
                value={
                  restorativeTypeSelected[selectedService?.serviceObjId]?.additionalInstructions ??
                  ""
                }
                handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                rows={4}
                cols={5}
              />
              {restorativeTypeSelected[
                selectedService?.serviceObjId
              ]?.additionalInstructions?.trim()?.length > 0 ? (
                <></>
              ) : (
                <p className="text-sm text-red-500 ml-1">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div className="BOTTOM-SECTION mt-8">
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab(ORDER_STAGE.PATIENT_DETAILS);
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModelForPrintingCaseDetailsSection;
