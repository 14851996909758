import { useState, useEffect } from "react";

import graph from "../../Assets/images/graph.svg";
import CardOne from "../../Components/Dashboard-Comps/CardOne";
import iconOne from "../../Assets/images/frame.svg";
import fileIcon from "../../Assets/images/fileCardIcon.svg";
import searchIcon from "../../Assets/images/searchInputIcon.svg";
import QuickOrderCard from "../../Components/Dashboard-Comps/QuickOrderCard";
import toothPic from "../../Assets/images/toothPic.svg";
import { IoIosArrowForward } from "react-icons/io";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import logo from "../../Assets/images/LOGO.svg";

import OrderHistoryCard from "../../Components/Dashboard-Comps/OrderHistoryCard";

// import CustomTabsNew from "../../Components/Dashboard-Comps/";
import CustomTabsDashboard from "../../Components/Dashboard-Comps/CustomTabsDashboard";
import { LoginButton } from "../Login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import {
  iconArray,
  superAdminIconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
} from "../../constants/NavbarRoutesArray";
import { useGetServicesMutation } from "../../services/services";

import CustomCarousel from "../../Components/UserManagement-Comps/CustomCarousel";
import { useGetProfileMutation } from "../../services/user";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import { Tooltip } from "react-tooltip";
import { useGetOrdersForDentistMutation, useOrderStatsMutation } from "../../services/orders";
import { useNavigate } from "react-router";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import { endOfMonth, startOfMonth } from "date-fns";
import DateFilter from "../../Components/InputField-Comps/DateFilter";

function DashboardDentist({ userRoles, userInfo, setUserInfo }) {
  const navigate = useNavigate();
  const [getProfile] = useGetProfileMutation();
  const { user, isAuthenticated } = useAuth0();
  const { userProfileDetails } = useSelector((state) => state.personalDetails);
  const { getAccessTokenSilently } = useAuth0();
  const [getServices, { data: servicesData }] = useGetServicesMutation();
  const [getOrdersForDentist] = useGetOrdersForDentistMutation();
  const [userRole, setUserRole] = useState(userRoles);
  const [ordersData, setOrdersData] = useState([]);
  const [activePage, setActivePage] = useState({
    id: "",
    title: "InitialLoading",
    description: "",
    priceTo: "",
    priceFrom: "",
    gallery: {},
  });

  const [ordersCreated, setOrdersCreated] = useState(0);
  const [ordersInProgress, setOrdersInProgress] = useState(0);
  const [quotesToReview, setQuotesToReview] = useState(0);
  const [deliveryInReview, setDeliveryInReview] = useState(0);
  const [deliveryCompleted, setDeliveryCompleted] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: "selection",
  });

  console.log("dateRange", dateRange);

  const [orderStats] = useOrderStatsMutation();

  const getOrderCreated = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "1",
      },
    }).unwrap();
    console.log("resp987987", resp);
    setOrdersCreated(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getOrderInProgress = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "5",
      },
    }).unwrap();
    setOrdersInProgress(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getQuotesToReview = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "2",
      },
    }).unwrap();
    setQuotesToReview(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getDeliveryInReview = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "7",
      },
    }).unwrap();
    setDeliveryInReview(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getDeliveryCompleted = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "8",
      },
    }).unwrap();
    setDeliveryCompleted(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getDashboardStats = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    getOrderCreated(accessToken);
    getOrderInProgress(accessToken);
    getQuotesToReview(accessToken);
    getDeliveryInReview(accessToken);
    getDeliveryCompleted(accessToken);
  };

  useEffect(() => {
    getDashboardStats();
  }, [dateRange]);

  const getOrdersData = async (accessToken) => {
    const resp = await getOrdersForDentist({ accessToken }).unwrap();
    if (resp) {
      if (resp.data.length === 0) {
        // GetToast('Currently there are no orders in due', "success");
      }
      setOrdersData(resp.data);
    }
  };

  const readOrders = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getOrdersData(accessToken);
  };

  const prepareServicesElements = () => {
    if (servicesData?.data?.length > 0) {
      const arrayOfCards = [];
      // debugger;

      // Create HTML elements based on servicesData
      servicesData.data.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            id={eachService._id}
            image={eachService.image}
            gallery={{ images: eachService.images, videos: eachService.videos }}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService.serviceName}
            description={eachService.serviceDescription}
            priceFrom={eachService.priceRangeFrom}
            priceTo={eachService.priceRangeTo}
            orderUrl={eachService?.orderUrl}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const handleCardOnClick = (card) => {
    setActivePage({
      id: card.props.id,
      title: card.props.title,
      description: card.props.description,
      priceTo: card.props.priceTo,
      priceFrom: card.props.priceFrom,
      gallery: card.props.gallery,
      orderUrl: card.props?.orderUrl,
    });
    if (card.props?.orderUrl) {
      navigate("/addNewOrder", { state: { serviceId: card.props.id } });
    } else {
      GetToast(`Order form for ${card.props?.title} is offline and will be live soon…`, "error");
    }
  };

  const getServicesData = async (accessToken) => {
    await getServices(accessToken).unwrap();
  };

  const readServicesInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await getServicesData(accessToken);
  };

  useEffect(() => {
    readServicesInfo();
    readOrders();
  }, []);

  // for order history section
  const arrayOfTilesOne = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];
  const arrayOfTilesTwo = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];

  useEffect(() => {
    if (userInfo[0]?.crowdHireId === undefined) {
      const getUpdatedProfile = async () => {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
          },
        });
        const response = await getProfile(accessToken).unwrap();
        if (response) {
          localStorage.setItem("userCrowdHireId", response?.chDetails[0]?.crowdHireId);
          localStorage.setItem("useractive", response?.chDetails[0]?.active);
          setUserInfo(response?.chDetails);
        }
      };
      getUpdatedProfile();
    }
  }, []);

  return (
    <>
      <div className="flex bg-black">
        <Navbar
          iconArray={
            userRole === "89uij7"
              ? superAdminIconArray
              : userRole === "676dza2"
              ? iconArray
              : spIconArray
          }
          navHoverCss={navHoverCss}
          navActiveCss={navActiveCss}
          middleSection={false}
          middleTabTitleArray={[""]}
          middleTabContentObject={{}}
          userRole={userRole}
          active={"Dashboard"}
        />
        <div className="MAIN-DASH bg-black p-4 w-full h-fit">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {userProfileDetails?.firstName ?? user?.name}
                </div>
              ) : (
                <LoginButton />
              )}
              {userRole !== "89uij7" ? (
                userInfo?.length > 0 ? (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    {userInfo[0]?.active === 1 ? (
                      <>
                        {userInfo[0]?.crowdHireId}
                        <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                      </>
                    ) : (
                      <div
                        className="flex"
                        data-tooltip-id="status"
                        data-tooltip-content={
                          "Your registration is under review and you will be notified once admin approves your registration."
                        }
                      >
                        <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                        <img
                          className="relative top-[0px] ml-2 right-0 h-5 w-5"
                          src={redCross}
                          alt=""
                        />
                        <Tooltip id="status" place="bottom" />
                      </div>
                    )}
                  </button>
                ) : (
                  <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                    Account Not Active
                    <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
                  </button>
                )
              ) : (
                <></>
              )}

              <Notification />
            </div>
          </div>
          <div className="SEARCH-BAR flex justify-between items-center mb-8 gap-12">
            <p className="font-medium text-[34px] leading-7 mr-8 text-textP">Dashboard</p>
            {/* <DateFilter range={dateRange} setRange={setDateRange} /> */}
          </div>
          <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-5 md:grid-rows-2 gap-y-8 md:gap-12">
            <div className="CARD-GRID col-span-3 grid lg:grid-cols-3 md:grid-cols-2, gap-4">
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={iconOne}
                bigBox={true}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders Created"}
                count={ordersCreated}
                circles={true}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=1")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders In Progress"}
                count={ordersInProgress}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=5")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Quotes To Review"}
                count={quotesToReview}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=2")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Delivery In Review"}
                count={deliveryInReview}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=7")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Delivery Completed"}
                count={deliveryCompleted}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=8")}
              />
            </div>
            <div className="SIDE-INFO-BAR rounded-lg row-span-1 md:row-span-2 col-span-2 bg-secondary  p-4">
              <div className="flex justify-between items-center mb-4">
                <p className="font-semibold text-xl text-white">Quick Order</p>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Find Service"
                    className="placeholder:text-[#99] placeholder:text-[16px] text-[16px] text-white pl-8 w-36 bg-secondary bg-opacity-30 focus:outline-none"
                  />
                  <img className="absolute top-1 left-1" src={searchIcon} alt="" />
                </div>
              </div>
              <div className="CAROUSEL">
                <CustomCarousel
                  // rows and columns you want per slide with x and y gaps in pixels
                  rows={2}
                  cols={3}
                  gapX={20}
                  gapY={20}
                  completeSliders={prepareServicesElements()}
                  numberOfCardsShownInSingleSlide={5}
                  handleCardOnClick={handleCardOnClick}
                />
              </div>

              <div className="flex justify-between items-center my-4">
                <p className="font-semibold text-xl text-white">Order History</p>
                <p className="text-[16px] text-white leading-4 font-medium flex items-center cursor-pointer">
                  View full History{"  "} <IoIosArrowForward />
                </p>
              </div>
              <div className="TABS">
                <CustomTabsDashboard
                  tabsTitleArray={["Crown and Bridge", "Dental Implant"]}
                  tabsContentObject={{
                    titleOne: arrayOfTilesOne,
                    titleTwo: arrayOfTilesTwo,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardDentist;
