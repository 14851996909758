import { createSlice } from "@reduxjs/toolkit";
import {
  crownAndBridgeInitialOrderDetails,
  dentalImplantInitialOrderDetails,
  modelInitialOrderDetails,
  orthodonticInitialOrderDetails,
  removablesInitialOrderDetails,
  smileInitialOrderDetails,
  surgicalInitialOrderDetails,
} from "../Utils/orderFormInitialDetails";

const initialState = {
  // for Crown and Bridge Order Form
  crownAndBridgeOrderDetails: crownAndBridgeInitialOrderDetails,
  // for Dental Implant Order Form
  dentalImplantOrderDetails: dentalImplantInitialOrderDetails,
  // for Orthodontic Order Form
  orthodonticOrderDetails: orthodonticInitialOrderDetails,
  // for Removables Order Form
  removablesOrderDetails: removablesInitialOrderDetails,
  // for Smile Design Order Form
  smileOrderDetails: smileInitialOrderDetails,
  // for Models for Printing Order Form
  modelsOrderDetails: modelInitialOrderDetails,
  // for Surgical Guides Order Form
  surgicalOrderDetails: surgicalInitialOrderDetails,

  orderFormLastProgressTab: 0,
};

export const orderFormSlice = createSlice({
  name: "orderForm",
  initialState,
  reducers: {
    setCrownAndBridgeOrderDetails: (state, action) => {
      state.crownAndBridgeOrderDetails = action.payload;
    },
    setDentalImplantOrderDetails: (state, action) => {
      state.dentalImplantOrderDetails = action.payload;
    },
    setOrthodonticOrderDetails: (state, action) => {
      state.orthodonticOrderDetails = action.payload;
    },
    setRemovablesOrderDetails: (state, action) => {
      state.removablesOrderDetails = action.payload;
    },
    setSmileOrderDetails: (state, action) => {
      state.smileOrderDetails = action.payload;
    },
    setModelsOrderDetails: (state, action) => {
      state.modelsOrderDetails = action.payload;
    },
    setSurgicalOrderDetails: (state, action) => {
      state.surgicalOrderDetails = action.payload;
    },
    setOrderFormLastProgressTab: (state, action) => {
      state.orderFormLastProgressTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCrownAndBridgeOrderDetails,
  setDentalImplantOrderDetails,
  setOrthodonticOrderDetails,
  setRemovablesOrderDetails,
  setSmileOrderDetails,
  setModelsOrderDetails,
  setSurgicalOrderDetails,
  setOrderFormLastProgressTab,
} = orderFormSlice.actions;
