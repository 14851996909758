import React, { useEffect, useState } from "react";

// import { useDispatch } from "react-redux";
// import { readData } from "../../actions/tableService";
import CardOne from "../../Components/Dashboard-Comps/CardOne";
import iconOne from "../../Assets/images/frame.svg";
import fileIcon from "../../Assets/images/fileCardIcon.svg";
import { IoIosArrowForward } from "react-icons/io";
// import CustomTabs from "../../Components/Dashboard-Comps/CustomTabs-Dep";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import logo from "../../Assets/images/LOGO.svg";

import OrderHistoryCard from "../../Components/Dashboard-Comps/OrderHistoryCard";

// import CustomTabsNew from "../../Components/Dashboard-Comps/";
import CustomTabsDashboard from "../../Components/Dashboard-Comps/CustomTabsDashboard";
import { LoginButton } from "../Login/Login";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  iconArray,
  superAdminIconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
} from "../../constants/NavbarRoutesArray";

import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import {
  useOrderStatsMutation,
  usePaymentStatsMutation,
  useRefundStatsMutation,
} from "../../services/orders";
import { endOfMonth, startOfMonth } from "date-fns";
import { useNavigate } from "react-router-dom";

function Dashboard({ userRoles, userInfo }) {
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userRole, setUserRole] = useState(userRoles);
  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const [ordersDelivered, setOrdersDelivered] = useState(0);
  const [ordersToReview, setOrdersToReview] = useState(0);
  const [ordersDisputed, setOrdersDisputed] = useState(0);
  const [rejectedByServiceProvider, setRejectedByServiceProvider] = useState(0);
  const [paymentsReceived, setPaymentsReceived] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRefunds, setTotalRefunds] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: "selection",
  });

  console.log("dateRange", dateRange);

  const [orderStats] = useOrderStatsMutation();
  const [paymentStats] = usePaymentStatsMutation();
  const [refundStats] = useRefundStatsMutation();

  // eslint-disable-next-line no-unused-vars
  const roles = user?.["https://localhost:3000/roles"] || [];

  const getOrderDelivered = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "8",
      },
    }).unwrap();
    console.log("resp987987", resp);
    setOrdersDelivered(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getOrderToReview = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "1",
      },
    }).unwrap();
    setOrdersToReview(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getOrdersDisputed = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "9",
      },
    }).unwrap();
    setOrdersDisputed(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getOrdersRejectedByServiceProvider = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "9",
      },
    }).unwrap();
    setRejectedByServiceProvider(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getPaymentsReceived = async (accessToken) => {
    const resp = await orderStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "9",
      },
    }).unwrap();
    setPaymentsReceived(resp?.data?.[0]?.orderCount ?? 0);
  };

  const getTotalRevenue = async (accessToken) => {
    const resp = await paymentStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "9",
      },
    }).unwrap();
    console.log("firstadasdasd", resp);
    setTotalRevenue(resp?.data?.[0]?.totalAmount ?? 0);
  };

  const getTotalRefunds = async (accessToken) => {
    const resp = await refundStats({
      accessToken,
      data: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        currentStatus: "9",
      },
    }).unwrap();
    setTotalRefunds(resp?.data?.[0]?.totalRefundAmount ?? 0);
  };

  const getDashboardStats = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    getOrderDelivered(accessToken);
    getOrderToReview(accessToken);
    getOrdersDisputed(accessToken);
    getOrdersRejectedByServiceProvider(accessToken);
    getPaymentsReceived(accessToken);
    getTotalRevenue(accessToken);
    getTotalRefunds(accessToken);
  };

  useEffect(() => {
    getDashboardStats();
  }, [dateRange]);

  // for order history section
  const arrayOfTilesOne = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];
  const arrayOfTilesTwo = [
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
    <OrderHistoryCard
      avatarBgColor={"#FB6E52"}
      avatarFontSize={"13px"}
      avatarText={"MG"}
      avatarColor={"#fff"}
      headingText={"My Crown & Bridge"}
      headingColor={"#333"}
      headingFontSize={"13px"}
      subHeadingText={"Location of dummy, address..."}
      subHeadingColor={"#777"}
      subHeadingFontSize={"13px"}
      dateHeadingColor={"#333"}
      dateHeadingFontSize={"13px"}
      dateHeadingText={"Date"}
      date={"12/03/2023"}
      dateColor={"#777"}
      dateFontSize={"12px"}
      // you can add hover bg color and focus bg color here
      focusBgColor={"#f1f1f5"}
      hoverBgColor={"#f1f1f5"}
    />,
  ];

  return (
    <>
      <div className="flex bg-black">
        <Navbar
          iconArray={
            userRole === "89uij7"
              ? superAdminIconArray
              : userRole === "676dza2"
              ? iconArray
              : spIconArray
          }
          navHoverCss={navHoverCss}
          navActiveCss={navActiveCss}
          middleSection={false}
          middleTabTitleArray={[""]}
          middleTabContentObject={{}}
          userRole={userRole}
          active={"Dashboard"}
        />
        <div className="MAIN-DASH bg-background p-4 w-full h-full">
          <div className="NAVBAR flex justify-between items-center mb-4 ">
            <p className="text-[15px] text-[#999999] flex justify-between items-center">
              <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
              <span>
                <img src={hut} alt="" />
              </span>
              <span className="ml-3">Dashboard</span>
            </p>
            <div className="flex gap-4 items-center">
              {isAuthenticated ? (
                <div className="text-[15px] justify-center items-center hidden md:flex text-white">
                  Hello, {userProfileDetails?.firstName ?? user.name}
                </div>
              ) : (
                <LoginButton />
              )}
              <Notification />
            </div>
          </div>
          <div className="SEARCH-BAR flex justify-between items-center mb-8 gap-12">
            <p className="font-medium text-[34px] leading-7 mr-8 text-textP">Dashboard</p>
            {/* <DateFilter range={dateRange} setRange={setDateRange} /> */}
          </div>
          <div className="grid grid-cols-1 grid-rows-3 md:grid-cols-5 md:grid-rows-2 gap-y-8 md:gap-12">
            <div className="CARD-GRID col-span-3 grid lg:grid-cols-3 md:grid-cols-2, gap-4">
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={iconOne}
                bigBox={true}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders Delivered"}
                count={ordersDelivered}
                circles={true}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=8")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders In Review"}
                count={ordersToReview}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=1")}
              />

              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders Disputed"}
                count={ordersDisputed}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=9")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Orders Rejected By Service Provider"}
                count={rejectedByServiceProvider}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=3")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Payments Received"}
                count={paymentsReceived}
                handleClick={() => navigate("/reports?orderType=Orders&orderStatus=5")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Total Revenue"}
                count={`$ ${totalRevenue}`}
                handleClick={() => navigate("/reports?orderType=Payments")}
              />
              <CardOne
                bgOne={"#171818"}
                bgTwo={"#171818"}
                icon={fileIcon}
                bigBox={false}
                percentTextColor={"white"}
                numberTextColor={"white"}
                headingTextColor={"white"}
                subtextTextColor={"white"}
                title={"Total Refunds"}
                count={`$ ${totalRefunds}`}
                handleClick={() => navigate("/reports?orderType=Refunds")}
              />
            </div>
            <div className="SIDE-INFO-BAR rounded-lg row-span-1 md:row-span-2 col-span-2 bg-secondary p-4">
              <div className="flex justify-between items-center my-4 ">
                <p className="font-semibold text-xl text-white">Order</p>
                <p className="text-[16px] text-white leading-4 font-medium flex items-center cursor-pointer">
                  View full History{"  "} <IoIosArrowForward />
                </p>
              </div>
              <div className="TABS">
                <CustomTabsDashboard
                  tabsTitleArray={["Crown and Bridge", "Dental Implant"]}
                  tabsContentObject={{
                    titleOne: arrayOfTilesOne,
                    titleTwo: arrayOfTilesTwo,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{ marginLeft: 95, maxWidth: 650 }}>
        <Teeth />
      </div> */}
    </>
  );
}

export default withAuthenticationRequired(Dashboard);
// export default Dashboard;
