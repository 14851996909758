import { useRef, useState } from "react";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import hut from "../../Assets/images/hut.svg";
import logo from "../../Assets/images/LOGO.svg";
import { FaAngleRight } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import { useNavigate } from "react-router-dom";
import orderPlacedIcon from "../../Assets/icons/orderPlacedIcon.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import imagePlaceholderIcon from "../../Assets/images/imagePlaceholderIcon.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Modal from "react-modal";

import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../Login/Login";
import { Tooltip } from "react-tooltip";
import Notification from "../../Components/Notification/Notification";
import { useSelector } from "react-redux";
import { CORRECTION_TECHNIQUE, ORDER_STAGE } from "../../constants/orderForms";
import { isImageFormatDisplayable } from "../../Utils/utility";
import OrderSummary from "../../Components/OrderSummary-Comps/OrderSummary";

function OrthodonticOrderConfirmation({
  orderFor,
  orderObjId,
  userInfo,
  userRole,
  setActiveTab,
  setIsDisclaimerAccepted,
  selectedServiceCategory,
  categoryObjId,
  isOrderPlaced = false,
  placeOrderHandler,
  orderByData,
}) {
  return (
    <div className="flex w-full">
      <OrderConfirmationTab
        orderObjId={orderObjId}
        userRole={userRole}
        userInfo={userInfo}
        setActiveTab={setActiveTab}
        setIsDisclaimerAccepted={setIsDisclaimerAccepted}
        selectedServiceCategory={selectedServiceCategory}
        isOrderPlaced={isOrderPlaced}
        placeOrderHandler={placeOrderHandler}
        categoryObjId={categoryObjId}
        orderByData={orderByData}
      />
    </div>
  );
}

export default OrthodonticOrderConfirmation;

export const OrderConfirmationTab = ({
  userInfo,
  userRole,
  orderObjId,
  setActiveTab,
  setIsDisclaimerAccepted,
  selectedServiceCategory,
  isOrderPlaced,
  placeOrderHandler,
  categoryObjId,
  orderByData,
}) => {
  const pdfRef = useRef();
  const navigate = useNavigate();

  const { orthodonticOrderDetails } = useSelector((state) => state.orderFormDetails);
  const { user, isAuthenticated } = useAuth0();

  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width * 0.264583; // Convert pixels to mm (1 px = 0.264583 mm)
      const pdfHeight = canvas.height * 0.264583; // Convert pixels to mm

      const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]); // Create a PDF with custom page size
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight); // Add the image to the PDF
      pdf.save("download.pdf");
    });
  };

  return (
    <div className="MAIN-DASH bg-black md:p-4 px-4 pt-4 pb-20 w-full min-h-screen h-fit flex flex-col">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <img src={logo} className="mx-auto w-[50px] h-[40px] mr-4 block md:hidden" alt="" />
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between gap-4 w-full bg-secondary px-4 rounded-t-xl py-4">
        <div>
          <p className="font-medium text-[34px] text-white">Orthodontic Planning Order</p>
          {orderByData?.orderNumber && (
            <p className="text-white">
              Order Id : <strong className="text-primary">{orderByData?.orderNumber}</strong>
            </p>
          )}
        </div>
        {!isOrderPlaced && (
          <>
            <div onClick={downloadPDF} className="flex gap-4 items-center">
              {/* <img src={printerIcon} alt="" className="cursor-pointer" /> */}
              <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
                <img src={exitIcon} alt="" />
                Download Summary
              </button>
            </div>
            <div className="flex items-center md:justify-end gap-4 flex-wrap">
              <button
                onClick={() => {
                  navigate("/orders");
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setActiveTab(ORDER_STAGE.DISCLAIMER);
                  setIsDisclaimerAccepted(false);
                }}
                className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
              >
                Back
              </button>
              <button
                onClick={() => {
                  placeOrderHandler();
                }}
                className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
              >
                Get Quote
              </button>
            </div>
          </>
        )}
      </div>

      <OrderSummary
        data={{ ...orthodonticOrderDetails, categoryObjId: [categoryObjId] }}
        transformDetails={false}
        isOrderPlaced={isOrderPlaced}
        ref={pdfRef}
      />
    </div>
  );
};
