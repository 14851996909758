const ModelsForPrintingOrderSummary = ({ orderData, selectedServiceCategory }) => {
  return (
    <>
      {orderData?.caseDetails?.services?.map((service) => {
        const serviceDetails = selectedServiceCategory?.catservices?.find(
          (d) => d?.serviceObjId === service?.serviceObjId
        );
        if (serviceDetails?.serviceObjId) {
          return (
            <div
              key={"serviceItem-" + serviceDetails?.serviceObjId}
              className="grid md:grid-cols-3 grid-cols-1 gap-y-8 gap-x-4 rounded-xl bg-backgroundV2 p-6 my-4"
            >
              <div className="TILE md:col-span-3 pb-4 border-b border-1 border-dotted border-primary">
                <p className="text-[15px] text-white font-semibold">Service Name</p>
                <p className="text-primary font-medium mt-2 uppercase">
                  {serviceDetails?.serviceName}
                </p>
              </div>
              {["Die models for cosmetic restorations"].includes(serviceDetails?.serviceName) && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    Please select all teeth that need to be 'cut' for dies
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.cutForDiesTeeth?.join(", ") || "NA"}
                  </p>
                </div>
              )}

              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  Would you prefer a specific base thickness? (mm)
                </p>
                <p className="text-[#555] font-medium mt-2">{service?.baseThickness || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Would you like articulation attachments ?</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.needArticulationAttachments || "NA"}
                </p>
              </div>
              {service?.needArticulationAttachments === "Yes" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">
                    How many articulation attachments would you like?
                  </p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.articulationAttachmentsNeeded || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">What type of model file would you like?</p>
                <p className="text-[#555] font-medium mt-2">{service?.modelFile || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">
                  Would you like engraved text (we do not provide embossed) on your models?
                </p>
                <p className="text-[#555] font-medium mt-2">{service?.needEngravedText || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Would you like drain holes on your models?</p>
                <p className="text-[#555] font-medium mt-2">{service?.needDrainHoles || "NA"}</p>
              </div>
              {service?.needDrainHoles === "Yes" && (
                <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                  <p className="text-[15px] text-white">How many drain holes would you like?</p>
                  <p className="text-[#555] font-medium mt-2">
                    {service?.drainHolesNeeded || "NA"}
                  </p>
                </div>
              )}
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Preferred model design software?</p>
                <p className="text-[#555] font-medium mt-2">{service?.preferredSoftware || "NA"}</p>
              </div>
              <div className="TILE pb-4 border-b border-1 border-dotted border-white border-opacity-20">
                <p className="text-[15px] text-white">Additional Instructions</p>
                <p className="text-[#555] font-medium mt-2">
                  {service?.additionalInstructions || "NA"}
                </p>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default ModelsForPrintingOrderSummary;
