import React from "react";

function TabComponentCards({ detailTileList, className }) {
  return (
    <div className={`h-[500px] overflow-y-auto ${className ?? ""}`}>
      <div className="py-8 flex flex-col h-auto md:h-auto md:grid md:grid-cols-2 grid-rows-2 gap-8">
        {detailTileList.map((component, index) => (
          // this key method is wrong input correct key as per data set
          <div key={index}>{component}</div>
        ))}
      </div>
    </div>
  );
}

export default TabComponentCards;
