import { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { TeethV2 } from "../Teeth-Comps/TeethV2";
import DropdownField from "../InputField-Comps/DropdownField";
import QuickOrderCard from "../Dashboard-Comps/QuickOrderCard";
import DateInputFieldV2 from "../InputField-Comps/DateInputFieldV2";
import CustomCarousel from "../UserManagement-Comps/CustomCarousel";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { CircularCheckbox } from "../UserManagement-Comps/CircularForm";
import { TextInputField } from "../Services-Comps/InputFieldsServicesTab";

import {
  useDeleteOrderAnyTeethMobileMutation,
  useDeleteOrderMissingTeethMutation,
  useDeleteOrderServiceDetailsMutation,
  useDeleteOrderServiceMultiFieldMutation,
  useDeleteOrderServiceTeethMutation,
  useDeleteOrderTeethToExtractMutation,
  useSaveOrderAdditionalGuideOptionsMutation,
  useSaveOrderAnyTeethMobileMutation,
  useSaveOrderMissingTeethMutation,
  useSaveOrderServiceDetailsMutation,
  useSaveOrderServiceMultiFieldMutation,
  useSaveOrderServiceTeethMutation,
  useSaveOrderTeethToExtractMutation,
  useUpdateOrderAdditionalGuideOptionsMutation,
} from "../../services/orders";

import {
  findExtraElement,
  implantCompanyList,
  stackableGuideOptionsList1,
  stackableGuideOptionsList2,
  updateTeethMapping,
} from "../../Utils/utility";
import classes from "../Teeth-Comps/Teeth.module.scss";
import {
  ARCHES_TO_TREAT,
  GUIDANCE_LEVEL,
  GUIDE_TYPE,
  ORDER_STAGE,
  SERVICE_TEETH_MAPPING,
  SERVICES_REQUIRED,
} from "../../constants/orderForms";
import { useGetSoftwareCategoryByNameMutation } from "../../services/softwareCategory";

import Option1Image01 from "../../Assets/images/surgicalGuides/Option1Image01.jpg";
import Option1Image02 from "../../Assets/images/surgicalGuides/Option1Image02.jpg";
import Option1Image03 from "../../Assets/images/surgicalGuides/Option1Image03.jpg";
import Option1Image04 from "../../Assets/images/surgicalGuides/Option1Image04.jpg";
import Option1Image05 from "../../Assets/images/surgicalGuides/Option1Image05.jpg";
import Option2Image01 from "../../Assets/images/surgicalGuides/Option2Image01.jpg";
import Option2Image02 from "../../Assets/images/surgicalGuides/Option2Image02.jpg";
import Option2Image03 from "../../Assets/images/surgicalGuides/Option2Image03.jpg";
import Option2Image04 from "../../Assets/images/surgicalGuides/Option2Image04.jpg";
import Option2Image05 from "../../Assets/images/surgicalGuides/Option2Image05.jpg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setSurgicalOrderDetails } from "../../reducers/orderFormReducer";

export const SurgicalCaseDetailsSection = ({
  setActiveTab,
  orderCaseData,
  orderByData,
  setOrderUpdated,
  categoryObjId,
  submitOrderData,
  selectedServiceCategory,
}) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { surgicalOrderDetails } = useSelector((state) => state.orderFormDetails);

  const [saveOrderMissingTeeth] = useSaveOrderMissingTeethMutation();
  const [deleteOrderMissingTeeth] = useDeleteOrderMissingTeethMutation();
  const [saveOrderTeethToExtract] = useSaveOrderTeethToExtractMutation();
  const [deleteOrderTeethToExtract] = useDeleteOrderTeethToExtractMutation();
  const [saveOrderAnyTeethMobile] = useSaveOrderAnyTeethMobileMutation();
  const [deleteOrderAnyTeethMobile] = useDeleteOrderAnyTeethMobileMutation();
  const [saveOrderAdditionalGuideOptions] = useSaveOrderAdditionalGuideOptionsMutation();
  const [updateOrderAdditionalGuideOptions] = useUpdateOrderAdditionalGuideOptionsMutation();
  const [getSoftwareCategoryByName] = useGetSoftwareCategoryByNameMutation();
  const [saveOrderServiceDetails] = useSaveOrderServiceDetailsMutation();
  const [deleteOrderServiceDetails] = useDeleteOrderServiceDetailsMutation();
  const [saveOrderServiceTeeth] = useSaveOrderServiceTeethMutation();
  const [deleteOrderServiceTeeth] = useDeleteOrderServiceTeethMutation();
  const [saveOrderServiceMultiField] = useSaveOrderServiceMultiFieldMutation();
  const [deleteOrderServiceMultiField] = useDeleteOrderServiceMultiFieldMutation();

  const [preferredSoftwares, setPreferredSoftwares] = useState([]);
  const [hasMissingTeeth, setHasMissingTeeth] = useState(
    orderCaseData?.hasMissingTeeth === null ? "" : orderCaseData?.hasMissingTeeth ? "Yes" : "No"
  );
  const [missingTeethSelectedTeeth, setMissingTeethSelectedTeeth] = useState(
    orderCaseData?.missingTeethSelectedTeeth || []
  );
  const [anyTeethMobile, setAnyTeethMobile] = useState(
    orderCaseData?.anyTeethMobile === null ? "" : orderCaseData?.anyTeethMobile ? "Yes" : "No"
  );
  const [anyTeethMobileSelectedTeeth, setAnyTeethMobileSelectedTeeth] = useState(
    orderCaseData?.anyTeethMobileSelectedTeeth || []
  );
  const [teethToExtract, setIntendToExtractTeeth] = useState(
    orderCaseData?.teethToExtract === null ? "" : orderCaseData?.teethToExtract ? "Yes" : "No"
  );
  const [teethToExtractSelectedTeeth, setTeethToExtractSelectedTeeth] = useState(
    orderCaseData?.teethToExtractSelectedTeeth || []
  );
  const [selectedService, setSelectedService] = useState("");
  const [notation, setNotation] = useState(orderCaseData?.numberSystem || "FDI");
  const [restorativeTypeSelected, setRestorativeTypeSelected] = useState(
    orderCaseData?.services?.reduce((prev, curr) => {
      return { ...prev, [curr.serviceObjId]: curr };
    }, {}) ?? {}
  );

  console.log("notation", notation);
  console.log("orderCaseData", orderCaseData);

  const readSoftwareCategoryInfo = async (accessToken) => {
    const response = await getSoftwareCategoryByName({
      accessToken,
      softwareCategoryName: "CAD",
    }).unwrap();
    if (response?.data) {
      const softwaresList = response?.data;
      if (softwaresList?.length > 0) setPreferredSoftwares(softwaresList[0]?.softwares);
      else setPreferredSoftwares([]);
    }
  };

  const readSoftwareCategoriesInfoDetails = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    await readSoftwareCategoryInfo(accessToken);
  };

  useEffect(() => {
    readSoftwareCategoriesInfoDetails();
  }, []);

  const handleNotationChange = (event) => {
    setNotation(event.target.value);
    setAnyTeethMobileSelectedTeeth(
      updateTeethMapping(anyTeethMobileSelectedTeeth, event.target.value)
    );
    setMissingTeethSelectedTeeth(updateTeethMapping(missingTeethSelectedTeeth, event.target.value));
    setTeethToExtractSelectedTeeth(
      updateTeethMapping(teethToExtractSelectedTeeth, event.target.value)
    );
    if (restorativeTypeSelected[selectedService?.serviceObjId]?.length > 0) {
      setRestorativeTypeSelected((prev) => ({
        ...prev,
        [selectedService?.serviceObjId]: {
          ...(prev?.[selectedService?.serviceObjId] ?? {}),
          implantSiteTeeth: updateTeethMapping(
            restorativeTypeSelected[selectedService?.serviceObjId]?.implantSiteTeeth,
            event.target.value
          ),
          densahBursTeeth: updateTeethMapping(
            restorativeTypeSelected?.[selectedService?.serviceObjId]?.densahBursTeeth
          ),
        },
      }));
    }
  };

  console.log("restorativeTypeSelected", restorativeTypeSelected);

  const handleHasMissingTeethChange = (event) => {
    setHasMissingTeeth(event.target.value);
    if (event.target.value === "No") {
      setMissingTeethSelectedTeeth([]);
    }
  };

  const handleIntendToExtractTeethCheckboxChange = (event) => {
    setIntendToExtractTeeth(event.target.value);
  };

  const handleAnyTeethMobileCheckboxChange = (event) => {
    setAnyTeethMobile(event.target.value);
  };

  const validateSurgeryDate = (value) => {
    return value ? true : false;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "additionalInstructions",
      "hasMissingTeeth",
      "missingTeethSelectedTeeth",
      "teethToExtract",
      "guideType",
      "anyTeethMobile",
      "anyTeethMobileSelectedTeeth",
      "preferredImplantCompany",
      "preferredPinCompany",
      "pinLine",
      "implantLine",
      "servicesRequired",
      "abutmentPreferences",
      "guidanceLevel",
      "flapBeRaised",
      "densahBursUsed",
      "densahBurGuideDesired",
    ];

    return requiredFields.includes(fieldName);
  };

  const handleServiceMultiFieldSave = async (value, fieldKey, checked) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const serviceDetails = surgicalOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      dispatch(
        setSurgicalOrderDetails({
          ...surgicalOrderDetails,
          caseDetails: {
            ...(surgicalOrderDetails?.caseDetails ?? {}),
            services: [
              ...(surgicalOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [fieldKey]: value },
            ],
          },
        })
      );
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: surgicalOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
    }

    if (checked) {
      saveOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    } else {
      deleteOrderServiceMultiField({
        accessToken,
        _id: orderByData?._id,
        serviceObjId: selectedService?.serviceObjId,
        type: fieldKey,
        optionstring: value,
      });
    }
  };

  const handleFieldChange = (value, name, isMulti = false, checked = false, subOption = false) => {
    setRestorativeTypeSelected((prev) => {
      let actualValue = value;

      if (isMulti) {
        if (checked) {
          actualValue = [...(prev?.[selectedService?.serviceObjId]?.[name] ?? []), value];
        } else {
          actualValue = (prev?.[selectedService?.serviceObjId]?.[name] ?? [])?.filter(
            (item) => item !== value
          );
        }
      }

      if (name === "additionalGuideOptions") {
        if (prev?.[selectedService?.serviceObjId]?.[name]?.[value] !== undefined) {
          delete prev?.[selectedService?.serviceObjId]?.[name]?.[value];
          return prev;
        } else {
          return {
            ...prev,
            [selectedService?.serviceObjId]: {
              ...(prev?.[selectedService?.serviceObjId] ?? {}),
              [name]: {
                ...(prev?.[selectedService?.serviceObjId]?.[name] ?? {}),
                [value]: "",
              },
            },
          };
        }
      } else if (name === "stackableGuideOptionsSubOption") {
        return {
          ...prev,
          [selectedService?.serviceObjId]: {
            ...(prev?.[selectedService?.serviceObjId] ?? {}),
            additionalGuideOptions: {
              ...(prev?.[selectedService?.serviceObjId]?.additionalGuideOptions ?? {}),
              [value]: subOption,
            },
          },
        };
      } else {
        return {
          ...prev,
          [selectedService?.serviceObjId]: {
            ...(prev?.[selectedService?.serviceObjId] ?? {}),
            [name]: actualValue,
          },
        };
      }
    });
  };

  const validateTextField = (value) => {
    return value.trim().length > 0;
  };

  const getRestrictedTeeth = (serviceObjId, key) => {
    const restrictedTeethData = [];
    Object.keys(restorativeTypeSelected)
      .filter((d) => d !== serviceObjId)
      .forEach((d) => {
        restrictedTeethData.push(...(restorativeTypeSelected?.[d]?.[key] ?? []));
      });
    return restrictedTeethData;
  };

  const submitCaseDetailsForm = async () => {
    submitOrderData({
      numberSystem: notation,
      hasMissingTeeth: hasMissingTeeth,
      teethToExtract: teethToExtract,
      anyTeethMobile: anyTeethMobile,
      missingTeethSelectedTeeth: missingTeethSelectedTeeth,
      teethToExtractSelectedTeeth: teethToExtractSelectedTeeth,
      anyTeethMobileSelectedTeeth: anyTeethMobileSelectedTeeth,
      services: Object.keys(restorativeTypeSelected)?.map((key) => ({
        ...restorativeTypeSelected[key],
        serviceObjId: key,
      })),
    });
    setActiveTab(ORDER_STAGE.UPLOAD_FILES);
  };

  const deleteService = async (service) => {
    setRestorativeTypeSelected((prev) => {
      const copy = JSON.parse(JSON.stringify(prev));
      delete copy[service?.serviceObjId];
      return copy;
    });

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    deleteOrderServiceDetails({
      accessToken,
      _id: orderByData?._id,
      serviceObjId: service?.serviceObjId,
    });
  };

  const prepareServicesElements = useMemo(() => {
    if (selectedServiceCategory?.catservices?.length) {
      const arrayOfCards = [];
      // Create HTML elements based on servicesData
      selectedServiceCategory?.catservices?.map((eachService) =>
        arrayOfCards.push(
          <QuickOrderCard
            selectedIcon={
              restorativeTypeSelected.hasOwnProperty(eachService?.serviceObjId) &&
              Object.keys(restorativeTypeSelected[eachService?.serviceObjId] ?? {})?.length > 0
            }
            handleSelectedIcon={() => deleteService(eachService)}
            id={categoryObjId}
            active={selectedService?.serviceObjId === eachService?.serviceObjId}
            image={eachService?.serviceIcon}
            bgColor={"#171818"}
            textColor={"gray"}
            title={eachService?.serviceName}
            serviceDescription={eachService?.serviceDescription}
            clickHandler={() => setSelectedService(eachService)}
          />
        )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  }, [
    categoryObjId,
    restorativeTypeSelected,
    selectedService?.serviceObjId,
    selectedServiceCategory?.catservices,
  ]);

  const getGuideHelperCards = (type) => {
    const arrayOfCards = [];
    if (type === 1) {
      // Create HTML elements based on servicesData
      [Option1Image01, Option1Image02, Option1Image03, Option1Image04, Option1Image05]?.map(
        (eachOption) =>
          arrayOfCards.push(
            <QuickOrderCard
              id={categoryObjId}
              image={eachOption}
              bgColor={"#171818"}
              textColor={"gray"}
              title={""}
              serviceDescription={""}
            />
          )
      );
      return arrayOfCards;
    } else {
      [Option2Image01, Option2Image02, Option2Image03, Option2Image04, Option2Image05]?.map(
        (eachOption) =>
          arrayOfCards.push(
            <QuickOrderCard
              id={categoryObjId}
              image={eachOption}
              bgColor={"#171818"}
              textColor={"gray"}
              title={""}
              serviceDescription={""}
            />
          )
      );
      return arrayOfCards;
    }
    return [];
    // Return null if servicesData is empty or null
  };

  const handleMissingTeethSelection = async (value) => {
    console.log("value", value);
    const actualMissingTeeth = structuredClone(missingTeethSelectedTeeth);
    let result = value(actualMissingTeeth || []);
    setMissingTeethSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualMissingTeeth?.length) {
      teethNo = findExtraElement(actualMissingTeeth, result);
      saveOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualMissingTeeth?.length) {
      teethNo = findExtraElement(result, actualMissingTeeth);
      deleteOrderMissingTeeth({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleAnyTeethMobileSelection = async (value) => {
    console.log("value", value);
    const actualAnyTeethMobile = structuredClone(anyTeethMobileSelectedTeeth);
    let result = value(actualAnyTeethMobile || []);
    setAnyTeethMobileSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualAnyTeethMobile?.length) {
      teethNo = findExtraElement(actualAnyTeethMobile, result);
      saveOrderAnyTeethMobile({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualAnyTeethMobile?.length) {
      teethNo = findExtraElement(result, actualAnyTeethMobile);
      deleteOrderAnyTeethMobile({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleTeethToExtractSelection = async (value) => {
    console.log("value", value);
    const actualTeethToExtract = structuredClone(teethToExtractSelectedTeeth);
    let result = value(actualTeethToExtract || []);
    setTeethToExtractSelectedTeeth(result);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    let teethNo;
    if (result?.length > actualTeethToExtract?.length) {
      teethNo = findExtraElement(actualTeethToExtract, result);
      saveOrderTeethToExtract({ accessToken, _id: orderByData?._id, teethno: teethNo });
    } else if (result?.length < actualTeethToExtract?.length) {
      teethNo = findExtraElement(result, actualTeethToExtract);
      deleteOrderTeethToExtract({ accessToken, _id: orderByData?._id, teethno: teethNo });
    }
  };

  const handleAdditionalGuideOptionsSave = async (option, position) => {
    if (
      option &&
      !(
        restorativeTypeSelected?.[selectedService?.serviceObjId]?.additionalGuideOptions?.[
          option
        ] ||
        restorativeTypeSelected?.[selectedService?.serviceObjId]?.additionalGuideOptions?.[
          option
        ] === ""
      )
    ) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      saveOrderAdditionalGuideOptions({
        accessToken,
        _id: orderByData?._id,
        position: position,
        option: option,
        serviceObjId: selectedService?.serviceObjId,
      });
    } else if (
      option &&
      (restorativeTypeSelected?.[selectedService?.serviceObjId]?.additionalGuideOptions?.[option] ||
        restorativeTypeSelected?.[selectedService?.serviceObjId]?.additionalGuideOptions?.[
          option
        ] === "")
    ) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      updateOrderAdditionalGuideOptions({
        accessToken,
        _id: orderByData?._id,
        position: position,
        option: option,
        serviceObjId: selectedService?.serviceObjId,
      });
    }
  };

  const handleServiceTeethSave = async (value, teethKey) => {
    console.log("value", value);
    const actualTeethMapping = structuredClone(
      restorativeTypeSelected[selectedService?.serviceObjId]?.[teethKey] ?? []
    );
    console.log("actualTeethMapping", actualTeethMapping);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL,
      },
    });

    const serviceDetails = surgicalOrderDetails?.caseDetails?.services?.find(
      (d) => d?.serviceObjId === selectedService?.serviceObjId
    );

    console.log("serviceDetails", serviceDetails);

    if (!serviceDetails?.serviceObjId) {
      await saveOrderServiceDetails({
        accessToken: accessToken,
        _id: surgicalOrderDetails?._id,
        serviceObjId: selectedService?.serviceObjId,
      });
      dispatch(
        setSurgicalOrderDetails({
          ...surgicalOrderDetails,
          caseDetails: {
            ...(surgicalOrderDetails?.caseDetails ?? {}),
            services: [
              ...(surgicalOrderDetails?.caseDetails?.services ?? []),
              { serviceObjId: selectedService?.serviceObjId, [teethKey]: value },
            ],
          },
        })
      );
    }

    if (value?.length > actualTeethMapping?.length) {
      const teethNo = findExtraElement(actualTeethMapping, value);
      saveOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    } else if (value?.length < actualTeethMapping?.length) {
      const teethNo = findExtraElement(value, actualTeethMapping);
      deleteOrderServiceTeeth({
        accessToken,
        _id: orderByData?._id,
        teethno: teethNo,
        serviceObjId: selectedService?.serviceObjId,
        type: SERVICE_TEETH_MAPPING?.[teethKey],
      });
    }
  };

  return (
    <div className="">
      <div className="w-10/12">
        <div className="text-white flex flex-col mb-4">
          <p className="font-medium text-[17px] text-white mb-2">
            What tooth numbering system do you prefer?
            <span className="text-red-500 ml-1">*</span>
          </p>
          <div className="flex">
            <div className={classes.radioButtons}>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="UTN"
                  checked={notation === "UTN"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                UTN
              </label>
              <label className="text-white">
                <input
                  style={{ marginRight: "10px" }}
                  type="radio"
                  value="FDI"
                  checked={notation === "FDI"}
                  onChange={handleNotationChange}
                  className="text-white"
                />
                FDI
              </label>
            </div>
          </div>
          <p className="text-[16px] text-[#777] mb-8">
            The Universal tooth numbering (UTN) system (1-32) and Fédération Dentaire Internationale
            (FDI) system (11-48) are both internationally recognised numbering systems used in
            different parts of the world. Please select a preference that will then apply to all the
            tooth charts in this form.
          </p>
        </div>
        <div className="text-white flex flex-col">
          <p className="font-medium text-[17px] text-white mb-2">
            Please advise us if the patient has any missing teeth
            {isFieldRequired("hasMissingTeeth") && <span className="text-red-500 ml-1">*</span>}
          </p>
          <div className="flex">
            <div className="flex items-center mr-5">
              <CircularCheckbox
                value="Yes"
                checked={hasMissingTeeth === "Yes"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px] my-3">The patient has missing teeth</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={hasMissingTeeth === "No"}
                onChange={handleHasMissingTeethChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">The patient has no missing teeth</p>
            </div>
          </div>
          {hasMissingTeeth === "Yes" && (
            <div className="mt-4">
              <p className="text-white text-[16px] mb-2">
                Please select missing teeth
                {isFieldRequired("missingTeethSelectedTeeth") && (
                  <span className="text-red-500">*</span>
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={missingTeethSelectedTeeth}
                setSelectedTeeth={handleMissingTeethSelection}
                defaultNotation={notation}
                allowBulkTeethSelection={true}
                key={`missingTeethSelectedTeeth${notation}`}
                notationVisible={false}
              />
            </div>
          )}
        </div>
        <p className="text-[16px] text-[#777] mt-8">
          If there are any teeth you intend to extract, these extractions need to be digitally
          simulated before surgical/endodontic guides or regenerative meshes are requested. Please
          advise if you intend to extract ANY teeth BEFORE the date of surgery. Planned extractions
          on the day of surgery DO NOT need to be charted but may be advised in Additional
          Instructions, ONLY when regenerative meshes are requested. It is also extremely important
          that NO TEETH are restored in any manner AFTER intraoral scans have been obtained for
          surgical/endodontic guides or regenerative meshes. ALL restorations must be completed
          before intraoral scans are obtained or must be scheduled only after intended surgery.
        </p>
        <div className="text-white flex mb-8 mt-3">
          Do you intend to extract any teeth?
          {isFieldRequired("teethToExtract") && <span className="text-red-500 ml-1">*</span>}
          <div className="flex items-center mx-3">
            <CircularCheckbox
              value="Yes"
              checked={teethToExtract === "Yes"}
              onChange={handleIntendToExtractTeethCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">Yes</p>
          </div>
          <div className="flex items-center">
            <CircularCheckbox
              value="No"
              checked={teethToExtract === "No"}
              onChange={handleIntendToExtractTeethCheckboxChange}
              borderColor={"#FF8503"}
              backgroundColor={"#FF8503"}
            />
            <p className="text-white text-[16px]">No</p>
          </div>
        </div>
        {teethToExtract === "Yes" && (
          <div className="mx-5">
            <p className="text-white text-[17px] mb-2">
              Please select any teeth that you intend to extract
              {isFieldRequired("teethToExtractSelectedTeeth") && (
                <span className="text-red-500 ml-1">*</span>
              )}
            </p>
            <div className="">
              <TeethV2
                allTeethData={[]}
                selectedTeeth={teethToExtractSelectedTeeth}
                setSelectedTeeth={handleTeethToExtractSelection}
                defaultNotation={notation}
                key={`teethToExtractSelectedTeeth${notation}`}
                notationVisible={false}
                missingTeeth={missingTeethSelectedTeeth}
              />
            </div>
          </div>
        )}
        <div className="mb-8">
          <p className="text-[16px] text-[#777] mt-8">
            Mobile teeth can affect the fit of a guide. We will attempt to avoid designing a guide
            that sits on any teeth that you identify as mobile. You could also consider extracting
            all mobile teeth prior to obtaining intraoral scans. Alternatively, you could consider
            splinting mobile teeth before intraoral scans are obtained.
          </p>
          <div className="text-white flex mt-3">
            Are any of the remaining teeth mobile?
            {isFieldRequired("anyTeethMobile") && <span className="text-red-500 ml-1">*</span>}
            <div className="flex items-center mx-3">
              <CircularCheckbox
                value="Yes"
                checked={anyTeethMobile === "Yes"}
                onChange={handleAnyTeethMobileCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">Yes</p>
            </div>
            <div className="flex items-center">
              <CircularCheckbox
                value="No"
                checked={anyTeethMobile === "No"}
                onChange={handleAnyTeethMobileCheckboxChange}
                borderColor={"#FF8503"}
                backgroundColor={"#FF8503"}
              />
              <p className="text-white text-[16px]">No</p>
            </div>
          </div>
          {anyTeethMobile === "Yes" && (
            <div className="mx-5">
              <p className="text-white text-[16px] mb-2">
                Please select remaining mobile teeth
                {isFieldRequired("anyTeethMobileSelectedTeeth") && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </p>
              <TeethV2
                allTeethData={[]}
                selectedTeeth={anyTeethMobileSelectedTeeth}
                setSelectedTeeth={handleAnyTeethMobileSelection}
                defaultNotation={notation}
                key={`anyTeethMobileSelectedTeeth${notation}`}
                notationVisible={false}
                missingTeeth={[...missingTeethSelectedTeeth, ...teethToExtractSelectedTeeth]}
              />
            </div>
          )}
        </div>
        <div className="mb-3 mt-6">
          <p className="font-medium text-[24px] text-white mb-4">Surgical Guide Service Type</p>
          <p className="text-[16px] text-[#777] my-3">
            Please click a surgical guide service type below and choose the teeth that apply for
            that service type in the chart that appears.{" "}
            <span className="!text-white">
              Multiple surgical guide service types can be selected and multiple teeth can be
              selected on each service type's chart.
            </span>{" "}
            If you prefer not to use our charting system, you can also choose to only provide
            written instructions in the Additional Instructions column below.
          </p>
          <p className="text-[17px] text-[#777] mb-8">
            Please choose your surgical guide case type correctly and then choose the type of
            surgical guide you require.
          </p>
          {selectedServiceCategory?._id && Object.keys(restorativeTypeSelected).length !== "" && (
            <>
              <div className="CAROUSEL mb-8">
                <div className="md:block hidden">
                  <CustomCarousel
                    rows={1}
                    cols={6}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={6}
                  />
                </div>
                <div className="md:hidden block">
                  <CustomCarousel
                    rows={1}
                    cols={2}
                    gapY={20}
                    gapX={20}
                    completeSliders={prepareServicesElements}
                    numberOfCardsShownInSingleSlide={2}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {selectedService?.serviceObjId && (
          <>
            {[
              "Single or multiple single implant surgical guide",
              "Bone regeneration surgical mesh",
              "Implant bridge surgical guide",
              "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
              "Soft tissue based surgical guide for All on X",
              "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
            ].includes(selectedService?.serviceName) && (
              <div>
                <p className="font-medium text-[24px] text-white mt-4 mb-2">
                  Specific Guide Type
                  {isFieldRequired("guideType") && <span className="text-red-500 ml-1">*</span>}
                </p>
                {["Implant bridge surgical guide"].includes(selectedService?.serviceName) && (
                  <p className="text-[17px] text-[#777] mb-8">
                    Most situations in this category are tooth supported guides. Occasionally, we
                    encounter guides that are part tooth supported and part mucosa supported, for
                    eg. distal extension guides. Please feel free to select both options if
                    required.
                  </p>
                )}
                {[
                  "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                ].includes(selectedService?.serviceName) && (
                  <p className="text-[17px] text-[#777] mb-8">
                    We offer two options with our stackable guides. Our Option 1 is a routine set of
                    stackable guides. Option 2 includes an additional half arch drilling guide that
                    allows you to retain teeth on one side of the arch in either or both jaws. This
                    allows an additional set of interim mid surgery intraoral scans that enables
                    occlusal alignment with preoperative intraoral scans AND final postoperative
                    intraoral scans. A stackable temporary prothesis is always part of both options
                    as we always design our restorative wax ups in CAD software to guide implant
                    positions in any case. A temporary prosthesis is an automatic outcome of this
                    design.
                  </p>
                )}
                <div
                  className={`grid md:grid-cols-${
                    [
                      "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                      "Bone regeneration surgical mesh",
                    ].includes(selectedService?.serviceName)
                      ? 1
                      : 3
                  } grid-cols-1 gap-x-8`}
                >
                  {[
                    "Single or multiple single implant surgical guide",
                    "Implant bridge surgical guide",
                  ].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={GUIDE_TYPE.TOOTH_SUPPORTED}
                        checked={(
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ?? []
                        ).includes(GUIDE_TYPE.TOOTH_SUPPORTED)}
                        onChange={(e) =>
                          handleFieldChange(
                            e.target.value,
                            "guideType",
                            true,
                            !(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ??
                              []
                            ).includes(GUIDE_TYPE.TOOTH_SUPPORTED)
                          )
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{GUIDE_TYPE.TOOTH_SUPPORTED}</p>
                    </div>
                  )}
                  {["Bone regeneration surgical mesh"].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={GUIDE_TYPE.BONE_SUPPORTED}
                        checked={(
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ?? []
                        ).includes(GUIDE_TYPE.BONE_SUPPORTED)}
                        onChange={(e) =>
                          handleFieldChange(
                            e.target.value,
                            "guideType",
                            true,
                            !(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ??
                              []
                            ).includes(GUIDE_TYPE.BONE_SUPPORTED)
                          )
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{GUIDE_TYPE.BONE_SUPPORTED}</p>
                    </div>
                  )}
                  {[
                    "Implant bridge surgical guide",
                    "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
                    "Soft tissue based surgical guide for All on X",
                  ].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={GUIDE_TYPE.MUCOSA_SUPPORTED}
                        checked={(
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ?? []
                        ).includes(GUIDE_TYPE.MUCOSA_SUPPORTED)}
                        onChange={(e) =>
                          handleFieldChange(
                            e.target.value,
                            "guideType",
                            true,
                            !(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ??
                              []
                            ).includes(GUIDE_TYPE.MUCOSA_SUPPORTED)
                          )
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{GUIDE_TYPE.MUCOSA_SUPPORTED}</p>
                    </div>
                  )}
                  {[
                    "Soft tissue based surgical guide for Locators/Ball attachments or Bar",
                    "Soft tissue based surgical guide for All on X",
                  ].includes(selectedService?.serviceName) && (
                    <div className="flex items-center">
                      <CircularCheckbox
                        value={GUIDE_TYPE.BONE_SUPPORTED}
                        checked={(
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ?? []
                        ).includes(GUIDE_TYPE.BONE_SUPPORTED)}
                        onChange={(e) =>
                          handleFieldChange(
                            e.target.value,
                            "guideType",
                            true,
                            !(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ??
                              []
                            ).includes(GUIDE_TYPE.BONE_SUPPORTED)
                          )
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">{GUIDE_TYPE.BONE_SUPPORTED}</p>
                    </div>
                  )}
                  {[
                    "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                  ].includes(selectedService?.serviceName) && (
                    <>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={GUIDE_TYPE.STACKABLE_GUIDE1}
                            checked={(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ??
                              []
                            ).includes(GUIDE_TYPE.STACKABLE_GUIDE1)}
                            onChange={(e) =>
                              handleFieldChange(
                                e.target.value,
                                "guideType",
                                true,
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.guideType ?? []
                                ).includes(GUIDE_TYPE.STACKABLE_GUIDE1)
                              )
                            }
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            Stackable guides Option 1 (Tooth borne pin and base placement guide used
                            prior to extractions + Base guide with or without bone reduction +
                            Implant placement drilling (pilot or fully guided) guide AND Guided
                            stackable temporary printable prosthesis)
                          </p>
                        </div>
                        <div className="CAROUSEL mb-8 mt-4">
                          <div className="md:block hidden">
                            <CustomCarousel
                              rows={1}
                              cols={6}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(1)}
                              numberOfCardsShownInSingleSlide={6}
                            />
                          </div>
                          <div className="md:hidden block">
                            <CustomCarousel
                              rows={1}
                              cols={2}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(1)}
                              numberOfCardsShownInSingleSlide={2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={GUIDE_TYPE.STACKABLE_GUIDE2}
                            checked={(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ??
                              []
                            ).includes(GUIDE_TYPE.STACKABLE_GUIDE2)}
                            onChange={(e) =>
                              handleFieldChange(
                                e.target.value,
                                "guideType",
                                true,
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.guideType ?? []
                                ).includes(GUIDE_TYPE.STACKABLE_GUIDE2)
                              )
                            }
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            Stackable guides Option 2 (Tooth borne pin and base placement guide used
                            prior to extractions + Base guide with or without bone reduction +
                            Implant placement drilling guide (pilot or fully guided) for half arch +
                            Implant placement drilling (pilot or fully guided) guide for full arch
                            AND Guided stackable temporary printable prosthesis)
                          </p>
                        </div>
                        <div className="CAROUSEL mb-8 mt-4">
                          <div className="md:block hidden">
                            <CustomCarousel
                              rows={1}
                              cols={6}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(2)}
                              numberOfCardsShownInSingleSlide={6}
                            />
                          </div>
                          <div className="md:hidden block">
                            <CustomCarousel
                              rows={1}
                              cols={2}
                              gapY={20}
                              gapX={20}
                              completeSliders={getGuideHelperCards(2)}
                              numberOfCardsShownInSingleSlide={2}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {((restorativeTypeSelected?.[selectedService?.serviceObjId]?.guideType ?? []) ===
                  GUIDE_TYPE.BONE_SUPPORTED ||
                  [
                    "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                  ].includes(selectedService?.serviceName)) && (
                  <div className="text-white mt-5">
                    <div className="flex">
                      If bone reduction is necessary and you have not requested it, can this be
                      incorporated into the plan?{" "}
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value="Yes"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.boneReductionInPlan === "Yes"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "boneReductionInPlan")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Yes</p>
                      </div>
                      <div className="flex items-center">
                        <CircularCheckbox
                          value="No"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.boneReductionInPlan === "No"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "boneReductionInPlan")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">No</p>
                      </div>
                    </div>
                    <p className="text-[#777]">
                      (If the Yes option is selected, a bone reduction guide will automatically be
                      provided as one of the stackable guides.)
                    </p>
                  </div>
                )}
              </div>
            )}
            {restorativeTypeSelected && (
              <div className="mt-10 mb-7">
                <p className="text-white text-[16px] mb-2">
                  {selectedService?.serviceName === "Bone regeneration surgical mesh"
                    ? "Please select grafting site(s) where the mesh is required"
                    : selectedService?.serviceName === "Endodontic access or microsurgery guide"
                    ? "Please select teeth for endodontic access and/or microsurgery"
                    : "Please select implant site(s)"}
                </p>
                <TeethV2
                  key={
                    selectedService?.serviceObjId +
                    restorativeTypeSelected[selectedService?.serviceObjId]?.[
                      selectedService?.serviceName === "Bone regeneration surgical mesh"
                        ? "graftingSiteTeeth"
                        : selectedService?.serviceName === "Endodontic access or microsurgery guide"
                        ? "endodonticAccessTeeth"
                        : "implantSiteTeeth"
                    ]?.length +
                    notation
                  }
                  allTeethData={[]}
                  disabledTeeths={[
                    ...getRestrictedTeeth(selectedService?.serviceObjId, "graftingSiteTeeth"),
                    ...getRestrictedTeeth(selectedService?.serviceObjId, "endodonticAccessTeeth"),
                    ...getRestrictedTeeth(selectedService?.serviceObjId, "implantSiteTeeth"),
                  ]}
                  disabledTeethMessage={"Tooth is not selectable"}
                  restrictedTeethMessage={"This tooth is already selected in other case type"}
                  selectedTeeth={
                    restorativeTypeSelected[selectedService?.serviceObjId]?.[
                      selectedService?.serviceName === "Bone regeneration surgical mesh"
                        ? "graftingSiteTeeth"
                        : selectedService?.serviceName === "Endodontic access or microsurgery guide"
                        ? "endodonticAccessTeeth"
                        : "implantSiteTeeth"
                    ] || []
                  }
                  setSelectedTeeth={(value) => {
                    let result = value(
                      restorativeTypeSelected[selectedService?.serviceObjId]?.[
                        selectedService?.serviceName === "Bone regeneration surgical mesh"
                          ? "graftingSiteTeeth"
                          : selectedService?.serviceName ===
                            "Endodontic access or microsurgery guide"
                          ? "endodonticAccessTeeth"
                          : "implantSiteTeeth"
                      ] || []
                    );
                    handleFieldChange(
                      result,
                      selectedService?.serviceName === "Bone regeneration surgical mesh"
                        ? "graftingSiteTeeth"
                        : selectedService?.serviceName === "Endodontic access or microsurgery guide"
                        ? "endodonticAccessTeeth"
                        : "implantSiteTeeth"
                    );
                    handleServiceTeethSave(
                      result,
                      selectedService?.serviceName === "Bone regeneration surgical mesh"
                        ? "graftingSiteTeeth"
                        : selectedService?.serviceName === "Endodontic access or microsurgery guide"
                        ? "endodonticAccessTeeth"
                        : "implantSiteTeeth"
                    );
                  }}
                  defaultNotation={notation}
                  notationVisible={false}
                  missingTeeth={[...missingTeethSelectedTeeth, ...teethToExtractSelectedTeeth]}
                  missingTeethSelectable={true}
                  otherToothRestrictedMessage="Tooth is not selectable"
                />
              </div>
            )}
            {[
              "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
            ].includes(selectedService?.serviceName) && (
              <>
                <div>
                  <p className="font-medium text-white mt-6 mb-2">
                    What additional stackable guide options are desired? <small>(Optional)</small>
                  </p>
                  <div className="ml-6">
                    <div className="grid md:grid-rows-3 grid-rows-1  gap-x-8 gap-y-2 text-[16px] text-white">
                      {stackableGuideOptionsList1.map((d) => {
                        return (
                          <div className="flex items-center">
                            <CircularCheckbox
                              value={d}
                              checked={
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.additionalGuideOptions?.[d] ||
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.additionalGuideOptions?.[d] === ""
                              }
                              onChange={(e) => {
                                handleFieldChange(e.target.value, "additionalGuideOptions");
                                handleAdditionalGuideOptionsSave(d, "");
                              }}
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <div className="text-white text-[16px] flex gap-10">
                              {d}{" "}
                              {(restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.additionalGuideOptions?.[d] ||
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.additionalGuideOptions?.[d] === "") && (
                                <div className="flex gap-6">
                                  {Object.values(ARCHES_TO_TREAT)?.map((arches) => (
                                    <div className="flex items-center">
                                      <CircularCheckbox
                                        value={arches}
                                        checked={
                                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                                            ?.additionalGuideOptions?.[d] === arches
                                        }
                                        onChange={(e) => {
                                          handleFieldChange(
                                            d,
                                            "stackableGuideOptionsSubOption",
                                            false,
                                            false,
                                            e.target.value
                                          );
                                          handleAdditionalGuideOptionsSave(d, e.target.value);
                                        }}
                                        borderColor={"#FF8503"}
                                        backgroundColor={"#FF8503"}
                                      />
                                      <p className="text-white text-[16px]">{arches}</p>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="text-white flex flex-col my-6">
                  If no provisional denture has been selected, is a conversion denture available?
                  Dentures are highly recommended as back up provisional options and for recording
                  vertical dimension and bite for photogrammetry cases
                  <div className="flex">
                    <div className="flex items-center mr-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.conversionDentureAvailable === "Yes"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "conversionDentureAvailable")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.conversionDentureAvailable === "No"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "conversionDentureAvailable")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {["Soft tissue based surgical guide for All on X or Bar overdenture"].includes(
              selectedService?.serviceName
            ) && (
              <div className="mb-8">
                <p className="font-medium text-white mt-6 mb-4">
                  What additional guide options are desired?{" "}
                  <small>(Optional at additional cost)</small>
                </p>
                <div className="ml-6">
                  <div className="grid md:grid-rows-3 grid-rows-1  gap-x-8 gap-y-2 text-[16px] text-white">
                    {stackableGuideOptionsList2.map((d) => {
                      return (
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={d}
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.additionalGuideOptions?.[d] ||
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.additionalGuideOptions?.[d] === ""
                            }
                            onChange={(e) => {
                              handleFieldChange(e.target.value, "additionalGuideOptions");
                              handleAdditionalGuideOptionsSave(d, "");
                            }}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <div className="text-white text-[16px] flex gap-10">
                            {d}{" "}
                            {(restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.additionalGuideOptions?.[d] ||
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.additionalGuideOptions?.[d] === "") && (
                              <div className="flex gap-6">
                                <div className="flex items-center">
                                  <CircularCheckbox
                                    value={ARCHES_TO_TREAT.UPPER}
                                    checked={
                                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                                        ?.additionalGuideOptions?.[d] === ARCHES_TO_TREAT.UPPER
                                    }
                                    onChange={(e) => {
                                      handleFieldChange(
                                        d,
                                        "stackableGuideOptionsSubOption",
                                        false,
                                        false,
                                        e.target.value
                                      );
                                      handleAdditionalGuideOptionsSave(d, e.target.value);
                                    }}
                                    borderColor={"#FF8503"}
                                    backgroundColor={"#FF8503"}
                                  />
                                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.UPPER}</p>
                                </div>
                                <div className="flex items-center">
                                  <CircularCheckbox
                                    value={ARCHES_TO_TREAT.LOWER}
                                    checked={
                                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                                        ?.additionalGuideOptions?.[d] === ARCHES_TO_TREAT.LOWER
                                    }
                                    onChange={(e) => {
                                      handleFieldChange(
                                        d,
                                        "stackableGuideOptionsSubOption",
                                        false,
                                        false,
                                        e.target.value
                                      );
                                      handleAdditionalGuideOptionsSave(d, e.target.value);
                                    }}
                                    borderColor={"#FF8503"}
                                    backgroundColor={"#FF8503"}
                                  />
                                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.LOWER}</p>
                                </div>
                                <div className="flex items-center">
                                  <CircularCheckbox
                                    value={ARCHES_TO_TREAT.BOTH}
                                    checked={
                                      restorativeTypeSelected?.[selectedService?.serviceObjId]
                                        ?.additionalGuideOptions?.[d] === ARCHES_TO_TREAT.BOTH
                                    }
                                    onChange={(e) => {
                                      handleFieldChange(
                                        d,
                                        "stackableGuideOptionsSubOption",
                                        false,
                                        false,
                                        e.target.value
                                      );
                                      handleAdditionalGuideOptionsSave(d, e.target.value);
                                    }}
                                    borderColor={"#FF8503"}
                                    backgroundColor={"#FF8503"}
                                  />
                                  <p className="text-white text-[16px]">{ARCHES_TO_TREAT.BOTH}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            {[
              "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
              "Soft tissue based surgical guide for All on X",
            ].includes(selectedService?.serviceName) &&
              !restorativeTypeSelected?.[selectedService?.serviceObjId]?.additionalGuideOptions?.[
                "Guided temporary Prosthesis"
              ] &&
              restorativeTypeSelected?.[selectedService?.serviceObjId]
                ?.conversionDentureAvailable === "No" && (
                <div className="mb-3">
                  <div className="text-white flex mb-7">
                    A digital conversion denture can also be provided. Is this required?
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.digitalConversionDenture === "Yes"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "digitalConversionDenture")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.digitalConversionDenture === "No"
                        }
                        onChange={(e) =>
                          handleFieldChange(e.target.value, "digitalConversionDenture")
                        }
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                  <div className="text-white flex flex-col">
                    <TextAreaInputField
                      labelColor={"#fff"}
                      labelText={"Please indicate abutment preferences"}
                      errorMessage={"This field is required"}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("abutmentPreferences")}
                      htmlFor={"abutmentPreferences"}
                      name={"abutmentPreferences"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.abutmentPreferences ?? ""
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateTextField}
                      rows={4}
                      cols={5}
                    />
                  </div>
                </div>
              )}
            <div className=" flex md:flex-row flex-col gap-8 mb-5">
              <DateInputFieldV2
                labelColor={"#777"}
                labelText={"Surgery Date"}
                errorMessage={""}
                min={moment(new Date()).format("YYYY-MM-DD")}
                placeholder={"DD-MM-YYYY"}
                isFieldRequired={isFieldRequired("surgeryDate")}
                htmlFor={"surgeryDate"}
                name={"surgeryDate"}
                value={restorativeTypeSelected?.[selectedService?.serviceObjId]?.surgeryDate}
                handleInputChange={(e) => handleFieldChange(e.target.value, "surgeryDate")}
                validationFunctionName={validateSurgeryDate}
              />
            </div>
            {preferredSoftwares?.length > 0 && (
              <div className="text-white flex flex-col mb-8">
                <p>
                  Preferred design software?
                  {isFieldRequired("preferredSoftware") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <div className="grid grid-cols-5 gap-y-3 mt-1">
                  {preferredSoftwares
                    ?.filter((d) =>
                      ![
                        "Bridge(s) with gingiva",
                        "Maryland Bridge",
                        "Veneers",
                        "Post and core crown",
                      ].includes(selectedService?.serviceName)
                        ? true
                        : !["MeditCAD", "Dentbird", "Clinux", "Hypsocad"].includes(d?.softwareName)
                    )
                    ?.map((item) => {
                      return (
                        <div className="flex items-center mx-3" key={item?.softwareObjId}>
                          <CircularCheckbox
                            value={item?.softwareName}
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.preferredSoftware === item?.softwareName
                            }
                            onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">{item?.softwareName}</p>
                        </div>
                      );
                    })}
                  <div className="flex items-center mx-3 col-span-1">
                    <CircularCheckbox
                      value="No Preferance"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.preferredSoftware === "No Preferance"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "preferredSoftware")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No Preference</p>
                  </div>
                </div>
              </div>
            )}
            {selectedService?.serviceName === "Bone regeneration surgical mesh" && (
              <p className="text-[16px] text-[#777] mt-6">
                Your choice of a preferred implant company and implant line help us to simulate your
                implant placement and determine your volumetric grafting requirements. Please
                therefore choose what you propose to actually use in this specific patient.
              </p>
            )}
            <div className="grid grid-cols-12 gap-8 mt-5">
              {selectedService?.serviceName === "Endodontic access or microsurgery guide" ? (
                <>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Preferred Guided Access drill brand"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("guidedAccessDrillBrand")}
                      htmlFor={"guidedAccessDrillBrand"}
                      name={"guidedAccessDrillBrand"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.guidedAccessDrillBrand ?? ""
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Guided Access drill shank diameter (mm)"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("drillShankDiameter")}
                      htmlFor={"drillShankDiameter"}
                      name={"drillShankDiameter"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.drillShankDiameter ?? ""
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-span-6">
                    <DropdownField
                      labelText={"Preferred Implant Company"}
                      placeholder={"Select Implant Company"}
                      name={"preferredImplantCompany"}
                      labelClass={"text-[16px] leding-4"}
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      labelColor={"#777"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.preferredImplantCompany ?? ""
                      }
                      options={implantCompanyList?.map((d) => ({ label: d, value: d })) || []}
                      isFieldRequired={isFieldRequired("preferredImplantCompany")}
                    />
                  </div>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Implant Line"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("implantLine")}
                      htmlFor={"implantLine"}
                      name={"implantLine"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.implantLine ?? ""
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                </>
              )}
            </div>
            {[
              "Bone regeneration surgical mesh",
              "Endodontic access or microsurgery guide",
            ].includes(selectedService?.serviceName) ? null : (
              <>
                <div className="grid grid-cols-12 gap-8 mt-5">
                  <div className="col-span-6">
                    <DropdownField
                      labelText={"Preferred Pin Company"}
                      placeholder={"Select Pin Company"}
                      name={"preferredPinCompany"}
                      labelClass={"text-[16px] leding-4"}
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      labelColor={"#777"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]
                          ?.preferredPinCompany ?? ""
                      }
                      options={implantCompanyList?.map((d) => ({ label: d, value: d })) || []}
                      isFieldRequired={isFieldRequired("preferredPinCompany")}
                    />
                  </div>
                  <div className="col-span-5">
                    <TextInputField
                      labelColor={"#fff"}
                      labelText={"Pin Line"}
                      errorMessage={""}
                      placeholder={"Enter here"}
                      isFieldRequired={isFieldRequired("pinLine")}
                      htmlFor={"pinLine"}
                      name={"pinLine"}
                      value={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.pinLine ?? ""
                      }
                      handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                      validationFunctionName={validateTextField}
                      margin={"0px"}
                    />
                  </div>
                </div>
                <div className="my-4">
                  <p className="font-medium text-[24px] text-white mb-4">
                    Implant Surgical Kit <span className="text-red-500">*</span>
                  </p>
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-x-8 w-10/12">
                    <div className="flex items-start">
                      <CircularCheckbox
                        value="Guided Kit"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.implantSurgicalKit === "Guided Kit"
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "implantSurgicalKit")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Guided Kit</p>
                    </div>
                    <div className="">
                      <div className="flex items-start">
                        <CircularCheckbox
                          value="Regular Kit"
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.implantSurgicalKit === "Regular Kit"
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "implantSurgicalKit")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">Regular Kit </p>
                      </div>
                      <p className="text-[16px] text-[#777] my-3">
                        (If choosing a regular kit, please ensure that you only select a pilot drill
                        guide option below)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="text-white flex mb-8">
                    Will Densah burs be used?
                    {isFieldRequired("densahBursUsed") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                    <div className="flex items-center mx-3">
                      <CircularCheckbox
                        value="Yes"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.densahBursUsed === "Yes"
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "densahBursUsed")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">Yes</p>
                    </div>
                    <div className="flex items-center">
                      <CircularCheckbox
                        value="No"
                        checked={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.densahBursUsed === "No"
                        }
                        onChange={(e) => handleFieldChange(e.target.value, "densahBursUsed")}
                        borderColor={"#FF8503"}
                        backgroundColor={"#FF8503"}
                      />
                      <p className="text-white text-[16px]">No</p>
                    </div>
                  </div>
                  {restorativeTypeSelected?.[selectedService?.serviceObjId]?.densahBursUsed ===
                    "Yes" && (
                    <>
                      <div className="text-white flex mb-2">
                        Is a Densah bur guide desired?
                        {isFieldRequired("densahBurGuideDesired") && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                        <div className="flex items-center mx-3">
                          <CircularCheckbox
                            value="Yes"
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.densahBurGuideDesired === "Yes"
                            }
                            onChange={(e) =>
                              handleFieldChange(e.target.value, "densahBurGuideDesired")
                            }
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">Yes</p>
                        </div>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value="No"
                            checked={
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.densahBurGuideDesired === "No"
                            }
                            onChange={(e) =>
                              handleFieldChange(e.target.value, "densahBurGuideDesired")
                            }
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">No</p>
                        </div>
                      </div>
                      <p className="text-[16px] text-[#777] mb-3">
                        Please choose the Densah bur guided option only if you have the Densah
                        guided surgery kit from Versah. This additional guide is also an additional
                        cost.
                      </p>
                    </>
                  )}
                </div>
                {restorativeTypeSelected?.[selectedService?.serviceObjId]?.densahBursUsed ===
                  "Yes" &&
                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.densahBurGuideDesired === "Yes" && (
                    <div className="mt-6 mb-7">
                      <p className="text-white text-[16px] mb-2">
                        Please select sites at which Densah burs will be used
                      </p>
                      <TeethV2
                        key={
                          selectedService?.serviceObjId +
                          restorativeTypeSelected?.[selectedService?.serviceObjId]?.densahBursTeeth
                            ?.length +
                          notation
                        }
                        defaultNotation={notation}
                        notationVisible={false}
                        allTeethData={[]}
                        restrictedTeeth={getRestrictedTeeth(
                          selectedService?.serviceObjId,
                          "densahBursTeeth"
                        )}
                        restrictedTeethMessage={"This tooth is already selected in other case type"}
                        selectedTeeth={
                          restorativeTypeSelected?.[selectedService?.serviceObjId]
                            ?.densahBursTeeth || []
                        }
                        setSelectedTeeth={(value) => {
                          let result = value(
                            restorativeTypeSelected[selectedService?.serviceObjId]
                              ?.densahBursTeeth || []
                          );
                          handleFieldChange(result, "densahBursTeeth");
                          handleServiceTeethSave(result, "densahBursTeeth");
                        }}
                        // defaultNotation={"FDI"}
                        // notationVisible={false}
                        missingTeeth={[
                          ...missingTeethSelectedTeeth,
                          ...teethToExtractSelectedTeeth,
                        ]}
                        screwTeethSelectable={true}
                        selectedScrewTeeth={
                          restorativeTypeSelected[selectedService?.serviceObjId]
                            ?.implantSiteTeeth || []
                        }
                        otherToothRestrictedMessage="Tooth is not selectable"
                      />
                    </div>
                  )}
              </>
            )}
            {selectedService?.serviceName === "Bone regeneration surgical mesh" ? null : (
              <div className="mb-8">
                {["Endodontic access or microsurgery guide"].includes(
                  selectedService?.serviceName
                ) && (
                  <p className="text-[16px] text-[#777] my-3">
                    Flaps MAY be raised under our endodontic access guides as they are tooth
                    supported. If using an endodontic guide for microsurgical access however, we
                    need to know if you intend to raise a flap. If a flap is being raised, the guide
                    will be designed to fit over bone rather than over mucosa. An access drill brand
                    that will be used to drill into bone will still have to be chosen even for
                    microsurgical guides. Microsurgical guides can also be effectively used for
                    intraosseous anesthesia.
                  </p>
                )}
                <div className="text-white flex mt-3">
                  Will a flap be raised?{" "}
                  {isFieldRequired("flapBeRaised") && <span className="text-red-500 ml-1">*</span>}
                  <div className="flex items-center mx-3">
                    <CircularCheckbox
                      value="Yes"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.flapBeRaised ===
                        "Yes"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "flapBeRaised")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">Yes</p>
                  </div>
                  <div className="flex items-center">
                    <CircularCheckbox
                      value="No"
                      checked={
                        restorativeTypeSelected?.[selectedService?.serviceObjId]?.flapBeRaised ===
                        "No"
                      }
                      onChange={(e) => handleFieldChange(e.target.value, "flapBeRaised")}
                      borderColor={"#FF8503"}
                      backgroundColor={"#FF8503"}
                    />
                    <p className="text-white text-[16px]">No</p>
                  </div>
                </div>
                {!["Endodontic access or microsurgery guide"].includes(
                  selectedService?.serviceName
                ) && (
                  <p className="text-[16px] text-[#777] mb-3">
                    Flaps MAY be raised only under our tooth supported guides. Flaps MAY be raised
                    sometimes with our mucosa supported guides AFTER implants have been placed.
                    Flaps are almost ALWAYS raised for our stackable guides.
                  </p>
                )}
              </div>
            )}
            {[
              "Bone regeneration surgical mesh",
              "Endodontic access or microsurgery guide",
            ].includes(selectedService?.serviceName) ? null : (
              <>
                <div className="mb-4">
                  <p className="font-medium text-[24px] text-white mt-6 mb-4">
                    Choose Level of Guidance Desired{" "}
                    {isFieldRequired("guidanceLevel") && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </p>
                  <div>
                    <div className="grid md:grid-rows-2 grid-rows-1  gap-x-8 gap-y-4 text-[16px] text-white">
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value={GUIDANCE_LEVEL.PILOR_DRILL}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.guidanceLevel === GUIDANCE_LEVEL.PILOR_DRILL
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "guidanceLevel")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{GUIDANCE_LEVEL.PILOR_DRILL}</p>
                      </div>
                      <div className="flex items-center mx-3">
                        <CircularCheckbox
                          value={GUIDANCE_LEVEL.FULLY_GUIDED}
                          checked={
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.guidanceLevel === GUIDANCE_LEVEL.FULLY_GUIDED
                          }
                          onChange={(e) => handleFieldChange(e.target.value, "guidanceLevel")}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">{GUIDANCE_LEVEL.FULLY_GUIDED}</p>
                      </div>
                    </div>
                    {!(
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.guidanceLevel
                        ?.length > 0
                    ) ? (
                      <p className="text-sm text-red-500 py-2">Select at least one</p>
                    ) : (
                      <></>
                    )}
                  </div>

                  {restorativeTypeSelected?.[selectedService?.serviceObjId]?.guidanceLevel ===
                    GUIDANCE_LEVEL.FULLY_GUIDED &&
                    restorativeTypeSelected["65bfcd82a03607278060f727"]?.length > 0 && (
                      <div className="ml-6 my-3">
                        <div className="text-white flex">
                          ● Will the prosthesis be used as a morphological template for the guide?
                          <div className="flex items-center mx-3">
                            <CircularCheckbox
                              value="Yes"
                              checked={
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.prosthesisBeUsed === "Yes"
                              }
                              onChange={(e) =>
                                handleFieldChange(e.target.value, "prosthesisBeUsed")
                              }
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <p className="text-white text-[16px]">Yes</p>
                          </div>
                          <div className="flex items-center">
                            <CircularCheckbox
                              value="No"
                              checked={
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.prosthesisBeUsed === "No"
                              }
                              onChange={(e) =>
                                handleFieldChange(e.target.value, "prosthesisBeUsed")
                              }
                              borderColor={"#FF8503"}
                              backgroundColor={"#FF8503"}
                            />
                            <p className="text-white text-[16px]">No</p>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
            {["Endodontic access or microsurgery guide"].includes(
              selectedService?.serviceName
            ) ? null : (
              <div className="">
                <p className="font-medium text-[24px] text-white mt-6">
                  Choose Services Required{" "}
                  {isFieldRequired("servicesRequired") && (
                    <span className="text-red-500 ml-1">*</span>
                  )}
                </p>
                <p className="text-[16px] text-[#777] mb-4">
                  {selectedService?.serviceName === SERVICES_REQUIRED.REGENERATION_MESH
                    ? "(Custom designed printed titanium bone regeneration surgical meshes are currently available only in specific countries. Charges for printed titanium bone regeneration surgical meshes vary depending on the country. While we do define the choice of placement screws for the mesh, we do not supply bone screws or bone graft material. Shipping charges will be additional.)"
                    : "(Printed surgical guide services are currently available only in the USA, Australia and New Zealand. Charges for printed guides vary depending on the country and all implant/pin sleeves for an implant system will be charged additionally. Shipping charges will also be additional.)"}
                </p>
                <div>
                  <div
                    className={`grid md:grid-cols-3 md:grid-rows-1 grid-cols-1  gap-x-8 gap-y-4 text-[16px] text-white mb-8`}
                  >
                    {selectedService?.serviceName === "Bone regeneration surgical mesh" ? (
                      <>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={SERVICES_REQUIRED.REGENERATION_MESH}
                            checked={(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.servicesRequired ?? []
                            ).includes(SERVICES_REQUIRED.REGENERATION_MESH)}
                            onChange={(e) => {
                              handleFieldChange(
                                e.target.value,
                                "servicesRequired",
                                true,
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.REGENERATION_MESH)
                              );
                              handleServiceMultiFieldSave(
                                e.target.value,
                                "servicesRequired",
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.REGENERATION_MESH)
                              );
                            }}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            {SERVICES_REQUIRED.REGENERATION_MESH}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={SERVICES_REQUIRED.TITANIUM_MESH}
                            checked={(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.servicesRequired ?? []
                            ).includes(SERVICES_REQUIRED.TITANIUM_MESH)}
                            onChange={(e) => {
                              handleFieldChange(
                                e.target.value,
                                "servicesRequired",
                                true,
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.TITANIUM_MESH)
                              );
                              handleServiceMultiFieldSave(
                                e.target.value,
                                "servicesRequired",
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.TITANIUM_MESH)
                              );
                            }}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            {SERVICES_REQUIRED.TITANIUM_MESH}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={SERVICES_REQUIRED.IMPLANT_TREATMENT}
                            checked={(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.servicesRequired ?? []
                            ).includes(SERVICES_REQUIRED.IMPLANT_TREATMENT)}
                            onChange={(e) => {
                              handleFieldChange(
                                e.target.value,
                                "servicesRequired",
                                true,
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.IMPLANT_TREATMENT)
                              );
                              handleServiceMultiFieldSave(
                                e.target.value,
                                "servicesRequired",
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.IMPLANT_TREATMENT)
                              );
                            }}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            {SERVICES_REQUIRED.IMPLANT_TREATMENT}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <CircularCheckbox
                            value={SERVICES_REQUIRED.SURGICAL_GUIDE}
                            checked={(
                              restorativeTypeSelected?.[selectedService?.serviceObjId]
                                ?.servicesRequired ?? []
                            ).includes(SERVICES_REQUIRED.SURGICAL_GUIDE)}
                            onChange={(e) => {
                              handleFieldChange(
                                e.target.value,
                                "servicesRequired",
                                true,
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.SURGICAL_GUIDE)
                              );
                              handleServiceMultiFieldSave(
                                e.target.value,
                                "servicesRequired",
                                !(
                                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                                    ?.servicesRequired ?? []
                                ).includes(SERVICES_REQUIRED.SURGICAL_GUIDE)
                              );
                            }}
                            borderColor={"#FF8503"}
                            backgroundColor={"#FF8503"}
                          />
                          <p className="text-white text-[16px]">
                            {SERVICES_REQUIRED.SURGICAL_GUIDE}
                          </p>
                        </div>
                      </>
                    )}
                    {![
                      "Full arch stackable surgical guides for All on X (Resin or Metal) or Overdentures",
                      "Soft tissue based surgical guide for All on X",
                      "Bone regeneration surgical mesh",
                    ].includes(selectedService?.serviceName) && (
                      <div className="flex items-center">
                        <CircularCheckbox
                          value={SERVICES_REQUIRED.ABUTMENT_PLANNING}
                          checked={(
                            restorativeTypeSelected?.[selectedService?.serviceObjId]
                              ?.servicesRequired ?? []
                          ).includes(SERVICES_REQUIRED.ABUTMENT_PLANNING)}
                          onChange={(e) => {
                            handleFieldChange(
                              e.target.value,
                              "servicesRequired",
                              true,
                              !(
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.servicesRequired ?? []
                              ).includes(SERVICES_REQUIRED.ABUTMENT_PLANNING)
                            );
                            handleServiceMultiFieldSave(
                              e.target.value,
                              "servicesRequired",
                              !(
                                restorativeTypeSelected?.[selectedService?.serviceObjId]
                                  ?.servicesRequired ?? []
                              ).includes(SERVICES_REQUIRED.ABUTMENT_PLANNING)
                            );
                          }}
                          borderColor={"#FF8503"}
                          backgroundColor={"#FF8503"}
                        />
                        <p className="text-white text-[16px]">
                          {SERVICES_REQUIRED.ABUTMENT_PLANNING}
                        </p>
                      </div>
                    )}
                  </div>
                  {!(
                    (
                      restorativeTypeSelected?.[selectedService?.serviceObjId]?.servicesRequired ??
                      []
                    ).length > 0
                  ) ? (
                    <p className="text-sm text-red-500 py-2">Select at least one</p>
                  ) : null}
                </div>
              </div>
            )}
            {[
              "Bone regeneration surgical mesh",
              "Endodontic access or microsurgery guide",
            ].includes(selectedService?.serviceName) ? null : (
              <p className="text-[16px] text-[#777] mb-8">
                Please choose the abutment planning option only if you need to see the path of
                emergence of the restorative screw in angled abutments. Screw emergence is otherwise
                routinely indicated on implant reports for all zero degree restorative solutions.
              </p>
            )}
            <div>
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Additional Instructions"}
                errorMessage={"This field is required"}
                placeholder={"Enter Additional Instructions"}
                isFieldRequired={isFieldRequired("additionalInstructions")}
                htmlFor={"additionalInstructions"}
                name={"additionalInstructions"}
                value={
                  restorativeTypeSelected?.[selectedService?.serviceObjId]
                    ?.additionalInstructions ?? ""
                }
                handleInputChange={(e) => handleFieldChange(e.target.value, e.target.name)}
                // validationFunctionName={validatePatientConcern}
                // isSubmitted={isPatientInitialFormSubmitted}
                rows={4}
                cols={5}
              />
              {restorativeTypeSelected?.[
                selectedService?.serviceObjId
              ]?.additionalInstructions?.trim()?.length > 0 ? null : (
                <p className="text-sm text-red-500">This field is required</p>
              )}
            </div>
          </>
        )}
      </div>
      <div>
        <div className="flex items-center md:justify-end gap-4 pt-4 flex-wrap">
          <button
            onClick={() => {
              setActiveTab(ORDER_STAGE.PATIENT_DETAILS);
            }}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Back
          </button>
          <button
            onClick={() => {
              submitCaseDetailsForm();
            }}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Save & Continue
          </button>
        </div>
      </div>
    </div>
  );
};
