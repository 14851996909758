/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { CategoryIconInputField, CategoryTextAreaField } from "./InputFieldsCategory";
import RangeSlider from "./RangeSlider";
import CustomRangeSlider from "./RangeSlider";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useSaveServicesMutation,
  useUpdateServicesMutation,
  useGetServicesMutation,
} from "../../services/services";
import { useDeleteFileMutation, useSaveFileMutation } from "../../services/uploadFiles";
import DropdownField from "../InputField-Comps/DropdownField";
import FileUploadComponent from "../InputField-Comps/FilePondField";
import { GetToast } from "../Toast-Comps/GetToast";
import Modal from "react-modal";
import { AiOutlineClose, AiOutlineLoading } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import {
  useDeleteSubServiceMutation,
  useSaveSubServiceMutation,
  useUpdateSubServiceMutation,
} from "../../services/util";
import { forEach } from "jszip";
import { BiLoaderAlt } from "react-icons/bi";

const InputFieldsServicesTab = ({
  closeModal,
  isEditModal,
  editingServicesRow,
  getData,
  selectedServiceCategory,
}) => {
  const [saveServices] = useSaveServicesMutation();
  const [updateServices] = useUpdateServicesMutation();
  const [saveFile] = useSaveFileMutation();

  const { getAccessTokenSilently } = useAuth0();

  // const [selectedService, setSelectedService] = useState("");
  const [serviceIcon, setServiceIcon] = useState("");
  const [serviceIconLoading, setServiceIconLoading] = useState(false);
  const [serviceCode, setServiceCode] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [sliderValue, setSliderValue] = useState([0, 100]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [sortOrder, setSortOrder] = useState("");

  const handleServiceCodeChange = (event) => {
    const { value } = event.target;
    setServiceCode(value);
  };
  const handleIconFileUpload = async (uploadedFiles) => {
    setServiceIconLoading(true);
    const file = uploadedFiles[0];
    // Access and use the uploaded files here
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    const resp = await saveFile({
      accessToken,
      file,
    });
    if (resp) {
      // debugger
      // var icons = categoryIcon;
      if (resp?.data?.url) {
        // icons.push({
        //   fileType:"image",
        //   s3Url:resp.data.url
        // })
        setServiceIcon(resp?.data?.url);
      }
    }
    setServiceIconLoading(false);

    // Perform any further operations with the files, such as saving to state, sending to the server, etc.
  };

  const handleServiceNameChange = (event) => {
    const { value } = event.target;
    setServiceName(value);
  };
  const handleServiceDescriptionChange = (event) => {
    const { value } = event.target;
    setServiceDescription(value);
  };
  const handleSortOrderChange = (event) => {
    const { value } = event.target;
    setSortOrder(value);
  };

  React.useEffect(() => {
    if (isEditModal && editingServicesRow) {
      // setSelectedService(editingServicesRow.selectedService)
      // if (editingServicesRow?.parentObjId?.length > 0) {
      //   setSelectedService(editingServicesRow.parentObjId[0]?._id);
      // }
      setServiceCode(editingServicesRow.serviceCode);
      setServiceName(editingServicesRow.serviceName);
      setSortOrder(editingServicesRow?.sortOrder);
      setServiceDescription(editingServicesRow.serviceDescription);
      setSliderValue([
        editingServicesRow.priceRangeFrom || 0,
        editingServicesRow.priceRangeTo || 100,
      ]);
    }
  }, [editingServicesRow, isEditModal]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation for each input
    // const isSelectedServiceValid = !isFieldRequired("name") || validateName(selectedService);
    // const isServiceIconValid = !isFieldRequired("name") || validateName(serviceIcon);
    const isServiceCodeValid = !isFieldRequired("serviceCode") || validateName(serviceCode);
    const isServiceNameValid = !isFieldRequired("serviceName") || validateName(serviceName);
    const isServiceDescriptionValid =
      !isFieldRequired("serviceDescription") || validateName(serviceDescription);

    // Set overall form validity
    // setIsValid(isSelectedServiceValid && isServiceIconValid && isServiceCodeValid && isServiceNameValid && isServiceDescriptionValid && isPhoneValid);

    setIsSubmitted(true);

    // if (isSelectedServiceValid && isServiceIconValid && isServiceCodeValid && isServiceNameValid && isServiceDescriptionValid && isPhoneValid) {
    if (isServiceCodeValid && isServiceNameValid && isServiceDescriptionValid) {
      // Submit the form or perform any other action
      // calling any kind of submission api here
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
        },
      });
      if (isEditModal) {
        let updatePayload = {
          accessToken,
          _id: selectedServiceCategory?._id,
          serviceObjId: editingServicesRow?.serviceObjId,
          serviceName,
          serviceCode,
          serviceDescription,
          priceRangeFrom: sliderValue[0],
          priceRangeTo: sliderValue[1],
          serviceIcon: serviceIcon,
          sortOrder: sortOrder,
          // parentObjId: selectedService ? selectedService : undefined,
        };
        const resp = await updateServices(updatePayload);

        if (resp) {
          console.log("resp", resp);
        }

        if (resp?.data?.type === 1) {
          GetToast("Service Successfully Updated !!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
      } else {
        const resp = await saveServices({
          _id: selectedServiceCategory?._id,
          accessToken,
          serviceName,
          serviceIcon,
          serviceCode,
          serviceDescription,
          priceRangeFrom: sliderValue[0],
          priceRangeTo: sliderValue[1],
          sortOrder,
        }).unwrap();
        if (resp) {
          console.log("resp", resp);
        }
        if (resp?.type === 1) {
          GetToast("Service Successfully Saved !!");
        } else if (resp?.type === 2) {
          // Show error message from response if type is 2
          GetToast(resp?.message, "error");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
      }
      await getData(accessToken);
      closeModal();
    }
  };

  const validateName = (value) => {
    // Your name validation logic goes here
    // Return true if the value is valid, false otherwise
    // For example:
    return value?.trim().length > 0;
  };

  const validateSortOrder = (value) => {
    // Your name validation logic goes here
    // Return true if the value is valid, false otherwise
    // For example:
    return Number(value) ? true : false;
  };

  const isFieldRequired = (fieldName) => {
    // Implement your logic to determine if a field is required or not
    // For example, you can have a list of required fields and check if the fieldName exists in that list
    const requiredFields = ["serviceCode", "serviceName", "serviceDescription"];
    return requiredFields.includes(fieldName);
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <ServiceIconInputField
              labelText={"Service Icon"}
              labelColor={"#777"}
              errorMessage={""}
              placeholder={"Select Service Icon"}
              handleIconFileUpload={handleIconFileUpload}
              initialFiles={
                isEditModal
                  ? [{ source: editingServicesRow?.serviceIcon, options: { type: "local" } }] ?? ""
                  : ""
              }
            />
          </div>
          <div className="relative">
            <TextInputField
              labelText={"Service Code*"}
              labelColor={"#fff"}
              errorMessage={"this is error"}
              placeholder={"Enter Service Code"}
              name={"serviceCode"}
              value={serviceCode}
              handleInputChange={handleServiceCodeChange}
              isSubmitted={isSubmitted}
              validateName={validateName}
              isFieldRequired={isFieldRequired("serviceCode")}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelText={"Service Name*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Service Name"}
              name={"serviceName"}
              value={serviceName}
              handleInputChange={handleServiceNameChange}
              isSubmitted={isSubmitted}
              validateName={validateName}
              isFieldRequired={isFieldRequired("serviceName")}
            />
          </div>
          <div className="relative">
            <CategoryTextAreaField
              labelText={"Service Description*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Your Description Here"}
              categoryTextArea={serviceDescription}
              handleInputChange={handleServiceDescriptionChange}
              isSubmitted={isSubmitted}
              validateName={validateName}
              validateCategoryTextArea={validateName}
              isFieldRequired={isFieldRequired("serviceDescription")}
            />
          </div>
          <div className="relative">
            <div className="text-[16px] leading-4 text-white mb-[20px]">Price Range</div>
            <CustomRangeSlider value={sliderValue} setValue={setSliderValue} />
            <div className="flex justify-between mb-3">
              <div
                style={{ color: "rgb(119, 119, 119)", marginTop: "10px" }}
                className="text-[16px] leading-4"
              >
                {sliderValue[0]} USD
              </div>
              <div
                style={{ color: "rgb(119, 119, 119)", marginTop: "10px" }}
                className="text-[16px] leading-4"
              >
                {sliderValue[1]} USD
              </div>
            </div>
          </div>
          <div className="relative">
            <NumberInputField
              labelText={"Sort Order*"}
              labelColor={"#777"}
              errorMessage={"this is error"}
              placeholder={"Enter Sort Order"}
              name={"sortOrder"}
              value={sortOrder}
              handleInputChange={handleSortOrderChange}
              isSubmitted={isSubmitted}
              validateName={validateSortOrder}
              isFieldRequired={isFieldRequired("sortOrder")}
            />
          </div>
        </div>

        <div className="flex items-center justify-end gap-4">
          <button
            onClick={closeModal}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={serviceIconLoading}
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl h-full"
          >
            {serviceIconLoading ? <BiLoaderAlt className="animate-spin" /> : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsServicesTab;

export function TextInputField({
  labelText,
  labelClass,
  inputClass,
  labelColor,
  errorMessage,
  placeholder,
  isFieldRequired,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateName,
}) {
  return (
    <>
      <label
        htmlFor="name"
        style={{
          color: `${isSubmitted && !validateName(name) ? "border-red-500" : ""}`,
        }}
        className={`text-[16px] text-white leading-4 ${labelClass ?? ""}`}
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500 ml-1">*</span> : ""}
      </label>

      <input
        type="text"
        id="name"
        name={name}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10  ${
          inputClass ?? ""
        } ${isSubmitted && !validateName(name) ? "border-red-500" : ""}`}
        required={isFieldRequired}
      />
      {isSubmitted && !validateName(name) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </>
  );
}
export function EmailInputField({
  labelText,
  labelColor,
  errorMessage,
  placeholder,
  email,
  handleInputChange,
  isSubmitted,
  validateEmail,
  isFieldRequired,
}) {
  return (
    <>
      <label
        htmlFor="email"
        style={{
          color: `${isSubmitted && !validateEmail(email) ? "border-red-500" : labelColor}`,
        }}
        className="text-[16px] leading-4"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500 ml-1">*</span> : ""}
      </label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10  ${
          isSubmitted && !validateEmail(email) && isFieldRequired ? "border-red-500 " : ""
        }`}
        required={isFieldRequired}
      />
      {isSubmitted && !validateEmail(email) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px]">{errorMessage}</p>
      )}
    </>
  );
}
export function NumberInputField({
  labelText,
  errorMessage,
  labelColor,
  placeholder,
  name,
  value,
  handleInputChange,
  isSubmitted,
  validateName,
  isFieldRequired,
}) {
  return (
    <>
      <label
        htmlFor="phone"
        style={{
          color: `${isSubmitted && !validateName(value) ? "border-red-500" : ""}`,
        }}
        className="text-[16px] text-white leading-4"
      >
        {labelText}
        {isFieldRequired ? <span className="text-red-500 ml-1">*</span> : ""}
      </label>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        className={`border p-2 w-full text-white bg-black text-[16px] leading-4 mt-2 mb-4 rounded-md h-10 ${
          isSubmitted && !validateName(value) ? "border-red-500" : ""
        }`}
        required={isFieldRequired}
      />
      {isSubmitted && !validateName(value) && isFieldRequired && (
        <p className="text-red-500  relative text-[15px] left-0 top-[-10px] ">{errorMessage}</p>
      )}
    </>
  );
}
export function ServiceIconInputField({
  labelText,
  labelColor,
  handleIconFileUpload,
  isEditing,
  initialFiles,
}) {
  return (
    <>
      <label htmlFor="categoryIcon" className="text-[16px] text-white leading-4 mb-2">
        {labelText}
      </label>
      <FileUploadComponent
        maxFiles={1}
        acceptedFileTypes={["image/jpeg", "image/png"]}
        onUpload={handleIconFileUpload}
        allowMultipleFiles={false}
        initialFiles={initialFiles ? [{ source: initialFiles, options: { type: "local" } }] : []}
      />
    </>
  );
}
export function ServiceAssetsInputField({
  labelText,
  labelColor,
  handleAssetsFileUpload,
  isEditing,
  initialFiles,
}) {
  return (
    <>
      <label htmlFor="categoryIcon" style={{ color: labelColor }} className="text-[16px] leading-4">
        {labelText}
      </label>
      <FileUploadComponent
        maxFiles={10}
        acceptedFileTypes={["image/jpeg", "image/png", "video/mp4", "video/quicktime"]}
        onUpload={handleAssetsFileUpload}
        allowMultipleFiles={true}
        initialFiles={
          initialFiles
            ? initialFiles?.map((d) => ({
                source: d,
                options: { type: "local" },
              }))
            : []
        }
      />
    </>
  );
}
