import { useEffect, useId, useRef, useState } from "react";
import {
  iconArray,
  navActiveCss,
  navHoverCss,
  spIconArray,
  superAdminIconArray,
} from "../../constants/NavbarRoutesArray";
import hut from "../../Assets/images/hut.svg";
import Navbar from "../../Components/Navbar-Comps/Navbar";
import { useNavigate } from "react-router-dom";
import ticket from "../../Assets/icons/ticket.svg";
import replyIcon from "../../Assets/icons/replyIcon.svg";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import TextInputField from "../../Components/InputField-Comps/TextInputField";
import TextAreaInputField from "../../Components/InputField-Comps/TextAreaInputField";
import assignedIcon from "../../Assets/icons/orderAssignedIcon.svg";
import assignSuccess from "../../Assets/icons/assignSuccess.svg";
import { LoginButton } from "../Login/Login";
import { useAuth0 } from "@auth0/auth0-react";
import greenTick from "../../Assets/images/greenTick.svg";
import redCross from "../../Assets/images/redCross.svg";
import printerIcon from "../../Assets/icons/printerIcon.svg";
import exitIcon from "../../Assets/icons/exitIcon.svg";
import zipIcon from "../../Assets/images/zipIcon.jpg";
import folderIcon from "../../Assets/images/folderIcon.jpg";
import fileIcon from "../../Assets/images/fileIcon.jpg";
import rejectedIcon from "../../Assets/icons/rejectedIcon.svg";
import { useLocation } from "react-router-dom";
import {
  useGetOrderByIdMutation,
  useGetServiceProviderOrdersMutation,
  useReadCommOrdersMutation,
  useSaveCommOrdersMutation,
  useSaveDeliveryAssetsMutation,
  useSaveNotesMutation,
  useSaveOrderDeliveryMutation,
  useUpdateOrderByIdMutation,
  useUpdateServiceProviderOrdersMutation,
} from "../../services/orders";
import { Tooltip } from "react-tooltip";
import { GetToast } from "../../Components/Toast-Comps/GetToast";
import DeliveryTracker from "../../Components/Order-Comps/DeliveryTracker";
import AttachUpload from "../../Components/Order-Comps/AttachUpload";
import ConcernCard from "../../Components/Order-Comps/ConcernCard";
import Notification from "../../Components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import OrderSummary from "../../Components/OrderSummary-Comps/OrderSummary";
import axios from "axios";
import { setCurrencyRates } from "../../reducers/SPServiceDetailsReducer";
import ImageUploadV2 from "../../Components/Order-Comps/ImageUploadV2";
import { useSaveFileMutation } from "../../services/uploadFiles";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner";
import OrderCommunication from "../Orders/OrderCommunication";
import ImageUpload from "../../Components/Order-Comps/ImageUpload";
import moment from "moment";
import { FaFile } from "react-icons/fa6";
import OrderNotesHistory from "../Orders/OrderNotesHistory";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import OrderTickets from "../Orders/OrderTickets";
import { TICKET_TYPES } from "../../constants/common";

export const OrderDetailSummary = ({ data, setIsPriority, orderDetails, pdfRef }) => {
  return (
    <OrderSummary data={orderDetails} transformDetails={true} showTopTiles={false} ref={pdfRef} />
  );
};

export const OrderCommunications = ({ data }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [saveCommOrders] = useSaveCommOrdersMutation();
  const [readCommOrders] = useReadCommOrdersMutation();
  const [commData, setCommData] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [concern, setConcern] = useState("");
  const [concernDescription, setConcernDescription] = useState("");
  const [isConcernSubmitted, setIsConcernSubmitted] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  console.log(commData, "commData");

  const isFieldRequired = (fieldName) => {
    const requiredFields = [];
    return requiredFields.includes(fieldName);
  };

  const getReadCommData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await readCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 1,
    });
    if (resp.data.type === 1) {
      console.log(resp.data, "setCommData");
      setCommData(resp.data.data);
    }
  };

  const submitConcern = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    const resp = await saveCommOrders({
      accessToken,
      orderObjId: data?.orderObjId,
      typofCommunication: 1,
      commStatus: 1,
      readStatus: 0,
      imageurl: imageUrl,
      //parentObjId: null,
      communicationTile: concern,
      communicationText: concernDescription,
    });
    if (resp?.data?.type === 1) {
      GetToast("Concern Saved");
      setModalIsOpen(false);
      getReadCommData();
    }

    // const data = {
    //   concern: concern,
    //   concernDescription: concernDescription,
    // };
    // console.log(data);
  };

  const validateConcern = (value) => {
    return value?.trim().length > 0;
  };
  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "concern") setConcern(value);
    if (name === "concernDescription") setConcernDescription(value);
  };

  useEffect(() => {
    getReadCommData();
  }, []);

  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-white text-2xl font-medium">Concern</p>
        <button
          onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          Add New Concern
        </button>
      </div>
      {commData?.map((item) => (
        <ConcernCard item={item} getReadCommData={getReadCommData} orderObjId={data?.orderObjId} />
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setModalIsOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">Add New Concern</p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <AttachUpload setImageUrl={setImageUrl} imageUrl={imageUrl} />
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};
export const CustomerSupport = ({ data }) => {
  return (
    <div className="h-full">
      <div className="flex justify-between items-center w-full py-4">
        <p className="text-[#333333] text-2xl font-medium">Support Tickets</p>
        <button
          //onClick={() => setModalIsOpen(true)}
          className="bg-[#FF7E00] text-white px-4 py-2 font-medium rounded-lg"
        >
          + Add New Ticket
        </button>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-12 gap-4">
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">Orthodontic Service</p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle Mockups in
            der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
            //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>johndoe@abc.com</div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">Orthodontic Service</p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle Mockups in
            der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
            //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>johndoe@abc.com</div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">Orthodontic Service</p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle Mockups in
            der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
            //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>johndoe@abc.com</div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
        <div className="border border-[#D2D2D2] rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-[#DCE6FD] h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="text-[#555] font-medium text-xl">Ticket#901900</p>
          </div>
          <p className="text-[#555] text-[17px] font-medium my-4">Orthodontic Service</p>
          <p className="text-[#555] text-[16px]">
            Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle Mockups in
            der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und visuelle
            Mockups in der Druck- Lorem Ipsum ist ein Platzhaltertext der häufig wird um Layouts und
            visuelle Mockups in der Druck-
          </p>
          <div
            className="flex justify-between items-center text-[#4E81F4] my-4 cursor-pointer"
            //onClick={() => setIsReplyBoxOpen((prev) => !prev)}
          >
            <div>johndoe@abc.com</div>
            <div className="flex gap-3">
              <img src={replyIcon} alt="" />
              <p className="">Reply</p>
            </div>
          </div>
        </div>
      </div>
      {/* {isReplyBoxOpen && (
        <textarea
          type="text"
          className="bg-[#EEF3FE] h-32 w-full rounded-lg p-4 my-4 text-[12px] text-start"
          placeholder="Type your concern here..."
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button
                  className="w-full text-right"
                  onClick={() => setModalIsOpen(false)}
                >
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium text-black leading-8 mb-0">
                  Add New Concern
                </p>
              </div>
              <div className="flex-1 py-4">
                <TextInputField
                  labelColor={"#777"}
                  labelText={"Concern"}
                  errorMessage={"Concern is required"}
                  placeholder={"Enter your Concern"}
                  //   isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concern"}
                  name={"concern"}
                  value={concern}
                  handleInputChange={handleInputChange}
                  //   validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                />
                <TextAreaInputField
                  labelColor={"#777"}
                  labelText={"Concern Description"}
                  errorMessage={"Concern is required"}
                  placeholder={"Concern Description"}
                  //   isFieldRequired={isFieldRequired("concern")}
                  htmlFor={"concernDescription"}
                  name={"concernDescription"}
                  value={concernDescription}
                  handleInputChange={handleInputChange}
                  //   validationFunctionName={validateConcern}
                  isSubmitted={isConcernSubmitted}
                  rows={6}
                />
                <div>
                  <p className="text-[#777] text-[16px] mb-2">Attachment</p>
                  <div className="relative">
                    <input
                      type="file"
                      className="w-20 opacity-0 cursor-pointer"
                    />
                    <button className="pointer-events-none absolute left-0 border border-[#4E81F4] rounded-full px-4 py-2 text-[12px] font-medium text-[#4E81F4]">
                      <AiOutlineArrowUp className=" inline-block" /> Upload
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setModalIsOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => submitConcern()}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal> */}
    </div>
  );
};

export const OrderTrackingDetails = ({ data }) => {
  return (
    <div className="h-full">
      <DeliveryTracker />
    </div>
  );
};

export const Delivery = ({ data, orderDetails, readOrderInfo }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [updateOrderById] = useUpdateOrderByIdMutation();
  const [saveNotes] = useSaveNotesMutation();
  const [saveOrderDelivery] = useSaveOrderDeliveryMutation();
  const [saveDeliveryAssets] = useSaveDeliveryAssetsMutation();
  const [deliveryAssetId, setDeliveryAssetId] = useState(new Date().getTime());

  console.log("deliveryAssetId", deliveryAssetId);

  const [deliveryNote, setDeliveryNote] = useState("");
  const [deliveryFile, setDeliveryFile] = useState({
    fileFor: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const arrayOfDicomData = [
    {
      fileFor: "fileOne" + deliveryAssetId,
      initialState: deliveryFile,
      setState: setDeliveryFile,
      placeholderImage: zipIcon,
      acceptedFileTypes: ".zip, .tar",
      showImagePreview: false,
      selected: deliveryFile.fileFor === "fileOne",
    },
    {
      fileFor: "fileTwo" + deliveryAssetId,
      initialState: deliveryFile,
      setState: setDeliveryFile,
      placeholderImage: folderIcon,
      allowFolderUpload: true,
      showImagePreview: false,
      selected: deliveryFile.fileFor === "fileTwo",
    },
    {
      fileFor: "fileThree" + deliveryAssetId,
      initialState: deliveryFile,
      setState: setDeliveryFile,
      placeholderImage: fileIcon,
      acceptedFileTypes: "",
      showImagePreview: false,
      selected: deliveryFile.fileFor === "fileThree",
    },
  ];

  console.log("data777", data);
  console.log("deliveryFile", deliveryFile);
  console.log("orderDetails", orderDetails);
  console.log("arrayOfDicomData", arrayOfDicomData);
  console.log("arrayOfDicomData deliveryFile", deliveryFile);

  const handleSubmitDelivery = async () => {
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    updateOrderById({
      accessToken,
      orderId: orderDetails?._id,
      currentStatus: "7",
    });
    // if (resp) {
    //   GetToast("Thanks for accepting the order you will get confirmation in a day or two");
    //   navigate("/orders");
    // }

    let deliveryObjId = null;

    if (
      orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
        ?.orderdelivery?.[0]?.deliveryObjId
    ) {
      deliveryObjId =
        orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
          ?.orderdelivery?.[0]?.deliveryObjId;
    } else {
      const resp = await saveOrderDelivery({
        accessToken,
        data: {
          orderObjId: orderDetails?._id,
          spOrderObjId:
            orderDetails?.serviceproviderdetails?.[orderDetails?.serviceproviderdetails?.length - 1]
              ?.spOrderObjId,
        },
      }).unwrap();

      console.log("resp", resp);
      deliveryObjId = resp?.data?.deliveryObjId;
    }

    if (deliveryObjId) {
      await saveNotes({
        accessToken,
        data: {
          notes: deliveryNote,
          deliveryObjId: deliveryObjId,
          orderObjId: orderDetails?._id,
          fileFor: "orderDelivery",
          s3Url: deliveryFile?.s3Url,
        },
      });
    }

    GetToast("Thank you for delivering the order, you will receive confirmation shortly.");
    navigate("/orders");

    setIsLoading(false);
  };

  return (
    <div className="flex-col">
      <div>
        <div className="bg-backgroundV3 text-white rounded-lg p-4">
          <div className="flex gap-4 items-center">
            <div className="bg-white h-12 w-12 rounded-full flex items-center justify-center">
              <img src={ticket} alt="" />
            </div>
            <p className="font-medium text-xl">{data?.serviceName?.[0]}</p>
          </div>
        </div>
        <div className="mt-6">
          <OrderNotesHistory notes={orderDetails?.notes} />
        </div>
        <div className="mt-6">
          <div className="bg-backgroundV2 text-white p-4 rounded-md flex flex-col gap-3">
            <p className="text-[16px] leading-4 text-white mb-2">Upload Order Deliverables</p>
            <div className="grid grid-cols-3 gap-5">
              {arrayOfDicomData.map((item) => (
                <div style={{ height: `150px` }} className="w-full">
                  <ImageUpload
                    placeholderImage={item?.placeholderImage}
                    imageUrl={
                      deliveryFile?.fileFor === item?.fileFor ? item?.initialState?.s3Url : null
                    }
                    fileName={
                      deliveryFile?.fileFor === item?.fileFor ? item?.initialState?.fileName : ""
                    }
                    id={item?.initialState?.fileFor}
                    fileType="orderDelivery"
                    fileFor={item?.fileFor}
                    setImageUrl={item?.setState}
                    title={item?.title}
                    acceptedFileTypes={
                      item?.acceptedFileTypes ?? ".jpeg, .jpg, .png, .tiff, .bmp, .heif, .webp"
                    }
                    showImagePreview={item?.showImagePreview !== false ? true : false}
                    allowFolderUpload={item?.allowFolderUpload}
                    selected={item?.selected}
                    orderObjId={orderDetails?._id}
                    customImageUpload={true}
                    handleCustomImageUpload={() => {}}
                  />
                </div>
              ))}
            </div>
            <div className="w-full">
              <TextAreaInputField
                labelColor={"#777"}
                labelText={"Note"}
                errorMessage={"Note is required"}
                placeholder={"Type here"}
                isFieldRequired={true}
                htmlFor={"concernDescription"}
                name={"concernDescription"}
                value={deliveryNote}
                handleInputChange={(e) => setDeliveryNote(e.target.value)}
                rows={6}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-6 items-center md:justify-end gap-4 pt-4 flex-wrap">
        <button className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl">
          Cancel
        </button>

        <button
          onClick={handleSubmitDelivery}
          className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          disabled={isLoading}
        >
          {isLoading ? <LoaderSpinner /> : "Submit Delivery"}
        </button>
      </div>
    </div>
  );
};

function OrderDetailsServiceProvider({ userRole, userInfo }) {
  const location = useLocation();

  return (
    <div className="flex bg-black">
      <Navbar
        iconArray={
          userRole === "89uij7"
            ? superAdminIconArray
            : userRole === "676dza2"
            ? iconArray
            : spIconArray
        }
        navHoverCss={navHoverCss}
        navActiveCss={navActiveCss}
        middleSection={false}
        middleTabTitleArray={[""]}
        middleTabContentObject={{}}
        userRole={userRole}
        active="Orders"
      />
      <OrderTab userInfo={userInfo} userRole={userRole} data={location.state} />
    </div>
  );
}
export default OrderDetailsServiceProvider;

export const OrderTab = ({ userInfo, userRole, data }) => {
  console.log(data, "dataaaa");
  console.log(userInfo, "userInfo");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfileDetails } = useSelector((state) => state.personalDetails);

  console.log("userProfileDetails", userProfileDetails);

  const [getOrderById] = useGetOrderByIdMutation();
  const [getServiceProviderOrders] = useGetServiceProviderOrdersMutation();
  const [updateServiceProviderOrders] = useUpdateServiceProviderOrdersMutation();

  const [orderDetails, setOrderDetails] = useState([]);
  const [serviceProviderOrdersData, setServiceProviderOrdersData] = useState([]);
  const [activeSubTab, setActiveSubTab] = useState(TICKET_TYPES.SUPPORT_TICKET);

  const getOrderData = async (accessToken, orderId) => {
    const resp = await getOrderById({
      accessToken,
      orderId,
    }).unwrap();
    if (resp) {
      const respOrder = await getServiceProviderOrders({
        accessToken,
        orderObjId: resp.data[0]?._id,
      }).unwrap();
      setServiceProviderOrdersData(respOrder.data);

      setOrderDetails(resp.data[0]);
      setIsPriority(resp?.data?.[0]?.isPriority);
    }
  };

  const readOrderInfo = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getOrderData(accessToken, data.orderObjId);
    }
  };

  useEffect(() => {
    readOrderInfo();
  }, []);

  const { currencyRates } = useSelector((state) => state.servicesDetails);

  const [isPriority, setIsPriority] = useState(false);

  const pdfRef = useRef();

  const tabNamesArray = [];
  if (data.statusCode === "106" || data.statusCode === "109" || data.statusCode === "112") {
    tabNamesArray.push("Order Details");
  } else if (
    data?.statusCode === "115" ||
    data?.statusCode === "121" ||
    data?.statusCode === "136"
  ) {
    tabNamesArray.push("Order Details");
    tabNamesArray.push("Delivery");
  } else {
    tabNamesArray.push("Order Details");
    // tabNamesArray.push("Order Tracking Details");
    // tabNamesArray.push("Customer Support");
    // tabNamesArray.push("Delivery");
  }
  tabNamesArray.push("Support Tickets");
  //["Order Details", "Order Communications", "Order Tracking Details", "Customer Support", "Delivery"];
  const outputObject = {
    "Order Details": (
      <OrderDetailSummary
        data={data}
        setIsPriority={setIsPriority}
        orderDetails={orderDetails}
        pdfRef={pdfRef}
      />
    ),
    // "Order Communications": <OrderCommunications data={data} />,
    // "Customer Support": <CustomerSupport data={data} />,
    Delivery: <Delivery data={data} orderDetails={orderDetails} readOrderInfo={readOrderInfo} />,
    "Support Tickets": (
      <OrderTickets
        orderObjId={orderDetails?._id}
        orderDetails={orderDetails}
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
      />
    ),
    // "Order Tracking Details": <OrderTrackingDetails data={data} />,
  };
  const { getAccessTokenSilently } = useAuth0();
  const { user, isAuthenticated } = useAuth0();
  const [activeTab, setActiveTab] = useState(tabNamesArray[0]);
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);
  const [isOrderAccepted, setIsOrderAccepted] = useState(false);
  const [localPrice, setLocalPrice] = useState("");
  const [isCommunicalModelOpen, setIsCommunicalModelOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [rejectionNote, setRejectionNote] = useState("");

  const [updateOrderById] = useUpdateOrderByIdMutation();
  const [saveCommOrders] = useSaveCommOrdersMutation();

  const handleTabSuper = (tabName) => {
    setActiveTab(tabName);
  };

  const createTicket = async (accessToken) => {
    const payload = {
      orderObjId: orderDetails?._id,
      messagetext: "Order Acceptance Rejected by Service Provider: " + rejectionNote,
      assetUrl: "",
      typeofComm: "4",
      fromuserAuthId: userProfileDetails?.userAuthId,
      touserAuthId: undefined,
    };
    if (orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId) {
      payload.spuserAuthId = orderDetails?.serviceproviderdetails?.[0]?.spuserAuthId;
    }
    const resp = await saveCommOrders({
      accessToken,
      data: payload,
    });
  };

  const handleAcceptSubmit = async (actionType) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });

    if (actionType === "accept") {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "4",
      });
      if (resp) {
        GetToast("Thanks for accepting the order you will get confirmation in a day or two");
        navigate("/orders");
        setIsOrderAccepted(true);
        setAcceptModalOpen(false);
      }
    } else {
      const resp = await updateOrderById({
        accessToken,
        orderId: orderDetails?._id,
        currentStatus: "3",
      });
      if (resp) {
        const spOrdersData = serviceProviderOrdersData?.find(
          (d) => d?.spuserAuthId?.[0]?.userAuthId === userProfileDetails?.userAuthId
        );
        if (spOrdersData?._id) {
          updateServiceProviderOrders({
            accessToken,
            status: "0",
            _id: spOrdersData?._id,
            spOrderObjId: spOrdersData?.spOrderObjId,
            orderObjId: orderDetails?._id,
          });
        }
        createTicket(accessToken);
        GetToast("Order rejected, you can contact Administrator for any queries.");
        navigate("/orders");
      }
    }
  };

  console.log(data.statusCode, "fwefwefew");
  console.log(data, "fwefwefewfffff");

  const getCurrencyData = async () => {
    if (Object.keys(currencyRates)?.length > 0) {
      return currencyRates;
    }

    const response = await axios.get(
      "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json"
    );
    console.log("currency response", response);
    const { usd } = response.data;
    dispatch(setCurrencyRates(usd));
    return usd;
  };

  const convertCurrency = async (amount, userCurrency, reverse = false) => {
    const usdCurrencyRates = await getCurrencyData();
    const conversionrate = usdCurrencyRates[userCurrency?.toLowerCase()];
    if (conversionrate && reverse) {
      return amount * conversionrate;
    } else if (conversionrate) {
      return amount / conversionrate;
    }
    return 0;
  };

  const updateLocalPrice = async () => {
    setLocalPrice(
      (await convertCurrency(data?.orderValue, data?.orderCurrency || "USD", true)).toFixed(2)
    );
  };

  useEffect(() => {
    updateLocalPrice();
  }, []);

  console.log(localPrice, "localPrice");

  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = canvas.width * 0.264583; // Convert pixels to mm (1 px = 0.264583 mm)
      const pdfHeight = canvas.height * 0.264583; // Convert pixels to mm

      const pdf = new jsPDF("p", "mm", [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <div className="MAIN-DASH bg-black p-4 w-full min-h-screen h-full flex flex-col md:py-4 pt-4 pb-20">
      <div className="NAVBAR flex justify-between items-center mb-4 ">
        <p className="text-[15px] text-[#999999] flex justify-between items-center">
          <span>
            <img src={hut} alt="" />
          </span>
          <span className="ml-3 flex items-center">Dashboard</span>
        </p>
        <div className="flex gap-4 items-center">
          {isAuthenticated ? (
            <div className="text-[15px] justify-center items-center hidden md:flex text-white">
              Hello, {userProfileDetails?.firstName ?? user?.name}
            </div>
          ) : (
            <LoginButton />
          )}
          {userRole !== "89uij7" ? (
            userInfo?.length > 0 ? (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                {userInfo[0]?.active === 1 ? (
                  <>
                    {userInfo[0]?.crowdHireId}
                    <img className="relative top-[4px] right-0" src={greenTick} alt="" />
                  </>
                ) : (
                  <div
                    className="flex"
                    data-tooltip-id="status"
                    data-tooltip-content={
                      "Your registration is under review and you will be notified once admin approves your registration."
                    }
                  >
                    <span className="text-orange-600">{userInfo[0]?.crowdHireId}</span>
                    <img
                      className="relative top-[0px] ml-2 right-0 h-5 w-5"
                      src={redCross}
                      alt=""
                    />
                    <Tooltip id="status" place="bottom" />
                  </div>
                )}
              </button>
            ) : (
              <button className="relative px-4 py-2 flex items-center rounded-xl bg-secondary font-medium text-[15px] text-white">
                Account Not Active
                <img className="relative top-[0px] right-0 h-5 w-5" src={redCross} alt="" />
              </button>
            )
          ) : (
            <></>
          )}

          <Notification />
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:items-center items-start justify-between my-4 md:gap-96 gap-4">
        <div className="flex flex-1 gap-4 items-center justify-between w-full">
          <p className="font-medium text-[34px] text-white">{data.serviceName}</p>
          {data?.statusCode === "112" ? (
            <p className="px-4 py-2 text-[#1DB569] rounded-lg font-medium text-[17px]">
              {data.status}
            </p>
          ) : data?.statusCode === "113" || data?.statusCode === "109" ? (
            <p className="px-4 py-2 text-[#F35F5D] rounded-lg font-medium text-[17px]">
              {data.status}
            </p>
          ) : data?.statusCode === "115" ? (
            <p className="px-4 py-2 text-[#ff9e4f] rounded-lg font-medium text-[17px]">
              {data.status}
            </p>
          ) : data?.statusCode === "124" ? (
            <p className="px-4 py-2 text-[#ff9e4f] rounded-lg font-medium text-[17px]">
              {data.status}
            </p>
          ) : data?.statusCode === "121" ? (
            <p className="px-4 py-2 text-[#39c44e] rounded-lg font-medium text-[17px]">
              {data.status}
            </p>
          ) : (
            data?.statusCode === "106" && (
              <div className="flex gap-2 items-center">
                <button
                  onClick={() => setAcceptModalOpen(true)}
                  className="px-4 py-2 bg-[#1DB569] text-white rounded-lg font-medium text-[17px]"
                >
                  Accept
                </button>
                <button
                  onClick={() => setRejectModalOpen(true)}
                  className="px-4 py-2 bg-[#F35F5D] text-white rounded-lg font-medium text-[17px]"
                >
                  Reject
                </button>
              </div>
            )
          )}
        </div>
      </div>

      <div
        className={isPriority ? "card shadow-xl" : "shadow-xl"}
        data-label={isPriority ? "Priority Order" : ""}
      >
        <div
          className={
            isPriority
              ? "card__container bg-secondary p-4 rounded-md flex-1 flex flex-col"
              : "bg-secondary p-4 rounded-md flex-1 flex flex-col"
          }
        >
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              {orderDetails?.orderNumber && (
                <p className="text-white">
                  Order Id : <strong className="text-primary">{orderDetails?.orderNumber}</strong>
                </p>
              )}
              <p className="text-xl font-medium text-white my-4">
                Price:{" "}
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={data.orderCurrency + " may vary according to the USD rate"}
                  className="text-white font-bold"
                >
                  {" "}
                  ${data?.orderValue} USD{" "}
                  <span className="text-white text-base">
                    ( {localPrice} {data?.orderCurrency} )
                  </span>
                </span>{" "}
              </p>
            </div>
            <div onClick={downloadPDF} className="flex gap-4 items-center">
              {/* <img src={printerIcon} alt="" className="cursor-pointer" /> */}
              <button className="px-4 py-2 bg-[#FF7E00] rounded-md flex items-center gap-2 text-white font-medium">
                <img src={exitIcon} alt="" />
                Download Summary
              </button>
            </div>
          </div>
          <Tooltip id="my-tooltip" place="right" />
          <div className="Tabs flex-1 h-full">
            {/* Tab nav */}
            <ul className="NAV flex items-center gap-4 text-[16px] leading-4 font-medium border-b-2 border-[#222026] border-opacity-10 py-4">
              {tabNamesArray?.map((title, i) => {
                return (
                  <li key={i + 1} className="relative" onClick={() => handleTabSuper(title)}>
                    <div
                      className={
                        activeTab === title
                          ? "text-primary before:content-[''] before:absolute before:h-1 before:w-full before:bg-primary before:left-0 before:-bottom-4"
                          : "hover:text-primary cursor-pointer text-[#999] font-normal"
                      }
                    >
                      {title}
                    </div>
                  </li>
                );
              })}
            </ul>

            <div className="outlet md:h-fit h-[50vh] overflow-auto pt-4 ">
              {Object.keys(outputObject || [])?.map((key) => {
                if (tabNamesArray.includes(key)) {
                  return <>{activeTab === key ? <>{outputObject[key]}</> : null}</>;
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={acceptModalOpen}
        onRequestClose={() => setAcceptModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col text-white">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setAcceptModalOpen(false)}>
                  <AiOutlineClose className="ml-auto" />
                </button>
                <p className="text-[26px] font-medium leading-8 mb-0">Accept Order</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                {isOrderAccepted ? (
                  <>
                    <img src={assignSuccess} alt="" />
                    <p className="text-2xl font-semibold">Congratulations!</p>
                    <p className="text-center my-0 md:px-12">
                      Your order is successfully accepted.
                    </p>
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={assignedIcon} alt="" />
                    <p className="text-center my-4 md:px-12">
                      By clicking confirm this order will be accepted for
                      <span className="text-xl text-primary font-semibold">
                        {" "}
                        ${data.orderValue} USD ( {localPrice} {data.orderCurrency} )
                      </span>
                    </p>
                  </>
                )}
              </div>

              <div className="flex items-center justify-end gap-4">
                {isOrderAccepted ? null : (
                  <button
                    onClick={() => setAcceptModalOpen(false)}
                    className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={() => {
                    handleAcceptSubmit("accept");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  {isOrderAccepted ? "Okay" : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>

      <Modal
        isOpen={rejectModalOpen}
        onRequestClose={() => setRejectModalOpen(false)}
        contentLabel="Custom Modal"
        className="custom-modal"
        // style={customStyles}
        ariaHideApp={false}
      >
        <>
          <div className="MODAL-BODY h-full">
            <div className="h-full flex flex-col">
              <div className="h-fit">
                <button className="w-full text-right" onClick={() => setRejectModalOpen(false)}>
                  <AiOutlineClose className="ml-auto text-white" />
                </button>
                <p className="text-[26px] font-medium text-white leading-8 mb-0">Reject Order</p>
              </div>

              <div className="flex-1 flex flex-col items-center justify-center">
                <img src={rejectedIcon} alt="" className="w-4/12" />
                <p className="text-center text-white my-4 md:px-12">
                  Are you sure you want to reject this order!
                </p>
                <div className="w-full mt-6">
                  <TextAreaInputField
                    labelColor={"#777"}
                    labelText={"Add Rejection Note"}
                    errorMessage={"Note is required"}
                    placeholder={"Type here"}
                    isFieldRequired={true}
                    htmlFor={"rejectionNote"}
                    name={"rejectionNote"}
                    value={rejectionNote}
                    handleInputChange={(e) => setRejectionNote(e.target.value)}
                    rows={6}
                  />
                </div>
              </div>

              <div className="flex items-center justify-end gap-4">
                <button
                  onClick={() => setRejectModalOpen(false)}
                  className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
                >
                  Cancel
                </button>

                <button
                  onClick={() => {
                    handleAcceptSubmit("reject");
                  }}
                  className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Modal>
      {/* {data?.statusCode !== "106" && (
        <OrderCommunication
          isOpen={isCommunicalModelOpen}
          onClose={setIsCommunicalModelOpen}
          orderObjId={orderDetails?._id}
          orderDetails={orderDetails}
        />
      )} */}
    </div>
  );
};
