/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MapInputField from "../InputField-Comps/MapInputField";
import LocationInput from "../InputField-Comps/LocationInput";
import TextInputField from "../InputField-Comps/TextInputField";
import NumberInputField from "../InputField-Comps/NumberInputField";
import { useSaveBankDetailsMutation, useUpdateBankDetailsMutation } from "../../services/user";
import UseAccessToken from "../../hooks/UseAccessToken";
import { useGetCurrenciesMutation } from "../../services/util";
import DropdownField from "../InputField-Comps/DropdownField";
import CurrencyDropDown from "../InputField-Comps/CurrencyDropDown";
import { useAuth0 } from "@auth0/auth0-react";
import { GetToast } from "../Toast-Comps/GetToast";
import TextAreaInputField from "../InputField-Comps/TextAreaInputField";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import LocationInputV2 from "../InputField-Comps/LocationInputV2";
import { MAP_LIBRARIES } from "../../Utils/utility";

const InputFieldsBank = ({ setModalIsOpen, profileDetailData, setIsProfileUpdated }) => {
  const [location, setLocation] = useState({ lat: 37.7577, lng: -122.4376 });
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState("");
  const [iBANNumber, setIBANNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  const [isValid, setIsValid] = useState(false);
  const [isBankFormSubmitted, setIsBankFormSubmitted] = useState(false);
  const accessToken = UseAccessToken();

  //Google Maps comps and state here
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const { getAccessTokenSilently } = useAuth0();
  const [updateBankDetails] = useUpdateBankDetailsMutation();
  const [saveBankDetails] = useSaveBankDetailsMutation();
  const [getCurrencies] = useGetCurrenciesMutation();

  useEffect(() => {
    setBankName(profileDetailData?.bankDetails?.[0]?.bankName);
    setBankAddress(profileDetailData?.bankDetails?.[0]?.bankAddress);
    setAccountHolderName(profileDetailData?.bankDetails?.[0]?.accountHolderName);
    setAccountNo(profileDetailData?.bankDetails?.[0]?.accountNo);
    setSelectedCurrencyOption(profileDetailData?.bankDetails?.[0]?.currency);
    setIBANNumber(profileDetailData?.bankDetails?.[0]?.iBANNumber);
    setSwiftCode(profileDetailData?.bankDetails?.[0]?.swiftCode);
  }, [profileDetailData]);

  const getCurrencyAPIData = async (accessToken) => {
    const resp = await getCurrencies({ accessToken }).unwrap();
    setCurrencyOptions(resp);
  };

  const getCurrenciesData = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUDIENCE_URL, // Value in Identifier field for the API being called.
      },
    });
    if (accessToken) {
      await getCurrencyAPIData(accessToken);
    }
  };

  useEffect(() => {
    // Simulating fetching options from an external source
    // setTimeout(() => {
    //     const fetchedCurrencyOptions = [
    //         { value: "dollar", label: "dollar" },
    //         { value: "option2", label: "Option 2" },
    //         { value: "option3", label: "Option 3" },
    //     ];
    //     setCurrencyOptions(fetchedCurrencyOptions);
    // }, 1000);

    getCurrenciesData();
  }, []);

  console.log(currencyOptions, "currencyOptions");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "bankName") setBankName(value);
    if (name === "bankAddress") setBankAddress(value);
    if (name === "accountHolderName") setAccountHolderName(value);
    if (name === "accountNo") setAccountNo(value);
    if (name === "currency") setSelectedCurrencyOption(value);
    if (name === "ibanNumber") setIBANNumber(value);
    if (name === "swiftCode") setSwiftCode(value);
  };
  const handleSubmit = async (event) => {
    console.log("HANDLEWSUB");
    event.preventDefault();
    // Perform validation for each input
    const isBankNameValid = !isFieldRequired("bankName") || validateBankName(bankName);
    const isBankAccountNumberValid =
      !isFieldRequired("bankAccountNumber") || validateBankAccountNumber(accountNo);
    const isAccountHolderNameValid =
      !isFieldRequired("accountHolderName") || validateAccountHolderName(accountHolderName);
    const isSwiftCodeValid = !isFieldRequired("swiftCode") || validateSwiftCode(swiftCode);
    const isAccountHoldersAddressValid =
      !isFieldRequired("accountHoldersAddress") || validateAccountHoldersAddress(bankAddress);
    const isIbanNumberValid = !isFieldRequired("ibanNumber") || validateIbanNumber(iBANNumber);
    const isCurrencyValid =
      !isFieldRequired("currency") || validateCurrencyDropDown(selectedCurrencyOption);

    // Set overall form validity
    setIsValid(
      isBankNameValid &&
        isBankAccountNumberValid &&
        isAccountHolderNameValid &&
        isSwiftCodeValid &&
        isAccountHoldersAddressValid &&
        isIbanNumberValid &&
        isCurrencyValid
    );
    setIsBankFormSubmitted(true);
    console.log(
      isBankNameValid &&
        isBankAccountNumberValid &&
        isAccountHolderNameValid &&
        isSwiftCodeValid &&
        isAccountHoldersAddressValid &&
        isIbanNumberValid &&
        isCurrencyValid,
      "TESTINGGGG"
    );

    if (
      isBankNameValid &&
      isBankAccountNumberValid &&
      isAccountHolderNameValid &&
      isSwiftCodeValid &&
      isAccountHoldersAddressValid &&
      isIbanNumberValid &&
      isCurrencyValid
    ) {
      // Submit the form or perform any other action
      const data = {
        bankName: bankName,
        accountNo: accountNo,
        accountHolderName: accountHolderName,
        swiftCode: swiftCode,
        bankAddress: bankAddress,
        iBANNumber: iBANNumber,
        currency: selectedCurrencyOption,
        userAuthId: profileDetailData?.userAuthId,
      };
      console.log("Form submitted", data);
      if (profileDetailData?.bankDetails?.length !== 0) {
        const resp = await updateBankDetails({
          accessToken,
          ...data,
          _id: profileDetailData?.bankDetails?.[0]?._id,
        });
        if (resp?.data?.type === 1) {
          GetToast("Bank Details Updated Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
        setIsProfileUpdated(true);
        setModalIsOpen(false);
      } else {
        const resp = await saveBankDetails({
          accessToken,
          ...data,
        });
        if (resp?.type === 1) {
          GetToast("Bank Details Saved Successfully!!");
        } else {
          GetToast(
            "OOPS Something Went Wrong, please contact Administrator or write to admin@crowdhire.com.",
            "error"
          );
        }
        setIsProfileUpdated(true);
        setModalIsOpen(false);
      }
    }
  };

  const validateBankName = (value) => {
    return value?.trim().length > 0;
  };
  const validateBankAccountNumber = (value) => {
    // return value?.trim().length === 16;
    return value?.trim().length > 0;
  };
  const validateAccountHolderName = (value) => {
    return value?.trim().length > 0;
  };
  const validateAccountHoldersAddress = (value) => {
    return value?.trim().length > 0;
  };
  const validateSwiftCode = (value) => {
    // const regexPattern = /^[A-Za-z]{4}\d{7}$/;
    // return regexPattern.test(value);
    return value?.trim().length >= 6;
  };
  const validateIbanNumber = (value) => {
    // return value?.trim().length === 22;
    return value?.trim().length > 0;
  };
  const validateCurrencyDropDown = () => {
    if (!selectedCurrencyOption) {
      return false;
    }
    return true;
  };

  const isFieldRequired = (fieldName) => {
    const requiredFields = [
      "bankName",
      "bankAccountNumber",
      "accountHolderName",
      "swiftCode",
      "bankAddress",
      "currency",
    ];
    return requiredFields.includes(fieldName);
  };

  return (
    <div className="h-full">
      <form onSubmit={handleSubmit} className=" h-full flex flex-col">
        <div className="flex-1 pt-8 pb-8">
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Bank Name"}
              errorMessage={"Please enter the Bank Name"}
              placeholder={"Bank name"}
              htmlFor={"bankName"}
              name={"bankName"}
              value={bankName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateBankName}
              isSubmitted={isBankFormSubmitted}
              isFieldRequired={isFieldRequired("bankName")}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Bank Account Number"}
              errorMessage={"Please enter the Bank Account Number"}
              placeholder={"Bank Account Number"}
              htmlFor={"accountNo"}
              name={"accountNo"}
              value={accountNo}
              handleInputChange={handleInputChange}
              validationFunctionName={validateBankAccountNumber}
              isSubmitted={isBankFormSubmitted}
              isFieldRequired={isFieldRequired("bankAccountNumber")}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"Account Holder Name"}
              errorMessage={"Please enter the Bank Account Holder Name"}
              placeholder={"Account Holder Name"}
              htmlFor={"accountHolderName"}
              name={"accountHolderName"}
              value={accountHolderName}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAccountHolderName}
              isSubmitted={isBankFormSubmitted}
              isFieldRequired={isFieldRequired("accountHolderName")}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"SWIFT Code"}
              errorMessage={"Please enter the SWIFT Code"}
              placeholder={"SWIFT Code"}
              htmlFor={"swiftCode"}
              name={"swiftCode"}
              value={swiftCode}
              handleInputChange={handleInputChange}
              validationFunctionName={validateSwiftCode}
              isSubmitted={isBankFormSubmitted}
              isFieldRequired={isFieldRequired("swiftCode")}
            />
          </div>
          {/* <div className="relative">
                        <TextInputField
                            labelColor={"#777"}
                            labelText={"Account Holder Address"}
                            errorMessage={"Please enter the Account Holder Address"}
                            placeholder={"Account Holder Address"}
                            htmlFor={"bankAddress"}
                            name={"bankAddress"}
                            value={bankAddress}
                            handleInputChange={handleInputChange}
                            validationFunctionName={validateAccountHoldersAddress}
                            isSubmitted={isBankFormSubmitted}
                            isFieldRequired={isFieldRequired("bankAddress")}
                        />
                    </div> */}
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <div className=" col-span-2">
                <LocationInputV2
                  setValue={setBankAddress}
                  placeholder={"Search a Address location"}
                  name={"accountHoldersAddressSearch"}
                  setLocation={setLocation}
                />
              </div>
              <div className="col-span-2 row-span-2 mb-2">
                <GoogleMap
                  mapContainerClassName="h-[140px]"
                  center={location}
                  zoom={10}
                  onClick={(e) => {
                    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                  }}
                  clickableIcons={true}
                >
                  <Marker position={location} />
                </GoogleMap>
              </div>
            </>
          )}
          <div className="col-span-2 row-span-2">
            <TextAreaInputField
              labelColor={"#777"}
              labelText={"Account Holders Address"}
              errorMessage={"Enter Valid Address"}
              placeholder={"Write here"}
              isFieldRequired={isFieldRequired("bankAddress")}
              htmlFor={"bankAddress"}
              name={"bankAddress"}
              value={bankAddress}
              handleInputChange={handleInputChange}
              validationFunctionName={validateAccountHoldersAddress}
              isSubmitted={isBankFormSubmitted}
              rows={4}
              cols={5}
            />
          </div>
          <div className="relative">
            <TextInputField
              labelColor={"#777"}
              labelText={"IBAN/BSB/ABA"}
              errorMessage={"Please enter the IBAN Number"}
              placeholder={"IBAN/BSB/ABA"}
              htmlFor={"ibanNumber"}
              name={"ibanNumber"}
              value={iBANNumber}
              handleInputChange={handleInputChange}
              validationFunctionName={validateIbanNumber}
              isSubmitted={isBankFormSubmitted}
              isFieldRequired={isFieldRequired("ibanNumber")}
            />
          </div>
          <div className="relative">
            <CurrencyDropDown
              labelColor={"#777"}
              labelText={"Account Currency "}
              errorMessage={"Please Select a Currency"}
              placeholder={"Select Currency"}
              isFieldRequired={isFieldRequired("currency")}
              htmlFor={"currency"}
              name={"currency"}
              value={selectedCurrencyOption}
              handleInputChange={handleInputChange}
              validationFunctionName={validateCurrencyDropDown}
              isSubmitted={isBankFormSubmitted}
              options={currencyOptions}
            />
          </div>
          {/* <div className="relative">
                        <DropdownField
                            labelColor={"#777"}
                            labelText={"Account Currency "}
                            errorMessage={"Please Select a Currency"}
                            placeholder={"Select Currency"}
                            isFieldRequired={isFieldRequired("currency")}
                            htmlFor={"currency"}
                            name={"currency"}
                            value={selectedCurrencyOption}
                            handleInputChange={handleInputChange}
                            validationFunctionName={validateCurrencyDropDown}
                            isSubmitted={isBankFormSubmitted}
                            options={currencyOptions}
                        />
                    </div> */}
        </div>

        <div className="flex pb-4 items-center justify-end gap-4">
          <button
            onClick={() => setModalIsOpen(false)}
            className="font-medium text-[17px] left-[18px] text-[#777] py-2 px-8 bg-white border border-opacity-50 rounded-xl"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="font-medium text-[17px] left-[18px] text-white py-2 px-8 bg-[#ff7e00] rounded-xl"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputFieldsBank;
